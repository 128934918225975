import _ from 'lodash';
import type {
  GptPalette,
  ColorName,
  PartialColorPalette,
  LinkedColors,
  GptParams,
} from './types';
import {
  AI_GATEWAY_APP_DEF_ID,
  AI_GATEWAY_COLORS_FROM_IMAGE_AND_PALETTE_PROMPT_ID,
  AI_GATEWAY_FROM_LOGO_PROMPT_ID,
  AI_GATEWAY_FROM_TEXT_PROMPT_ID,
  AI_GATEWAY_URL,
  AI_GATEWAY_URL_HIDDEN,
  EMPTY_COLOR_NAME_VALUE,
  GPT_PROMPT_VERSION_CREATIVE,
  GPT_PROMPT_VERSION_STRICT,
  MAX_COLOR_PERCENT,
  MAX_COLORS_TO_EXTRACT,
  MAX_WHITE_COLOR_PERCENT,
  MEDIA_COLOR_EXTRACTOR_ALGORITHM,
  MEDIA_COLOR_EXTRACTOR_URL,
  MEDIA_FILES_PATH,
  MIN_COLOR_PERCENT,
} from './constants';
import type {
  Algorithm,
  Color,
  Color as ExtractorColor,
} from '@wix/ambassador-media-media-manager-color-extractor-v1-colors/types';
import { getAdvancedColors, getShadesFromBaseColors } from '../colors/colors';
import { HttpClient } from '@wix/http-client';
import { extractColors } from '@wix/ambassador-media-media-manager-color-extractor-v1-colors/http';
import { ColorPalette } from '@wix/document-services-types';
import { ALL_ACCENTS_COLORS } from '../colors/consts';
import { LinkedColorValue, ValidPromptId } from './types';

const isMainBackgroundColorWhite = (color: ExtractorColor) => {
  return (
    color.hex === '#FFFFFF' &&
    color.percent &&
    color.percent > MAX_WHITE_COLOR_PERCENT
  );
};

const isMainColor = (color: ExtractorColor) => {
  return color.percent && color.percent > MIN_COLOR_PERCENT;
};

const hasDominatingColor = (colorsFromColorExtractor: ExtractorColor[]) => {
  return colorsFromColorExtractor.find(
    (color) => color.percent && color.percent >= MAX_COLOR_PERCENT,
  );
};

const createGptLogoParams = (colorsFromColorExtractor: Color[]) => {
  const isDominantColorExists = hasDominatingColor(colorsFromColorExtractor);

  return colorsFromColorExtractor.reduce((acc, color) => {
    if (
      isDominantColorExists ||
      (!isMainBackgroundColorWhite(color) && isMainColor(color))
    ) {
      if (!color.hex) {
        return acc;
      }
      return {
        ...acc,
        [color.hex]: `${color.percent}%`,
      };
    }
    return acc;
  }, {}) as GptParams;
};

const getFullPalettes = (
  palettesFromGpt: GptPalette[],
  themeColorsNames: ColorName[],
): PartialColorPalette[] => {
  return palettesFromGpt.map((palette) => {
    const updatedPalette: PartialColorPalette = {};
    (Object.keys(palette) as Array<keyof GptPalette>).forEach((key, index) => {
      const newKey = themeColorsNames[index];

      //check is needed because chatGpt sometimes forgets to add # to hex value
      const hexValue = palette[key].includes('#')
        ? palette[key]
        : `#${palette[key]}`;

      updatedPalette[newKey] = hexValue;
    });
    const shadeColors: PartialColorPalette = getShadesFromBaseColors(
      updatedPalette as PartialColorPalette,
    );
    const advancedColors: PartialColorPalette = getAdvancedColors({
      ...updatedPalette,
      ...shadeColors,
    });
    const fullPalette = {
      ...updatedPalette,
      ...shadeColors,
      ...advancedColors,
    };

    return fullPalette;
  });
};

const buildMainColorsPaletteFromGptResponse = (
  palette: GptPalette,
  themeColorsNames: ColorName[],
): PartialColorPalette => {
  const updatedPalette: PartialColorPalette = {};
  (Object.keys(palette) as Array<keyof GptPalette>).forEach((key, index) => {
    const newKey = themeColorsNames[index];

    //check is needed because chatGpt sometimes forgets to add # to hex value
    const hexValue = palette[key].includes('#')
      ? palette[key]
      : `#${palette[key]}`;

    updatedPalette[newKey] = hexValue;
  });

  return updatedPalette;
};

const buildFullPalettesBasedOnAdvancedColorsPallete = (
  currentLinkedColorsPalette: Partial<Record<ColorName, LinkedColorValue>>,
  mainColorsPalette: PartialColorPalette,
  shadeColorsPalette: PartialColorPalette,
): PartialColorPalette => {
  const fullPalette = { ...mainColorsPalette, ...shadeColorsPalette };

  Object.entries(currentLinkedColorsPalette).forEach(
    ([colorName, linkedColor]) => {
      if (linkedColor === EMPTY_COLOR_NAME_VALUE) {
        return;
      }
      const colorToUpdate = fullPalette[linkedColor as ColorName];
      if (colorToUpdate) {
        fullPalette[colorName as ColorName] = colorToUpdate;
      }
    },
  );

  return fullPalette;
};

const getFullPalettesBasedOnLinkedColors = (
  palettesFromGpt: GptPalette[],
  currentLinkedColorsPalette: Partial<Record<ColorName, LinkedColorValue>>,
  themeColorsNames: ColorName[],
): PartialColorPalette[] => {
  return palettesFromGpt.map((palette) => {
    const mainColorsPalette: PartialColorPalette =
      buildMainColorsPaletteFromGptResponse(palette, themeColorsNames);
    const shadeColorsPalette: PartialColorPalette = getShadesFromBaseColors(
      mainColorsPalette as PartialColorPalette,
    );
    return buildFullPalettesBasedOnAdvancedColorsPallete(
      currentLinkedColorsPalette,
      mainColorsPalette,
      shadeColorsPalette,
    );
  });
};

const fetchExtractColorsFromImage = async (imagePath: string) => {
  const httpClient = new HttpClient({
    baseURL: MEDIA_COLOR_EXTRACTOR_URL,
  });

  const colors = await httpClient.request(
    extractColors({
      url: `${MEDIA_FILES_PATH}${imagePath}`,
      algorithm: MEDIA_COLOR_EXTRACTOR_ALGORITHM as Algorithm,
      colors: MAX_COLORS_TO_EXTRACT,
    }),
  );

  return colors.data.colors?.palette;
};

const getParamsToString = (gptParams: GptParams) => {
  let stringParams = '';

  for (const param in gptParams) {
    if (Object.hasOwn(gptParams, param)) {
      stringParams += `${param}: ${gptParams[param]} `;
    }
  }

  return stringParams;
};

const fetchGenareteFromImageV2 = async (
  metaSiteInstanceId: string,
  gptParamsData: GptParams,
) => {
  try {
    const httpClient = new HttpClient({
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: metaSiteInstanceId,
      },
    });
    const requestBody = {
      appDefId: AI_GATEWAY_APP_DEF_ID,
      promptId: AI_GATEWAY_FROM_LOGO_PROMPT_ID,
      params: {
        COLORS: getParamsToString(gptParamsData),
      },
    };
    const data = httpClient.post(AI_GATEWAY_URL, requestBody);
    return data;
  } catch (error) {
    throw new Error(`GPT Server Error: ${error}`);
  }
};

const fetchGenareteFromImageAndCurrentPalette = async (
  metaSiteInstanceId: string,
  appDefId: string,
  colorsFromImage: GptParams,
  currentPaletteToGpt: string,
  promptId?: ValidPromptId,
) => {
  try {
    const httpClient = new HttpClient({
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: metaSiteInstanceId,
      },
    });
    const requestBody = {
      appDefId,
      promptId: promptId || AI_GATEWAY_COLORS_FROM_IMAGE_AND_PALETTE_PROMPT_ID,
      params: {
        COLORS: getParamsToString(colorsFromImage),
        CURRENT_USER_PALETTE: currentPaletteToGpt,
      },
    };
    const data = httpClient.post(AI_GATEWAY_URL_HIDDEN, requestBody);
    return data;
  } catch (error) {
    throw new Error(`GPT Server Error: ${error}`);
  }
};

const getGptPromptsVersion = (colorsFromColorExtractor: Color[]) => {
  return colorsFromColorExtractor.length <= 6
    ? GPT_PROMPT_VERSION_STRICT
    : GPT_PROMPT_VERSION_CREATIVE;
};

const fetchColorFromPromptV2 = async (
  metaSiteInstanceId: string,
  gptParamsData: GptParams,
) => {
  try {
    const httpClient = new HttpClient({
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: metaSiteInstanceId,
      },
    });
    const requestBody = {
      appDefId: AI_GATEWAY_APP_DEF_ID,
      promptId: AI_GATEWAY_FROM_TEXT_PROMPT_ID,
      params: {
        ...gptParamsData,
      },
    };
    const data = httpClient.post(AI_GATEWAY_URL, requestBody);
    return data;
  } catch (error) {
    throw new Error(`GPT Server Error: ${error}`);
  }
};

const getColorsPaletteAndLinkedPalette = (
  promptPalette: Partial<ColorPalette>,
) => {
  const updateMainColorFromPromptPalette: Partial<ColorPalette> = {};
  const updateLinkedColorFromPromptPalette: Partial<
    Record<keyof ColorPalette, any>
  > = {};
  Object.entries(promptPalette).forEach(([colorName, colorValue]) => {
    if (colorValue[0] === '#') {
      updateMainColorFromPromptPalette[colorName] = colorValue;
    } else {
      updateLinkedColorFromPromptPalette[colorName] = colorValue;
    }
  });

  return {
    updateMainColorFromPromptPalette,
    updateLinkedColorFromPromptPalette,
  };
};

const isAccentColor = (colorName: ColorName): boolean => {
  return ALL_ACCENTS_COLORS.includes(colorName);
};

const findLinkedColors = (
  colorName: ColorName,
  linkedColors: LinkedColors | undefined,
): ColorName[] => {
  const colorNamesToUpdate: ColorName[] = [];
  if (!linkedColors) {
    return colorNamesToUpdate;
  }
  _.forOwn(
    linkedColors,
    (
      value: ColorName | typeof EMPTY_COLOR_NAME_VALUE | undefined,
      key: string,
    ) => {
      if (value === colorName) {
        colorNamesToUpdate.push(key as ColorName);
      }
    },
  );

  return colorNamesToUpdate;
};

const getDependentLinksToUpdate = (
  linkToUpdate,
  currentLinkedColorsPalette,
) => {
  return _.reduce(
    linkToUpdate,
    (acc, colorNameToLinkTo, colorNameToUpdate) => {
      if (
        colorNameToLinkTo === '__empty__' &&
        !isAccentColor(colorNameToUpdate as ColorName)
      )
        return acc;

      const linked: ColorName[] = findLinkedColors(
        colorNameToUpdate as ColorName,
        currentLinkedColorsPalette,
      );
      linked.forEach((color) => (acc[color] = colorNameToLinkTo));
      return acc;
    },
    {} as LinkedColors,
  );
};

const getDependentHexToUpdate = (hexToUpdate, allLinksToUpdate) => {
  return _.reduce(
    hexToUpdate,
    (acc, hex, colorNameToUpdate) => {
      const linked = findLinkedColors(
        colorNameToUpdate as ColorName,
        allLinksToUpdate,
      );
      linked.forEach((color) => {
        acc[color] = hex;
      });

      // E.g. given linked colors { color_42: color_41 },
      // we call update with { color_42: #fff }.
      // Then, we need to delete 42 to 41 link, because 42 is no more dependent on 41
      if (isAccentColor(colorNameToUpdate as ColorName)) {
        delete allLinksToUpdate[colorNameToUpdate as ColorName];
      }
      return acc;
    },
    {} as ColorPalette,
  );
};

const getLinkedHexToUpdate = (
  linksToUpdate,
  dependentLinksToUpdate,
  hexUpdates,
  currentPalette,
) => {
  return _.reduce(
    { ...linksToUpdate, ...dependentLinksToUpdate },
    (acc, colorLinkedTo, colorNameToUpdate) => {
      if (colorLinkedTo === EMPTY_COLOR_NAME_VALUE) return acc;

      acc[colorNameToUpdate as ColorName] =
        hexUpdates[colorLinkedTo as ColorName] ??
        currentPalette[colorLinkedTo as ColorName];
      return acc;
    },
    {} as ColorPalette,
  );
};

export {
  createGptLogoParams,
  getGptPromptsVersion,
  getFullPalettes,
  fetchExtractColorsFromImage,
  fetchGenareteFromImageV2,
  fetchColorFromPromptV2,
  getColorsPaletteAndLinkedPalette,
  getDependentLinksToUpdate,
  getDependentHexToUpdate,
  getLinkedHexToUpdate,
  fetchGenareteFromImageAndCurrentPalette,
  getFullPalettesBasedOnLinkedColors,
};
