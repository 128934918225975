import { COMP_TYPES } from '../siteGeneratorUtilsConsts';

import type { DocumentServicesObject } from '@wix/document-services-types';
import type { AddedPageWithRef } from '../types';

// We don't have a real need to wait for changes applied in this flow, so currently not waiting for finish.
export const removeEmptyTransparentBoxes = async (
  ds: DocumentServicesObject,
  allPages: AddedPageWithRef[],
): Promise<void> => {
  const rootRefs = [
    ...allPages.map(({ pageRef }) => pageRef),
    ds.siteSegments.getHeader(),
    ds.siteSegments.getFooter(),
  ];
  rootRefs.forEach((rootRef) => {
    const boxesChildrenOfSection = ds.components
      .getChildren(rootRef, true)
      .filter(
        (childRef) => ds.components.getType(childRef) === COMP_TYPES.CONTAINER,
      )
      .filter((boxRef) => ds.components.getChildren(boxRef).length === 0);
    boxesChildrenOfSection.forEach((boxRef) => {
      ds.components.remove(boxRef);
    });
  });
};
