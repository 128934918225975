import { SectionPresetStructure } from '../../types';
import { SerializedCompStructure } from '@wix/document-services-types';

const parallaxBehaviorName = 'BgParallax';

const removeParallaxBehaviorsRecursively = (
  structure: SerializedCompStructure,
): void => {
  if (structure.behaviors?.items?.includes(parallaxBehaviorName)) {
    delete structure.behaviors;
  }
  structure.components?.forEach((childStructure) => {
    removeParallaxBehaviorsRecursively(childStructure);
  });
};

export const removeParallaxBehaviors = (
  sections: SectionPresetStructure[],
): SectionPresetStructure[] => {
  sections.forEach(({ structure, mobileStructure }) => {
    removeParallaxBehaviorsRecursively(structure);
    removeParallaxBehaviorsRecursively(mobileStructure);
  });
  return sections;
};
