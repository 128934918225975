import _ from 'lodash';
import {
  arraysHaveIntersection,
  getIndexOfLongestArray,
  findAllIndicesByCondition,
} from './arrayUtils';
import type {
  HeaderPresetDefinition,
  SectionPresetDefinition,
  FooterPresetDefinition,
  PresetDefinition,
} from '../types';
import { chooseRandomIndex } from './siteGeneratorUtils';
import { HomepagePresets } from '../types';

const STYLE_EXCEPTION_INDUSTRY_ID = ['c4e29ccec1b3103f4caa52f0'];
const STYLE_EXCEPTION_STRUCTURE_ID = ['7de435a5405481f73e84a8cd'];

export const isExceptionToStyleRules = (
  structureId: string,
  industryId: string,
) => {
  return (
    STYLE_EXCEPTION_STRUCTURE_ID.includes(structureId) &&
    STYLE_EXCEPTION_INDUSTRY_ID.includes(industryId)
  );
};

export const filterSectionsMatrixByDuplicates = (
  sectionsMatrix: SectionPresetDefinition[][],
): SectionPresetDefinition[][] => {
  const existingSectionsIds: string[] = [];
  return sectionsMatrix
    .map((sectionsList) => {
      const filteredSectionsList = sectionsList.filter(
        ({ _id }) => !existingSectionsIds.includes(_id),
      );
      existingSectionsIds.push(...filteredSectionsList.map(({ _id }) => _id));
      return filteredSectionsList;
    })
    .filter((sectionsList) => sectionsList.length);
};

export const getHomepagePresets = (
  headers: HeaderPresetDefinition[],
  sectionsMatrix: SectionPresetDefinition[][],
  footers: FooterPresetDefinition[],
  allSectionsSameStyleRule: boolean,
): HomepagePresets => {
  let filteredSectionsMatrix = filterSectionsMatrixByDuplicates(sectionsMatrix);
  console.log(
    `filteredSectionsMatrix: ${filteredSectionsMatrix.length} items`,
    filteredSectionsMatrix.map((sections) => sections.length),
  );
  filteredSectionsMatrix = filterByAvailableHeadersAndFooters(
    headers,
    filteredSectionsMatrix,
    footers,
  );
  const shuffledSectionsMatrix = filteredSectionsMatrix.map((sectionList) =>
    _.shuffle(sectionList),
  ) as SectionPresetDefinition[][];

  const chosenSections = getSectionsWithLongestStylePath(
    shuffledSectionsMatrix,
    allSectionsSameStyleRule,
  ) as SectionPresetDefinition[];

  return {
    headerPreset: headers.length
      ? (choosePresetWithSameStyle(
          headers,
          chosenSections[0],
        ) as HeaderPresetDefinition)
      : null,
    sectionsPresets: chosenSections,
    footerPreset: footers.length
      ? (choosePresetWithSameStyle(
          footers,
          chosenSections[chosenSections.length - 1],
        ) as FooterPresetDefinition)
      : null,
  };
};

const filterByAvailableHeadersAndFooters = (
  headers: HeaderPresetDefinition[],
  sectionsMatrix: SectionPresetDefinition[][],
  footers: FooterPresetDefinition[],
): SectionPresetDefinition[][] => {
  if (headers.length) {
    sectionsMatrix[0] = filterSectionPresetsByExistingMatch(
      sectionsMatrix[0],
      headers,
    );
  }
  if (footers.length) {
    sectionsMatrix[sectionsMatrix.length - 1] =
      filterSectionPresetsByExistingMatch(
        sectionsMatrix[sectionsMatrix.length - 1],
        footers,
      );
  }
  return sectionsMatrix;
};

const filterSectionPresetsByExistingMatch = (
  sectionList: SectionPresetDefinition[],
  presetsListToMatch: HeaderPresetDefinition[] | FooterPresetDefinition[],
) => {
  const filteredSectionList = sectionList.filter((section) => {
    return presetsListToMatch.some((preset) =>
      arraysHaveIntersection(preset.styleRules ?? [], section.styleRules ?? []),
    );
  });
  return filteredSectionList.length ? filteredSectionList : sectionList;
};

const getSectionsWithLongestStylePath = (
  sections: SectionPresetDefinition[][],
  allSectionsSameStyleRule: boolean,
): SectionPresetDefinition[] => {
  let resultPath = longestStylePathRecursive(
    [],
    sections,
    allSectionsSameStyleRule,
  );

  while (resultPath.length < sections.length) {
    resultPath.push(chooseRandomIndex(sections[resultPath.length].length));
    resultPath = longestStylePathRecursive(
      resultPath,
      sections,
      allSectionsSameStyleRule,
    );
  }

  return sections.map((sectionList, index) => {
    return sectionList[resultPath[index]];
  });
};

const longestStylePathRecursive = (
  currPath: number[],
  sectionsMatrix: SectionPresetDefinition[][],
  allSectionsSameStyleRule: boolean,
) => {
  if (currPath.length === sectionsMatrix.length) {
    return currPath;
  }

  const prevStyleRules =
    currPath.length > 0
      ? sectionsMatrix[currPath.length - 1][currPath[currPath.length - 1]]
          .styleRules ?? []
      : [];
  const nextSectionPossibleIndices = prevStyleRules.length
    ? findAllIndicesByCondition(sectionsMatrix[currPath.length], (section) => {
        const areSectionsSameStyle = arraysHaveIntersection(
          prevStyleRules,
          section.styleRules ?? [],
        );
        return allSectionsSameStyleRule
          ? areSectionsSameStyle
          : !areSectionsSameStyle;
      })
    : Array.from(
        { length: sectionsMatrix[currPath.length].length },
        (_, i) => i,
      );
  const possiblePaths = nextSectionPossibleIndices.map((sectionIndex) =>
    longestStylePathRecursive(
      [...currPath, sectionIndex],
      sectionsMatrix,
      allSectionsSameStyleRule,
    ),
  );

  const longestPathIndex = getIndexOfLongestArray(possiblePaths);
  return possiblePaths[longestPathIndex] ?? currPath;
};

const choosePresetWithSameStyle = (
  presetsList: HeaderPresetDefinition[] | FooterPresetDefinition[],
  presetToMatch: SectionPresetDefinition,
) => {
  const styleRules = presetToMatch.styleRules ?? [];
  const filteredPresets = (presetsList as PresetDefinition[]).filter((preset) =>
    arraysHaveIntersection(styleRules, preset.styleRules ?? []),
  );
  if (!filteredPresets.length) {
    return presetsList[chooseRandomIndex(presetsList.length)];
  }
  return filteredPresets[chooseRandomIndex(filteredPresets.length)];
};
