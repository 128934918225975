import { MAX_SUBSAMPLE_HEIGHT, MAX_SUBSAMPLE_WIDTH } from './consts';

export const computeLuminosity = ({ red, green, blue }: RGBColorObject) => {
  return Math.sqrt(
    red * red * 0.241 + green * green * 0.691 + blue * blue * 0.068,
  );
};

export const computeAverageLuminosity = async (url: string) => {
  const pixels = await getImageData(url);
  let avg = 0;
  for (let i = 0; i < pixels.length; i += 4) {
    const red = pixels[i];
    const green = pixels[i + 1];
    const blue = pixels[i + 2];
    avg += computeLuminosity({ red, green, blue }) / (pixels.length / 4);
  }
  return avg;
};

const getImageData = (url: string): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      if (
        img.width > MAX_SUBSAMPLE_WIDTH ||
        img.height > MAX_SUBSAMPLE_HEIGHT
      ) {
        const ratio = Math.min(
          MAX_SUBSAMPLE_WIDTH / img.width,
          MAX_SUBSAMPLE_HEIGHT / img.height,
        );
        canvas.width = img.width * ratio;
        canvas.height = img.height * ratio;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
      resolve(new Uint8Array(pixels.data));
    };
    img.onerror = function (err) {
      reject(err);
    };
    img.src = url;
  });
};

//copied from santa-editor/packages/theme
//reference: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgb = (hex: string): RGBColorObject => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : { red: 0, green: 0, blue: 0 };
};

interface RGBColorObject {
  red: number;
  green: number;
  blue: number;
}
