import type { ColorName } from '../palettesFromComponentApi/types';

export const SHADES_RATIOS = {
  shade1: 0.25,
  shade2: 0.5,
  shade3: 0.75,
};

export const RGB_COLORS = ['red', 'green', 'blue'] as const;

export enum COLOR_ROLES {
  MAIN_1 = 'MAIN_1',
  MAIN_2 = 'MAIN_2',
  SHADE_1 = 'SHADE_1',
  SHADE_2 = 'SHADE_2',
  SHADE_3 = 'SHADE_3',
  SECONDARY_1 = 'SECONDARY_1',
  SECONDARY_2 = 'SECONDARY_2',
  SECONDARY_3 = 'SECONDARY_3',
  SECONDARY_4 = 'SECONDARY_4',
  BACKGROUND = 'BACKGROUND',
  SECONDARY_BACKGROUND = 'SECONDARY_BACKGROUND',
  SECONDARY_TEXT = 'SECONDARY_TEXT',
  PRIMARY_TEXT = 'PRIMARY_TEXT',
  LINK = 'LINK',
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  LINE = 'LINE',
  PRIMARY_BUTTON_FILL = 'PRIMARY_BUTTON_FILL',
  PRIMARY_BUTTON_BORDER = 'PRIMARY_BUTTON_BORDER',
  PRIMARY_BUTTON_TEXT = 'PRIMARY_BUTTON_TEXT',
  PRIMARY_BUTTON_FILL_HOVER = 'PRIMARY_BUTTON_FILL_HOVER',
  PRIMARY_BUTTON_BORDER_HOVER = 'PRIMARY_BUTTON_BORDER_HOVER',
  PRIMARY_BUTTON_TEXT_HOVER = 'PRIMARY_BUTTON_TEXT_HOVER',
  PRIMARY_BUTTON_FILL_DISABLED = 'PRIMARY_BUTTON_FILL_DISABLED',
  PRIMARY_BUTTON_BORDER_DISABLED = 'PRIMARY_BUTTON_BORDER_DISABLED',
  PRIMARY_BUTTON_TEXT_DISABLED = 'PRIMARY_BUTTON_TEXT_DISABLED',
  SECONDARY_BUTTON_FILL = 'SECONDARY_BUTTON_FILL',
  SECONDARY_BUTTON_BORDER = 'SECONDARY_BUTTON_BORDER',
  SECONDARY_BUTTON_TEXT = 'SECONDARY_BUTTON_TEXT',
  SECONDARY_BUTTON_FILL_HOVER = 'SECONDARY_BUTTON_FILL_HOVER',
  SECONDARY_BUTTON_BORDER_HOVER = 'SECONDARY_BUTTON_BORDER_HOVER',
  SECONDARY_BUTTON_TEXT_HOVER = 'SECONDARY_BUTTON_TEXT_HOVER',
  SECONDARY_BUTTON_FILL_DISABLED = 'SECONDARY_BUTTON_FILL_DISABLED',
  SECONDARY_BUTTON_BORDER_DISABLED = 'SECONDARY_BUTTON_BORDER_DISABLED',
  SECONDARY_BUTTON_TEXT_DISABLED = 'SECONDARY_BUTTON_TEXT_DISABLED',
}

export const COLOR_ROLE_TO_COLOR_NAME_MAP: Record<COLOR_ROLES, ColorName> = {
  [COLOR_ROLES.BACKGROUND]: 'color_11',
  [COLOR_ROLES.SECONDARY_BACKGROUND]: 'color_12',
  [COLOR_ROLES.SECONDARY_TEXT]: 'color_14',
  [COLOR_ROLES.PRIMARY_TEXT]: 'color_15',
  [COLOR_ROLES.LINK]: 'color_18',
  [COLOR_ROLES.MAIN_1]: 'color_36',
  [COLOR_ROLES.MAIN_2]: 'color_37',
  [COLOR_ROLES.SHADE_1]: 'color_38',
  [COLOR_ROLES.SHADE_2]: 'color_39',
  [COLOR_ROLES.SHADE_3]: 'color_40',
  [COLOR_ROLES.SECONDARY_1]: 'color_41',
  [COLOR_ROLES.SECONDARY_2]: 'color_42',
  [COLOR_ROLES.SECONDARY_3]: 'color_43',
  [COLOR_ROLES.SECONDARY_4]: 'color_44',
  [COLOR_ROLES.TITLE]: 'color_45',
  [COLOR_ROLES.SUBTITLE]: 'color_46',
  [COLOR_ROLES.LINE]: 'color_47',
  [COLOR_ROLES.PRIMARY_BUTTON_FILL]: 'color_48',
  [COLOR_ROLES.PRIMARY_BUTTON_BORDER]: 'color_49',
  [COLOR_ROLES.PRIMARY_BUTTON_TEXT]: 'color_50',
  [COLOR_ROLES.PRIMARY_BUTTON_FILL_HOVER]: 'color_51',
  [COLOR_ROLES.PRIMARY_BUTTON_BORDER_HOVER]: 'color_52',
  [COLOR_ROLES.PRIMARY_BUTTON_TEXT_HOVER]: 'color_53',
  [COLOR_ROLES.PRIMARY_BUTTON_FILL_DISABLED]: 'color_54',
  [COLOR_ROLES.PRIMARY_BUTTON_BORDER_DISABLED]: 'color_55',
  [COLOR_ROLES.PRIMARY_BUTTON_TEXT_DISABLED]: 'color_56',
  [COLOR_ROLES.SECONDARY_BUTTON_FILL]: 'color_57',
  [COLOR_ROLES.SECONDARY_BUTTON_BORDER]: 'color_58',
  [COLOR_ROLES.SECONDARY_BUTTON_TEXT]: 'color_59',
  [COLOR_ROLES.SECONDARY_BUTTON_FILL_HOVER]: 'color_60',
  [COLOR_ROLES.SECONDARY_BUTTON_BORDER_HOVER]: 'color_61',
  [COLOR_ROLES.SECONDARY_BUTTON_TEXT_HOVER]: 'color_62',
  [COLOR_ROLES.SECONDARY_BUTTON_FILL_DISABLED]: 'color_63',
  [COLOR_ROLES.SECONDARY_BUTTON_BORDER_DISABLED]: 'color_64',
  [COLOR_ROLES.SECONDARY_BUTTON_TEXT_DISABLED]: 'color_65',
};

export const ALL_ACCENTS_COLORS = [
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_1,
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_2,
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_3,
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_4,
];

export const SHADES_COLORS: ColorName[] = ['color_38', 'color_39', 'color_40'];
