import { createReduxStore } from '#packages/apilib';
import type { PageRef, MiniSiteManager } from '@wix/document-services-types';

export interface PreviewerState {
  miniSiteManager?: MiniSiteManager;
  componentsPageContainerPointer?: PageRef;
}

const getInitialState = (): PreviewerState => ({});

export const PreviewerStore = createReduxStore({
  getInitialState,
  selectors: {
    getMiniSiteManager: (state: PreviewerState) => state.miniSiteManager,
  },
  actions: {
    setMiniSiteManager: (
      state: PreviewerState,
      miniSiteManager: PreviewerState['miniSiteManager'],
    ) => {
      return {
        ...state,
        miniSiteDs: miniSiteManager.ds,
        miniSiteManager,
      };
    },
    setComponentsPageContainerPointer: (
      state: PreviewerState,
      componentsPageContainerPointer: PreviewerState['componentsPageContainerPointer'],
    ) => {
      return {
        ...state,
        componentsPageContainerPointer,
      };
    },
  },
});
