export const ALLOWED_HISTORY_MESSAGES_AMOUNT = 6;

export const MISSING_MESSAGE = '<<missing>>';

export enum UnrelatedAction {
  EDITOR_ACTION = 'Editor Action',
  BRIEF_ACTION = 'Brief Action',
  IRRELEVANT = 'Irrelevant',
  DESCRIBE = 'Describe',
}

export enum ChatMessageRole {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  ASSISTANT = 'ASSISTANT',
}

export enum ChatTopic {
  Theme = 'theme',
  Coloration = 'coloration',
  ThemeRecommendation = 'theme_recommendation',
  Layout = 'layout',
  ImageCollection = 'imageCollection',
  SiteProfile = 'siteProfile',
  Structure = 'structure',
  Media = 'media',
  Business = 'business',
  ColorPalette = 'colorPalette',
  Description = 'description',
  MatchImages = 'matchImages',
}

export enum InitialPromptActions {
  UNRELATED = 'Unrelated',
  DONE = 'Done',
  CHANGE_THEME = 'Change Theme',
  APPLY_THEME_VARIATION = 'Apply Theme Variation',
  APPLY_LAYOUT_VARIATION = 'Apply Layout Variation',
  SHUFFLE_COLORS = 'Shuffle Colors',
}

export enum PromptNames {
  THEME_RELATED = 'theme_related',
  LAYOUT_RELATED = 'layout_related',
}

export enum PromptHubAcceptedUrls {
  AI_GATEWAY_URL = '/_serverless/ai-gateway/prompt',
  AI_GATEWAY_URL_NEW = '/_serverless/ai-gateway/ai-prompt',
  AI_GATEWAY_OBJECT_URL = '/_serverless/ai-gateway/object-prompt',
  AI_GATEWAY_OBJECT_URL_NEW = '/_serverless/ai-gateway/ai-object-prompt',
  AI_GATEWAY_GET_PROMPT_URL = '/_serverless/ai-gateway/get-prompt',
}

export const AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS = `e36c4531-99b6-4a9a-b523-451cca8bf286`;

export const PROMPT_HUB_PROMPTS_ID = {
  MATCH_IMAGES_PROMPT: `9a1df2df-857d-4f45-9c74-2a34411638bd`,
  THEME_PROMPT: `c07b2ed7-fdd0-41ad-b952-01f5d3e0ec8f`,
  THEME_RECOMMENDATION_PROMPT: `986197c1-da54-4302-9851-ac83d905205d`,
  LAYOUT_PROMPT: `843a1261-af81-4187-adf6-24032c43fe7c`,
  THEME_RELATED: `20b8edea-817a-4755-b6b1-b9d3ece3bb4b`,
  THEME_RELATED_LOCALIZATION: 'c6813ef7-9f2b-4dea-9362-a7280f4789ac',
  THEME_UNRELATED: `be27d496-2ff0-4e46-a765-5211804a1a34`,
  LAYOUT_RELATED: `0a294829-cf72-4eb0-a9ce-ca6bcfe678ec`,
  LAYOUT_RELATED_LOCALIZATION: '5df7b02b-4af2-4438-86c1-318ae5737aaf',
  LAYOUT_UNRELATED: `f7859de9-9615-4aa9-9b1b-d09d349f1657`,
  USER_INPUT_TRANSLATOR: '807af37d-6e05-40b1-85f6-785496b91760',
};

export enum MatchImagesPromptResponseProperties {
  IMAGE_SET = 'Image Set',
}

export const emptyInitialUserMessage = {
  content: '',
  role: ChatMessageRole.User,
};
