import type { SerializedCompStructure } from '@wix/document-services-types';
import { transformCompStructure } from './siteGeneratorUtils';
import {
  WIX_BLOG,
  WIX_STORES,
  WIX_NEW_STORES,
  WIX_SITE_SEARCH,
  MEMBERS_AREA,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_RESTAURANTS_ORDERS_NEW,
} from '@wix/app-definition-ids';

export const getWidgetToRootAppMap = (
  isUsingSgOrigin: boolean,
): Record<string, string[]> => ({
  [WIX_SITE_SEARCH]: [WIX_BLOG],
  [WIX_STORES]: [WIX_NEW_STORES, WIX_RESTAURANTS_ORDERS_NEW],
  [MEMBERS_AREA]: isUsingSgOrigin
    ? [
        WIX_NEW_STORES,
        WIX_BLOG,
        WIX_BOOKINGS,
        WIX_EVENTS,
        WIX_RESTAURANTS_ORDERS_NEW,
      ]
    : [WIX_RESTAURANTS_ORDERS_NEW],
});

export const adjustHeaderWidgets = (
  isAppInstalled: (appDefId: string) => boolean,
  headerStructure: SerializedCompStructure,
): SerializedCompStructure => {
  const mutator = (comp: SerializedCompStructure) => {
    return filterHeaderWidgets(isAppInstalled, comp);
  };
  return transformCompStructure(headerStructure, mutator);
};

const filterHeaderWidgets = (
  isAppInstalled: (appDefId: string) => boolean,
  comp: SerializedCompStructure,
): SerializedCompStructure => {
  if (!comp.components) {
    return comp;
  }
  comp.components = comp.components.filter((childComponent) =>
    filterWidget(isAppInstalled, childComponent),
  );
  return comp;
};

function filterWidget(
  isAppInstalled: (appDefId: string) => boolean,
  comp: SerializedCompStructure,
) {
  const id = comp.data?.appDefinitionId || comp.data?.applicationId;
  if (id) {
    return isAppInstalled(id);
  }
  return true;
}

export const adjustHeaderWidgetsByRootApp = (
  headerStructure: SerializedCompStructure,
  rootApps: string[],
  isUsingSgOrigin: boolean,
): SerializedCompStructure => {
  const widgetToRootAppMap = getWidgetToRootAppMap(isUsingSgOrigin);
  const shouldKeepWidgetByRootApp = (appDefId: string): boolean => {
    const widgetRootApps = widgetToRootAppMap[appDefId];
    if (widgetRootApps?.length) {
      return widgetRootApps.some((appDefId) => rootApps.includes(appDefId));
    }
    return true;
  };
  const mutator = (comp: SerializedCompStructure) => {
    return filterHeaderWidgets(shouldKeepWidgetByRootApp, comp);
  };
  return transformCompStructure(headerStructure, mutator);
};
