import _ from 'lodash';
import type { AddedPageWithRef } from '../types';
import type {
  DocumentServicesObject,
  MenuItem,
} from '@wix/document-services-types';

export const reorderSitePages = (
  ds: DocumentServicesObject,
  allPages: AddedPageWithRef[],
) => {
  const CUSTOM_MAIN_MENU_ID = 'CUSTOM_MAIN_MENU';
  const menuData =
    ds.menu.getByType('MAIN_MENU')?.[0] || ds.menu.getById(CUSTOM_MAIN_MENU_ID);
  const reorderedItems = reorderMenuItems(allPages, menuData.items);
  ds.menu.update(menuData.id, { ...menuData, items: reorderedItems });
};

const getPageId = (item: MenuItem) => {
  // @ts-ignore
  const pageId = item.link?.pageId;
  if (!pageId) return;
  return getCleanPageId(pageId);
};

const getCleanPageId = (pageId: string) => {
  return _.startsWith(pageId, '#') ? pageId.substring(1) : pageId;
};

const reorderMenuItems = (
  allPages: AddedPageWithRef[],
  menuItems: MenuItem[],
): MenuItem[] => {
  const orderMap = {};
  allPages.forEach(({ pageRef }, index) => {
    orderMap[pageRef.id] = index;
  });

  menuItems.sort((itemA, itemB) => {
    const pageIdA = getPageId(itemA);
    const pageIdB = getPageId(itemB);
    const indexA = pageIdA ? orderMap[pageIdA] : undefined;
    const indexB = pageIdB ? orderMap[pageIdB] : undefined;

    if (indexA === undefined && indexB === undefined) {
      return 0;
    } else if (indexA === undefined) {
      return 1;
    } else if (indexB === undefined) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });

  return menuItems;
};
