._item_15i40_1 {
  position: relative;
  overflow: hidden;
  transform-origin: 0 0; }
  ._item_15i40_1 ._overlay_15i40_5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; }
