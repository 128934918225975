import { createPageStructure } from './siteGeneratorUtils';

import type {
  FooterPresetStructure,
  FullHomepageStructure,
  HeaderPresetStructure,
  SectionPresetStructure,
  PresetStructure,
} from '../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

function isPresetStructure<T extends PresetStructure>(obj: T | null): obj is T {
  return Boolean(obj?.id);
}

const disableHeaderFixedPosition = (
  headerStructure: SerializedCompStructure,
): void => {
  if (headerStructure.layout) {
    headerStructure.layout.fixedPosition = false;
  }
};

const setHeaderPreviewTransformations = (
  headerStructure: SerializedCompStructure,
): void => {
  // @ts-ignore
  headerStructure.transformations = {
    type: 'TransformData',
    zLayer: 'BELOW_PINNED',
  };
};
const uniteHeaderAndFirstSectionMobile = (
  header: HeaderPresetStructure | null,
  firstSection: SectionPresetStructure,
): SectionPresetStructure => {
  if (!header) {
    return firstSection;
  }
  disableHeaderFixedPosition(header.mobileStructure);
  setHeaderPreviewTransformations(header.mobileStructure);

  const pageStructure = createPageStructure(
    'headerAndFirstSection',
    [header, firstSection],
    true,
  );
  return {
    ...firstSection,
    mobileStructure: pageStructure as SerializedCompStructure,
    sourceTemplateId:
      firstSection.sourceTemplateId || header.sourceTemplateId || '',
  };
};

const uniteHeaderAndFirstSectionDesktop = (
  header: HeaderPresetStructure | null,
  firstSection: SectionPresetStructure,
): SectionPresetStructure => {
  if (!header) {
    return firstSection;
  }
  disableHeaderFixedPosition(header.structure);
  setHeaderPreviewTransformations(header.structure);

  const pageStructure = createPageStructure(
    'headerAndFirstSection',
    [header, firstSection],
    false,
  );
  return {
    ...firstSection,
    structure: pageStructure as SerializedCompStructure,
    sourceTemplateId:
      firstSection.sourceTemplateId || header.sourceTemplateId || '',
  };
};

export const composeHomepagePreviews = (
  { header, sections, footer }: FullHomepageStructure,
  isMobile: boolean,
): Array<
  FooterPresetStructure | SectionPresetStructure | HeaderPresetStructure
> => {
  const [firstSection, ...restSections] = sections;
  const headerAndFirstSection = isMobile
    ? uniteHeaderAndFirstSectionMobile(header, firstSection)
    : uniteHeaderAndFirstSectionDesktop(header, firstSection);
  return [headerAndFirstSection, ...restSections, footer].filter(
    isPresetStructure,
  );
};
