export interface Message {
  getTextualContent: () => string;
}

export class TextMessage implements Message {
  paragraphs: string[];

  constructor(content: string) {
    this.paragraphs = content.split('\n');
  }

  getTextualContent = () => {
    return this.paragraphs?.join('\n');
  };
}
