import {
  initSetup,
  createPreviewComponents,
  prefetchCustomCSM,
} from './previewerApi';
import type { CreatePreviewComponentsParams } from './previewerApi';
import type { MiniSiteManager } from '@wix/document-services-types';

export interface StandalonePreviewerApi {
  createPreviewComponents: (
    params: CreatePreviewComponentsParams,
  ) => Promise<React.ReactElement[]>;
  prefetchCustomCSM: (publishedTemplateMetasiteId: string) => Promise<void>;
}

export const createStandalonePreviewerApi = async (
  miniSiteManager: MiniSiteManager,
) => {
  initSetup(miniSiteManager);

  const { ds, createPreviewElements } = miniSiteManager;

  const standalonePreviewerApi = {
    createPreviewComponents: async (params: CreatePreviewComponentsParams) => {
      return createPreviewComponents(ds, createPreviewElements, params);
    },
    prefetchCustomCSM: async (publishedTemplateMetasiteId: string) => {
      return prefetchCustomCSM(ds, publishedTemplateMetasiteId);
    },
  };

  return standalonePreviewerApi;
};
