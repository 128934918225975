import type { HttpClient } from '@wix/http-client';
import type { ReportError } from '@wix/editor-content-injector';
import SgThemeFilteringService from '../sgThemeFilteringService/sgThemeFilteringService';
import {
  ChatMessageRole,
  ChatTopic,
  InitialPromptActions,
  PromptNames,
  UnrelatedAction,
} from './constants';
import {
  OpenAiChatCompletionRequest,
  ParsedInitialResponse,
  ParsedRelatedActionResponse,
} from './types';
import {
  getInitialResponseFromPromptHub,
  getPromptByPromptName,
  getThemeSuggestionPromptHub,
  getEnglishTranslatedUserInput,
  getActionFromUnrelatedPromptHub,
  getNewExtendedKitForShuffleColors,
  getRelatedUserMessage,
  getRelatedPromptAnswerPromptHubHidden,
  getRelatedPromptNameByChatTopic,
  getRelatedPromptAnswerPromptHub,
  getMatchingProfileIdPromptHub,
} from './utils';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  KitDefinition,
  KitExtendedDescription,
  KitExtendedDescriptionKey,
  KitInjectionOptions,
} from '@wix/editor-kits';
import { ChatControllerBiEventNumbers } from '../constants';
import _ from 'lodash';
import { ChatMessage, Library, TopicValue } from '../types';
import { LayoutFamilyDefinition } from '../../types';

export class SgChat {
  client: HttpClient;
  reportDebug: ReportError;
  sgThemeFiltering: SgThemeFilteringService;
  private promptRelatedSystemMessagePromises: Record<
    PromptNames,
    Promise<OpenAiChatCompletionRequest | null>
  >;

  constructor(
    httpClient: HttpClient,
    reportDebug: ReportError,
    sgThemeFiltering: SgThemeFilteringService,
    isHiddenPromptSpecOpen: boolean,
    isLocalizationSpecOpen: boolean,
  ) {
    this.client = httpClient;
    this.reportDebug = reportDebug;
    this.sgThemeFiltering = sgThemeFiltering;
    this.initSgChatPromptsSystemMessages(
      isHiddenPromptSpecOpen,
      isLocalizationSpecOpen,
    );
  }

  private async initSgChatPromptsSystemMessages(
    isHiddenPromptSpecOpen: boolean,
    isLocalizationSpecOpen: boolean,
  ) {
    if (isHiddenPromptSpecOpen) {
      return;
    }
    this.promptRelatedSystemMessagePromises = Object.values(PromptNames).reduce(
      (systemMessages, promptName) => {
        systemMessages[promptName] = getPromptByPromptName(
          promptName,
          this.client,
          isLocalizationSpecOpen,
        )
          .then((value) => value.prompt.openAiChatCompletionRequest)
          .catch((e) => {
            console.error(e);
            ErrorReporter.captureException(e, {
              tags: {
                siteGenerationWizard: true,
                siteGenerationFetchSystemMessage: true,
              },
              extra: {
                promptName,
              },
            });
            return null;
          });
        return systemMessages;
      },
      {} as Record<PromptNames, Promise<OpenAiChatCompletionRequest | null>>,
    );
  }

  private async filterTopThemes(
    userInput: string,
    kitExtendedDescriptions: {
      [key in KitExtendedDescriptionKey]: KitExtendedDescription;
    },
    sendSiteCommonBi: (
      evid: ChatControllerBiEventNumbers,
      fields: NonNullable<unknown>,
    ) => void,
  ) {
    try {
      ErrorReporter.breadcrumb('sgChat.filterTopThemes request', {
        userInput,
      });
      const res = await this.sgThemeFiltering.filterKitsByUserInput(
        userInput,
        kitExtendedDescriptions,
      );

      if (!res.output?.values?.top_themes?.strVal) {
        sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
          flow: 'SG',
          topic: ChatTopic.ThemeRecommendation,
          errn: 'top themes are null or empty',
          functionName: 'filterTopThemes',
        });
        return _.sampleSize(kitExtendedDescriptions, 5);
      }
      const topThemes = JSON.parse(res.output?.values?.top_themes?.strVal);

      ErrorReporter.breadcrumb('sgChat.filterTopThemes response', {
        topThemes,
      });
      if (!topThemes || topThemes?.length === 0) {
        sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
          flow: 'SG',
          topic: ChatTopic.ThemeRecommendation,
          errn: 'top themes are null or empty',
          functionName: 'filterTopThemes',
        });
        return _.sampleSize(kitExtendedDescriptions, 5);
      }

      return topThemes;
    } catch (error) {
      let errorMessage;
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
        flow: 'SG',
        topic: ChatTopic.ThemeRecommendation,
        errn: errorMessage,
        functionName: 'filterTopThemes',
      });
      ErrorReporter.captureException(error, {
        tags: {
          siteGenerationWizard: true,
          siteGenerationAiErrorResponse: true,
        },
        extra: {
          userInput,
        },
      });
      return _.sampleSize(kitExtendedDescriptions, 5);
    }
  }

  private async getInitialMiniChatAction(
    chatTopic: ChatTopic.Layout | ChatTopic.Theme | ChatTopic.MatchImages,
    initialUserMessage: ChatMessage,
    businessType: string,
    library: Library,
    kitExtendedDescriptions: {
      [key in KitExtendedDescriptionKey]: KitExtendedDescription;
    },
    userInput: string,
    sendSiteCommonBi: (
      evid: ChatControllerBiEventNumbers,
      fields: NonNullable<unknown>,
    ) => void,
    isHiddenPromptSpecOpen: boolean,
    isLocalizationPromptSpecOpen: boolean,
  ): Promise<ParsedInitialResponse> {
    const parsedResponse = await getInitialResponseFromPromptHub(
      chatTopic,
      initialUserMessage,
      businessType,
      library,
      userInput,
      this.client,
      this.reportDebug,
      sendSiteCommonBi,
      isHiddenPromptSpecOpen,
    );

    if (parsedResponse.action === InitialPromptActions.CHANGE_THEME) {
      const translatedUserInput = await getEnglishTranslatedUserInput(
        userInput,
        chatTopic,
        this.client,
        isLocalizationPromptSpecOpen,
        isHiddenPromptSpecOpen,
        sendSiteCommonBi,
      );
      const response: KitExtendedDescriptionKey =
        await getThemeSuggestionPromptHub(
          translatedUserInput,
          businessType,
          kitExtendedDescriptions,
          initialUserMessage,
          this.filterTopThemes.bind(this),
          this.client,
          this.reportDebug,
          sendSiteCommonBi,
          isHiddenPromptSpecOpen,
        );
      parsedResponse.suggestion = response;
    }

    return parsedResponse;
  }

  async sendMessage(
    userInput: string,
    initialUserMessage: ChatMessage,
    chatTopic: ChatTopic.Layout | ChatTopic.Theme,
    messagesHistory: ChatMessage[],
    library: Library,
    kitExtendedDescriptions: {
      [key in KitExtendedDescriptionKey]: KitExtendedDescription;
    },
    businessType: string,
    topicValue: TopicValue,
    setChatHistory: (chatTopic: ChatTopic, message: ChatMessage) => void,
    currentGenerated: {
      currentKit: KitDefinition;
      previousKit: KitDefinition | undefined;
      currentLayoutFamily: LayoutFamilyDefinition;
      currentKitInjectionOption: KitInjectionOptions;
      previousKitInjectionOption: KitInjectionOptions | undefined;
      isColorFromLogoNeeded: boolean;
    },
    languageCode: string,
    sendSiteCommonBi: (
      evid: ChatControllerBiEventNumbers,
      fields: NonNullable<unknown>,
    ) => void,
    isHiddenPromptSpecOpen: boolean,
    isLocalizationPromptSpecOpen: boolean,
  ): Promise<ParsedRelatedActionResponse> {
    const { action, suggestion } = await this.getInitialMiniChatAction(
      chatTopic,
      initialUserMessage,
      businessType,
      library,
      kitExtendedDescriptions,
      userInput,
      sendSiteCommonBi,
      isHiddenPromptSpecOpen,
      isLocalizationPromptSpecOpen,
    );
    let unrelatedAction: UnrelatedAction | undefined;
    if (action === InitialPromptActions.UNRELATED) {
      unrelatedAction = await getActionFromUnrelatedPromptHub(
        chatTopic,
        userInput,
        this.client,
        sendSiteCommonBi,
        isHiddenPromptSpecOpen,
      );
    }

    let newExtendetKitSuggestion: KitExtendedDescription | undefined;
    let topicDescriptionIndex =
      suggestion && suggestion !== 'NA' ? suggestion : topicValue.current;
    if (action === InitialPromptActions.SHUFFLE_COLORS) {
      newExtendetKitSuggestion = getNewExtendedKitForShuffleColors(
        kitExtendedDescriptions,
        currentGenerated,
      );
      topicDescriptionIndex = `${newExtendetKitSuggestion.kitName}_${newExtendetKitSuggestion.preset}`;
    }

    const contextDescription =
      chatTopic === ChatTopic.Theme
        ? kitExtendedDescriptions[
            topicDescriptionIndex as KitExtendedDescriptionKey
          ]?.description
        : library[topicDescriptionIndex]?.description;

    const relatedUserMessage = {
      role: ChatMessageRole.User,
      content: getRelatedUserMessage(
        chatTopic,
        userInput,
        unrelatedAction ? [unrelatedAction] : [action],
        action === InitialPromptActions.SHUFFLE_COLORS &&
          newExtendetKitSuggestion
          ? newExtendetKitSuggestion.description
          : contextDescription,
      ),
    };
    setChatHistory(chatTopic, relatedUserMessage);

    let promptAnswer: string;
    if (isHiddenPromptSpecOpen) {
      promptAnswer = await getRelatedPromptAnswerPromptHubHidden(
        chatTopic,
        relatedUserMessage,
        messagesHistory,
        this.client,
        sendSiteCommonBi,
        isLocalizationPromptSpecOpen,
        languageCode,
      );
    } else {
      const systemMessage =
        await this.promptRelatedSystemMessagePromises[
          getRelatedPromptNameByChatTopic(chatTopic)
        ];
      if (!systemMessage) {
        throw new Error(`systemMessage is not defined in ${chatTopic}`);
      }
      promptAnswer = await getRelatedPromptAnswerPromptHub(
        chatTopic,
        relatedUserMessage,
        messagesHistory,
        this.client,
        systemMessage,
        sendSiteCommonBi,
        isLocalizationPromptSpecOpen,
        languageCode,
      );
    }

    const newResponseMessageRecord = {
      role: ChatMessageRole.Assistant,
      content: promptAnswer,
    };
    setChatHistory(chatTopic, newResponseMessageRecord);

    return {
      content: promptAnswer,
      suggestion,
      action,
      isValidResponse: true,
      contextDescription,
      newExtendedKit: newExtendetKitSuggestion,
    };
  }

  async getMatchingProfileId(
    businessType: string,
    description: string,
    imageLibrary: Library,
    isHiddenPromptSpecOpen: boolean,
    sendSiteCommonBi: (
      evid: ChatControllerBiEventNumbers,
      fields: NonNullable<unknown>,
    ) => void,
  ): Promise<string | null> {
    return getMatchingProfileIdPromptHub(
      businessType,
      description,
      imageLibrary,
      this.reportDebug,
      this.client,
      isHiddenPromptSpecOpen,
      sendSiteCommonBi,
    );
  }
}
