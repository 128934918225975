import { SerializedCompStructure } from '@wix/document-services-types';
import { getInnerTextString } from '@wix/editor-content-injector';
import type {
  GeneratedHomepage,
  LockedPresetIds,
  SectionPresetStructure,
} from '../types';

export const logTextChanges = (
  injectorName: string,
  allSectionsBefore: SerializedCompStructure[],
  allSectionsAfter: SerializedCompStructure[],
) => {
  const changedText = {};
  const scanSectionForChanges = (
    sectionsBefore: SerializedCompStructure[],
    sectionsAfter: SerializedCompStructure[],
  ) => {
    sectionsBefore.forEach((sectionBefore, index) => {
      const sectionAfter = sectionsAfter[index];
      if (!sectionAfter) {
        return;
      }
      if (sectionBefore.data?.text) {
        if (sectionBefore.data?.text !== sectionAfter.data?.text) {
          changedText[sectionBefore.id!] = {
            before: getInnerTextString(sectionBefore.data?.text),
            after: getInnerTextString(sectionAfter.data?.text),
          };
        }
      }
      if (sectionBefore.components?.length) {
        scanSectionForChanges(
          sectionBefore.components,
          sectionAfter.components!,
        );
      }
    });
  };

  scanSectionForChanges(allSectionsBefore, allSectionsAfter);

  window.console.log(`INJECT ${injectorName} DIFFS:`, changedText);
};

export const getStringifiedSiteGeneratorDebugData = (
  params: object,
  presetIds: LockedPresetIds,
): string => {
  return `{"SGParams": ${JSON.stringify(
    params,
    null,
    '\t',
  )},\n"presetIds": ${JSON.stringify(presetIds, null, '\t')}}`;
};

export const copySiteGeneratorDataToClipboard = async (
  data: string,
  setShouldShowCopiedIndication: (shouldShowCopiedIndication: boolean) => void,
) => {
  await navigator.clipboard.writeText(data).then(
    () => {
      setShouldShowCopiedIndication(true);
      setTimeout(() => {
        setShouldShowCopiedIndication(false);
      }, 5000);
    },
    (err) => {
      console.error('Could not copy error data', err.message);
    },
  );
};

export const getPresetIdsFromGeneratedHomepage = (
  generatedHomepage: GeneratedHomepage,
) => {
  return {
    header: generatedHomepage.header?.id,
    sections: generatedHomepage.sections.map(
      (section: SectionPresetStructure) => section.id,
    ),
    footer: generatedHomepage?.footer?.id,
  };
};
