import type {
  SerializedCompStructure,
  StyleRef,
  VectorImageDesignData,
} from '@wix/document-services-types';
import type { KitDefinition } from '../../types';
import { isImageBackground } from '@wix/editor-content-injector';
import { wrapInnerTextWithSpanInlineColor } from '../../utils';
import _ from 'lodash';

interface ImageUrlToComponentsMap {
  [imageBgUrl: string]: {
    transparentImage: boolean;
    texts: SerializedCompStructure[];
    buttons: SerializedCompStructure[];
    verticalLines: SerializedCompStructure[];
    horizontalLines: SerializedCompStructure[];
    vectorImage: SerializedCompStructure[];
  };
}

export const getComponentsOnImageBG = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
  parentWithBackground: SerializedCompStructure | null = null,
): ImageUrlToComponentsMap => {
  const aggregator = {};
  getComponentsOnImageBG_Recursive(
    structure,
    kitDefinition,
    parentWithBackground,
    aggregator,
  );

  Object.keys(aggregator).forEach((url) => {
    if (aggregator[url].length === 0) {
      delete aggregator[url];
    }
  });

  return aggregator;
};

export const setTextColor = (text: SerializedCompStructure, color: string) => {
  if (!text?.data) {
    return;
  }
  if (text.data.text.includes('color_')) {
    text.data.text = text.data.text.replace(/color_\d+/g, color);
  } else {
    text.data.text = wrapInnerTextWithSpanInlineColor(text.data.text, color);
  }
};

export const setButtonColor = (
  button: SerializedCompStructure,
  kitDefinition: KitDefinition,
  mainColor: string,
  secondaryColor: string,
) => {
  let styleObject: StyleRef;
  if (typeof button.style === 'string') {
    styleObject = _.cloneDeep(
      kitDefinition.themeStyles[button.style],
    ) as StyleRef;
  } else {
    styleObject = button.style as StyleRef;
  }

  if (styleObject) {
    styleObject.style.properties = {
      ...styleObject.style.properties,
      'alpha-bg': '0',
      'alpha-bgh': '1',
      'alpha-brd': '1',
      'alpha-brdh': '1',
      bg: mainColor,
      bgh: mainColor,
      brd: mainColor,
      brdh: mainColor,
      brw: '1',
      txt: mainColor,
      txth: secondaryColor,
    };

    styleObject.style.propertiesSource = {
      ...styleObject.style.propertiesSource,
      'alpha-bg': 'value',
      'alpha-bgh': 'value',
      'alpha-brd': 'value',
      'alpha-brdh': 'value',
      bg: 'value',
      bgh: 'value',
      brd: 'value',
      brdh: 'value',
      brw: 'value',
      txt: 'value',
      txth: 'value',
    };
    delete styleObject.id;
    styleObject.styleType = 'custom';
    button.style = styleObject;
  }
};

export const setLineColor = (
  line: SerializedCompStructure,
  kitDefinition: KitDefinition,
  mainColor: string,
) => {
  let styleObject: StyleRef;
  if (typeof line.style === 'string') {
    styleObject = _.cloneDeep(
      kitDefinition.themeStyles[line.style],
    ) as StyleRef;
  } else {
    styleObject = line.style as StyleRef;
  }

  if (styleObject) {
    styleObject.style.properties = {
      ...styleObject.style.properties,
      'alpha-brd': '1',
      brd: mainColor,
      lnw: '1px',
    };

    styleObject.style.propertiesSource = {
      ...styleObject.style.propertiesSource,
      brd: 'value',
      lnw: 'value',
    };
    delete styleObject.id;
    styleObject.styleType = 'custom';
    line.style = styleObject;
  }
};

export const setVectorImageColor = (
  vectorImage: SerializedCompStructure,
  kitDefinition: KitDefinition,
  mainColor: string,
) => {
  let designData: VectorImageDesignData;
  if (typeof vectorImage.design === 'string') {
    designData = _.cloneDeep(
      kitDefinition.themeStyles[vectorImage.design],
    ) as VectorImageDesignData;
  } else {
    designData = vectorImage.design as VectorImageDesignData;
  }

  if (designData) {
    designData.shapeStyle = {
      ...designData.shapeStyle,
      opacity: 1,
    };

    designData.overrideColors = {
      ...designData.overrideColors,
      color1: mainColor,
    };
    designData.type = 'VectorImageDesignData';
    vectorImage.design = designData;
  }
};

const getComponentsOnImageBG_Recursive = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
  parentWithBackground: SerializedCompStructure | null = null,
  aggregator: ImageUrlToComponentsMap,
) => {
  const hasBackground = isImageBackground(structure);
  if (
    hasBackground ||
    (isTransparentBG(structure, kitDefinition) && parentWithBackground !== null)
  ) {
    const { url, transparentImage } = hasBackground
      ? getBackgroundUrl(structure)
      : getBackgroundUrl(parentWithBackground as SerializedCompStructure);

    aggregator[url] = {
      transparentImage,
      texts: [
        ...(aggregator[url]?.texts || []),
        ...getTextComponents(structure),
      ],
      buttons: [...(aggregator[url]?.buttons || []), ...getButtons(structure)],
      verticalLines: [
        ...(aggregator[url]?.verticalLines || []),
        ...getVerticalLines(structure),
      ],
      horizontalLines: [
        ...(aggregator[url]?.horizontalLines || []),
        ...getHorizontalLines(structure),
      ],
      vectorImage: [
        ...(aggregator[url]?.vectorImage || []),
        ...getVectorArts(structure),
      ],
    };
  }
  structure.components?.forEach((component) =>
    getComponentsOnImageBG_Recursive(
      component,
      kitDefinition,
      hasBackground ? structure : parentWithBackground,
      aggregator,
    ),
  );
  return aggregator;
};

const getTextComponents = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.WRichText',
  ) || [];

const getButtons = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.SiteButton',
  ) || [];

const getVerticalLines = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.VerticalLine',
  ) || [];

const getHorizontalLines = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.FiveGridLine',
  ) || [];

const getVectorArts = (structure: SerializedCompStructure) =>
  structure.components?.filter(
    (comp) => comp.componentType === 'wysiwyg.viewer.components.VectorImage',
  ) || [];

const getBackgroundUrl = (structure: SerializedCompStructure) => ({
  url: structure.design?.background?.mediaRef?.uri as string,
  transparentImage: structure.design?.background?.mediaRef?.opacity < 1,
});

const isTransparentBG = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
) => {
  if (structure.design) {
    const background = structure.design.background;
    const media = background?.mediaRef;
    const colorOpacity =
      background?.colorLayers?.[0].opacity ?? background?.colorOpacity ?? 1;

    const isMediaBGHidden = media?.opacity === 0;
    const isColorBGHidden = colorOpacity === 0;
    return media ? isMediaBGHidden : isColorBGHidden;
  }
  if (typeof structure.style === 'string') {
    return (
      kitDefinition.themeStyles[structure.style]?.style.properties[
        'alpha-bg'
      ] === '0'
    );
  }
};
