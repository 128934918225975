import { ChatControllerBiEventNumbers, ChatTopic } from './constants';
import { ChatMessage } from './types';
import {
  ParsedRelatedActionResponse,
  RawResponsePromptHub,
} from './sgChat/types';

export const reportChatHistoryBI = (
  newMessage: ChatMessage,
  topic: ChatTopic,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
) => {
  if (newMessage.content === null || newMessage.content === undefined) {
    return;
  }
  sendSiteCommonBi(ChatControllerBiEventNumbers.SG_CHAT_MESSAGE, {
    flow: 'SG',
    role: newMessage.role,
    text: newMessage.content,
    topic,
  });
};

export const reportErrorFromGPT = (
  errorMessage: string,
  errorCode: number,
  functionName: string,
  topic: ChatTopic,
  request: string,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
) => {
  sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
    flow: 'SG',
    errn: errorMessage,
    errc: errorCode,
    topic,
    functionName,
    p1: request,
  });
};

export const reportAIRequestDurationPromptHub = (
  data: RawResponsePromptHub,
  requestStartTime: number,
  promptId: string,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  userQuery?: string,
) => {
  const duration = Date.now() - requestStartTime;
  sendSiteCommonBi(ChatControllerBiEventNumbers.AI_TEXT_GENERATOR, {
    flow: 'SG',
    duration,
    query: userQuery,
    query_output:
      data.response.openAiChatCompletionResponse.choices[0].message.content,
    prompt: promptId,
  });
};

export const reportInvalidJson = (
  functionName: string,
  rawText: string,
  topic: ChatTopic,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
) => {
  sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
    flow: 'SG',
    errn: 'parseAiTextResponse',
    functionName,
    topic,
    p1: rawText,
  });
};

export const reportInvalidSuggestion = (
  errorName: string,
  functionName: string,
  topic: ChatTopic,
  response: ParsedRelatedActionResponse,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
) => {
  sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
    flow: 'SG',
    errn: errorName,
    functionName,
    topic,
    p1: JSON.stringify(response),
  });
};
