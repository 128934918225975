import {
  IndustryProfileData,
  languageCodeToLanguageName,
} from '@wix/editor-content-provider';
import { ChatMessage, Library } from '../types';
import {
  KitDefinition,
  KitExtendedDescription,
  KitExtendedDescriptionKey,
  KitInjectionOptions,
} from '@wix/editor-kits';
import { LayoutFamilyDefinition } from '../../types';
import {
  PromptNames,
  PromptHubAcceptedUrls,
  AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
  PROMPT_HUB_PROMPTS_ID,
  ChatTopic,
  UnrelatedAction,
  ChatMessageRole,
  InitialPromptActions,
  MISSING_MESSAGE,
  ALLOWED_HISTORY_MESSAGES_AMOUNT,
  MatchImagesPromptResponseProperties,
  emptyInitialUserMessage,
} from './constants';
import {
  OpenAiChatCompletionRequest,
  ParsedInitialResponse,
  PromptObject,
  RawParsedInitialResponse,
  RawResponsePromptHub,
  RequestBodyObjectPromptHub,
  RequestBodyObjectPromptHubNew,
  RequestBodyPromptHub,
} from './types';
import { HttpClient, type HttpResponse } from '@wix/http-client';
import { ReportError } from '@wix/editor-content-injector';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { ChatControllerBiEventNumbers } from '../constants';
import {
  reportAIRequestDurationPromptHub,
  reportErrorFromGPT,
  reportInvalidJson,
} from '../biUtilts';
import _ from 'lodash';
import { DEFAULT_LANGUAGE_CODE } from '../../consts';

export const constructLayoutLibraryFromLayoutFamilies = (
  layoutFamilies: LayoutFamilyDefinition[],
): Library => {
  return layoutFamilies.reduce((acc: Library, layout) => {
    acc[layout.title] = { description: layout.description };
    return acc;
  }, {});
};

export const constructMediaLibraryFromIndustryProfiles = (
  industryProfiles: IndustryProfileData[],
): Library => {
  const transformedLibraryObject: Record<string, { description: string }> = {};
  industryProfiles.forEach((profile: IndustryProfileData) => {
    transformedLibraryObject[profile.id] = {
      description: profile.description,
    };
  });
  return transformedLibraryObject;
};

export const constructThemeLibraryFromKitDefinitions = (
  kitDefinitions: KitDefinition[],
): Library => {
  return kitDefinitions.reduce((acc: Library, kit) => {
    if (!kit.gptDescription) return acc;
    acc[kit.title] = { description: kit.gptDescription };
    return acc;
  }, {});
};

export const getPromptIdByPromptName = (
  promptName: PromptNames,
  isLocalizationSpecOpen: boolean,
) => {
  switch (promptName) {
    case PromptNames.THEME_RELATED:
      return isLocalizationSpecOpen
        ? PROMPT_HUB_PROMPTS_ID.THEME_RELATED_LOCALIZATION
        : PROMPT_HUB_PROMPTS_ID.THEME_RELATED;
    case PromptNames.LAYOUT_RELATED:
      return isLocalizationSpecOpen
        ? PROMPT_HUB_PROMPTS_ID.LAYOUT_RELATED_LOCALIZATION
        : PROMPT_HUB_PROMPTS_ID.LAYOUT_RELATED;
    default:
      throw new Error(`no promptId for chosen promptName, ${promptName}`);
  }
};

export const getPromptByPromptName = async (
  promptName: PromptNames,
  client: HttpClient,
  isLocalizationSpecOpen: boolean,
): Promise<PromptObject> => {
  const { data }: HttpResponse<PromptObject> = await client.post(
    PromptHubAcceptedUrls.AI_GATEWAY_GET_PROMPT_URL,
    {
      appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
      promptId: getPromptIdByPromptName(promptName, isLocalizationSpecOpen),
    },
  );
  return data;
};

const getPromptParamsForInitRequest = (
  chatTopic: ChatTopic,
  library: Library,
  businessType: string,
  description: string,
  userInput: string,
  initialUserMessage: ChatMessage,
): Record<string, string> => {
  switch (chatTopic) {
    case ChatTopic.Theme:
      return {
        user_input: userInput,
      };
    case ChatTopic.Layout:
      return {
        layout_library: JSON.stringify(library),
        user_input: initialUserMessage.content,
      };
    case ChatTopic.Media:
      return {
        media_library: JSON.stringify(library),
      };
    case ChatTopic.MatchImages:
      return {
        industry: businessType,
        description,
        image_library: JSON.stringify(library),
      };
    default:
      return { none: 'none' }; // TODO service must have params, maybe fix it
  }
};

const getPromptIdForInitRequest = (
  chatTopic: ChatTopic.Theme | ChatTopic.Layout | ChatTopic.MatchImages,
) => {
  switch (chatTopic) {
    case ChatTopic.Theme:
      return PROMPT_HUB_PROMPTS_ID.THEME_PROMPT;
    case ChatTopic.Layout:
      return PROMPT_HUB_PROMPTS_ID.LAYOUT_PROMPT;
    case ChatTopic.MatchImages:
      return PROMPT_HUB_PROMPTS_ID.MATCH_IMAGES_PROMPT;
  }
};

export const getInitialRequestBodyPromptHub = (
  chatTopic: ChatTopic.Theme | ChatTopic.Layout | ChatTopic.MatchImages,
  initialUserMessage: ChatMessage,
  businessType: string = '',
  library: Library = {},
  userInput: string,
  description: string,
): RequestBodyPromptHub => {
  return {
    params: getPromptParamsForInitRequest(
      chatTopic,
      library,
      businessType,
      description,
      userInput,
      initialUserMessage,
    ),
    appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
    promptId: getPromptIdForInitRequest(chatTopic),
  };
};

export const getResponsePromptHub = async (
  requestBody:
    | RequestBodyPromptHub
    | RequestBodyObjectPromptHub
    | RequestBodyObjectPromptHubNew,
  chatTopic: ChatTopic,
  client: HttpClient,
  serverlessUrl: PromptHubAcceptedUrls,
  promptId: string,
  siteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  userQuery?: string,
): Promise<RawResponsePromptHub | null> => {
  try {
    const requestStartTime = Date.now();
    const { data }: HttpResponse<RawResponsePromptHub> = await client.post(
      serverlessUrl,
      requestBody,
    );
    reportAIRequestDurationPromptHub(
      data,
      requestStartTime,
      promptId,
      siteCommonBi,
      userQuery,
    );
    return data;
  } catch (e: any) {
    const errorResponse = e.response?.data;
    reportErrorFromGPT(
      errorResponse?.message || 'error response',
      errorResponse?.errorCode,
      'getResponseFromPromptHub',
      chatTopic,
      JSON.stringify(requestBody),
      siteCommonBi,
    );
    ErrorReporter.captureException(e, {
      tags: {
        siteGenerationWizard: true,
        siteGenerationAiErrorResponse: true,
      },
      extra: {
        chatTopic,
        requestBody,
      },
    });
    return null;
  }
};

const getRawTextResponsePromptHub = (rawResponse: RawResponsePromptHub) => {
  const isRawResponse = !!rawResponse.response.generatedTexts[0];
  if (!isRawResponse) {
    throw new Error('RawResponse is not valid');
  }
  return rawResponse.response.generatedTexts[0];
};

export const getParsedInitialResponse = (
  rawResponse: RawResponsePromptHub,
  reportDebug: ReportError,
  chatTopic: ChatTopic,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
): ParsedInitialResponse => {
  const rawTextResponse = getRawTextResponsePromptHub(rawResponse);

  try {
    const parsedResponse: RawParsedInitialResponse =
      JSON.parse(rawTextResponse);
    return {
      action: parsedResponse.Action,
      suggestion: parsedResponse.Suggestion,
    };
  } catch (e) {
    reportDebug('getParsedInitialResponse Error', {
      rawResponse: rawResponse.response.generatedTexts,
      rawTextResponse,
    });
    reportInvalidJson(
      'getParsedInitialResponse',
      rawTextResponse,
      chatTopic,
      sendSiteCommonBi,
    );
    ErrorReporter.captureException(e, {
      tags: {
        siteGenerationWizard: true,
        siteGenerationAiErrorResponse: true,
      },
      extra: {
        textBeforeNormalization: rawResponse.response.generatedTexts,
        textAfterNormalization: rawTextResponse,
      },
    });
    throw new Error('Invalid getParsedInitialResponse - JSON.parse threw');
  }
};

export const getInitialResponseFromPromptHub = async (
  chatTopic: ChatTopic.Theme | ChatTopic.Layout | ChatTopic.MatchImages,
  initialUserMessage: ChatMessage,
  businessType: string,
  library: Library,
  userInput: string,
  client: HttpClient,
  reportDebug: ReportError,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  isHiddenPromptSpecOpen: boolean,
) => {
  const initialRequestBody = getInitialRequestBodyPromptHub(
    chatTopic,
    initialUserMessage,
    businessType,
    library,
    userInput,
    '',
  );
  const data = await getResponsePromptHub(
    initialRequestBody,
    chatTopic,
    client,
    isHiddenPromptSpecOpen
      ? PromptHubAcceptedUrls.AI_GATEWAY_URL_NEW
      : PromptHubAcceptedUrls.AI_GATEWAY_URL,
    initialRequestBody.promptId,
    sendSiteCommonBi,
    initialUserMessage.content,
  );
  if (!data) {
    throw new Error('getInitialMiniChatAction - no data');
  }
  return getParsedInitialResponse(
    data,
    reportDebug,
    chatTopic,
    sendSiteCommonBi,
  );
};

export const getEnglishTranslatedUserInput = async (
  userInput: string,
  chatTopic: ChatTopic,
  client: HttpClient,
  isLocalizationPromptSpecOpen: boolean,
  isHiddenPromptSpecOpen: boolean,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
) => {
  if (isLocalizationPromptSpecOpen) {
    const requestBody: RequestBodyPromptHub = {
      appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
      promptId: PROMPT_HUB_PROMPTS_ID.USER_INPUT_TRANSLATOR,
      params: {
        USER_TEXT: userInput,
      },
    };
    const data = await getResponsePromptHub(
      requestBody,
      chatTopic,
      client,
      isHiddenPromptSpecOpen
        ? PromptHubAcceptedUrls.AI_GATEWAY_URL_NEW
        : PromptHubAcceptedUrls.AI_GATEWAY_URL,
      requestBody.promptId,
      sendSiteCommonBi,
    );
    if (!data) {
      throw new Error(
        'getTranslateUserInput - Failed to get response from AI Gateway',
      );
    }
    return data.response.generatedTexts[0];
  }
  return userInput;
};

export const getThemeRecommendationRequestBodyPromptHub = (
  businessType: string,
  library: { [key in KitExtendedDescriptionKey]: KitExtendedDescription },
  initialUserMessage: ChatMessage,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
): RequestBodyPromptHub => {
  try {
    const theme_library = JSON.stringify(library);
    return {
      params: {
        website_type: businessType,
        theme_library,
        user_input: initialUserMessage.content,
      },
      appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
      promptId: PROMPT_HUB_PROMPTS_ID.THEME_RECOMMENDATION_PROMPT,
    };
  } catch (error) {
    let errorMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
      flow: 'SG',
      topic: ChatTopic.ThemeRecommendation,
      errn: errorMessage,
      functionName: 'getThemeRecommendationRequestBody',
    });
    ErrorReporter.captureException(error, {
      tags: {
        siteGenerationWizard: true,
      },
      extra: {
        library,
      },
    });
    throw new Error('getThemeRecommendationRequestBody - error');
  }
};

const getParsedThemeRecommendationResponsePromptHub = (
  rawResponse: RawResponsePromptHub,
  reportDebug: ReportError,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
): string => {
  const rawTextResponse = getRawTextResponsePromptHub(rawResponse);

  try {
    const parsedResponse = JSON.parse(rawTextResponse);
    return parsedResponse.Suggestion;
  } catch (e) {
    reportDebug('Error parsing theme recommendation response prompt hub', {
      rawResponse: rawResponse.response.generatedTexts,
      rawTextResponse,
    });
    reportInvalidJson(
      'Error parsing theme recommendation response prompt hub',
      rawTextResponse,
      ChatTopic.ThemeRecommendation,
      sendSiteCommonBi,
    );
    ErrorReporter.captureException(e, {
      tags: {
        siteGenerationWizard: true,
        siteGenerationAiErrorResponse: true,
      },
      extra: {
        rawResponse,
        textBeforeNormalization: rawResponse.response.generatedTexts,
        textAfterNormalization: rawTextResponse,
      },
    });
    throw new Error('Error parsing theme recommendation prompt hub response');
  }
};

const getThemeRecommendationPromptHub = async (
  themeLibrary: {
    [key in KitExtendedDescriptionKey]: KitExtendedDescription;
  },
  businessType: string,
  initialUserMessage: ChatMessage,
  client: HttpClient,
  reportDebug: ReportError,
  isHiddenPromptSpecOpen: boolean,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
): Promise<KitExtendedDescriptionKey> => {
  const themeRecommendationRequestBody =
    getThemeRecommendationRequestBodyPromptHub(
      businessType,
      themeLibrary,
      initialUserMessage,
      sendSiteCommonBi,
    );
  const data = await getResponsePromptHub(
    themeRecommendationRequestBody,
    ChatTopic.ThemeRecommendation,
    client,
    isHiddenPromptSpecOpen
      ? PromptHubAcceptedUrls.AI_GATEWAY_URL_NEW
      : PromptHubAcceptedUrls.AI_GATEWAY_URL,
    themeRecommendationRequestBody.promptId,
    sendSiteCommonBi,
    initialUserMessage.content,
  );
  if (!data) {
    throw new Error('getThemeRecommendation - no data');
  }
  const parsedData = getParsedThemeRecommendationResponsePromptHub(
    data,
    reportDebug,
    sendSiteCommonBi,
  );
  return parsedData as KitExtendedDescriptionKey;
};

export const getThemeSuggestionPromptHub = async (
  userInput: string,
  businessType: string,
  kitExtendedDescriptions: {
    [key in KitExtendedDescriptionKey]: KitExtendedDescription;
  },
  initialUserMessage: ChatMessage,
  filterTopThemes: (
    userInput: string,
    kitExtendedDescriptions: {
      [key in KitExtendedDescriptionKey]: KitExtendedDescription;
    },
    sendSiteCommonBi: (
      evid: ChatControllerBiEventNumbers,
      fields: NonNullable<unknown>,
    ) => void,
  ) => Promise<any>,
  client: HttpClient,
  reportDebug: ReportError,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  isHiddenPromptSpecOpen: boolean,
): Promise<KitExtendedDescriptionKey> => {
  const topThemes: {
    [key in KitExtendedDescriptionKey]: KitExtendedDescription;
  } = await filterTopThemes(
    userInput,
    kitExtendedDescriptions,
    sendSiteCommonBi,
  );

  try {
    const promptAnswer: KitExtendedDescriptionKey =
      await getThemeRecommendationPromptHub(
        topThemes,
        businessType,
        initialUserMessage,
        client,
        reportDebug,
        isHiddenPromptSpecOpen,
        sendSiteCommonBi,
      );
    return promptAnswer;
  } catch (error) {
    console.log('getThemeSuggestionPromptHub error');
    console.error(error);
    let errorMessage;
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    sendSiteCommonBi(ChatControllerBiEventNumbers.SG_ERROR, {
      flow: 'SG',
      topic: ChatTopic.ThemeRecommendation,
      errn: errorMessage,
      functionName: 'getThemeSuggestionPromptHub',
    });
    ErrorReporter.captureException(error, {
      tags: {
        siteGenerationWizard: true,
        siteGenerationAiErrorResponse: true,
      },
      extra: {
        topThemes,
        businessType,
      },
    });
    return _.sample(
      Object.keys(kitExtendedDescriptions),
    ) as KitExtendedDescriptionKey;
  }
};

const getUnrealtedPromptIdByChatTopic = (
  chatTopic: ChatTopic.Layout | ChatTopic.Theme,
) => {
  switch (chatTopic) {
    case ChatTopic.Theme:
      return PROMPT_HUB_PROMPTS_ID.THEME_UNRELATED;
    case ChatTopic.Layout:
      return PROMPT_HUB_PROMPTS_ID.LAYOUT_UNRELATED;
  }
};

export const getUnrelatedPromptHubRequestBody = (
  chatTopic: ChatTopic.Layout | ChatTopic.Theme,
  userInput: string,
): RequestBodyPromptHub => {
  return {
    appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
    params: {
      user_request: userInput,
    },
    promptId: getUnrealtedPromptIdByChatTopic(chatTopic),
  };
};

export const getActionFromUnrelatedPromptHub = async (
  chatTopic: ChatTopic.Layout | ChatTopic.Theme,
  userInput: string,
  client: HttpClient,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  isHiddenPromptSpecOpen: boolean,
): Promise<UnrelatedAction> => {
  const body = getUnrelatedPromptHubRequestBody(chatTopic, userInput);
  const data = await getResponsePromptHub(
    body,
    chatTopic,
    client,
    isHiddenPromptSpecOpen
      ? PromptHubAcceptedUrls.AI_GATEWAY_URL_NEW
      : PromptHubAcceptedUrls.AI_GATEWAY_URL,
    body.promptId,
    sendSiteCommonBi,
  );
  if (!data) {
    throw new Error('getActionFromUnrelatedPromptHub - no data');
  }
  const rawTextResponse = getRawTextResponsePromptHub(data);
  try {
    const { Action } = JSON.parse(rawTextResponse);
    return Action;
  } catch (e) {
    reportInvalidJson(
      'getActionFromUnrelatedPromptHub',
      rawTextResponse,
      chatTopic,
      sendSiteCommonBi,
    );
    ErrorReporter.captureException(e, {
      tags: {
        siteGenerationWizard: true,
        siteGenerationAiErrorResponse: true,
      },
      extra: {
        userInput,
        textBeforeNormalization: data.response.generatedTexts,
        textAfterNormalization: rawTextResponse,
      },
    });
    throw new Error('getActionFromUnrelatedPromptHub - JSON.parse threw');
  }
};

export const getRelatedUserMessage = (
  chatTopic: ChatTopic.Layout | ChatTopic.Theme,
  input: string,
  actions: UnrelatedAction[] | InitialPromptActions[],
  contextDescription: string,
) => {
  switch (chatTopic) {
    case ChatTopic.Theme:
      return `{{
    "Action": "${actions[0] || MISSING_MESSAGE}",
    "Theme Description": "${contextDescription || MISSING_MESSAGE}",
    "User Input": "${input || MISSING_MESSAGE}"
  }}
  If the user asks to describe or comment on the current theme, you must ignore the "Action" and describe or comment on the theme using the provided "Theme Description". If the user asks your opinion of the theme, you must use the "Theme Description" to formulate your response. You must not use any other information. You must only give a description of the theme. You must not give your opinion of the theme. You must not say anything else.`;
    case ChatTopic.Layout:
      return `{{
    "Action": "${actions[0] || MISSING_MESSAGE}",
    "Layout Description": "${contextDescription || MISSING_MESSAGE}",
    "User Input": "${input || MISSING_MESSAGE}"
  }}
  If the user asks to describe or comment on their layout, you must do so using the provided "Layout Description". If the user asks your opinion of the layout, you must use the "Layout Description" to formulate your response. You must not use any other information. You must only give a description of the layout. You must not give your opinion of the layout. You must not say anything else.`;
  }
};

export const getNewExtendedKitForShuffleColors = (
  kitExtendedDescriptions: {
    [key in KitExtendedDescriptionKey]: KitExtendedDescription;
  },
  currentGenerated: {
    currentKit: KitDefinition;
    currentLayoutFamily: LayoutFamilyDefinition;
    currentKitInjectionOption: KitInjectionOptions;
  },
): KitExtendedDescription => {
  let availablePresets = Object.values(kitExtendedDescriptions).filter(
    (extendedKit) => extendedKit.kitName === currentGenerated.currentKit.title,
  );
  const currentGeneratedKitInjectionPreset =
    currentGenerated.currentKitInjectionOption?.colorationPreset ||
    currentGenerated.currentKitInjectionOption?.gradientPreset;
  if (currentGeneratedKitInjectionPreset) {
    availablePresets = availablePresets.filter(
      (extendedKit) =>
        extendedKit.preset !== currentGeneratedKitInjectionPreset,
    );
  }
  if (availablePresets.length === 0) {
    return _.sample(kitExtendedDescriptions) as KitExtendedDescription;
  }
  return _.sample(availablePresets) as KitExtendedDescription;
};

export const sliceMessages = (messages: ChatMessage[]) => {
  return messages.slice(ALLOWED_HISTORY_MESSAGES_AMOUNT * -1);
};

export const getRelatedActionRequestBodyPromptHubHidden = (
  relatedUserMessage: ChatMessage,
  messagesHistory: ChatMessage[],
  promptId: string,
  isLocalizationSpecOpen: boolean,
  languageCode: string,
): RequestBodyObjectPromptHubNew => {
  const reducedHistoryMessages = sliceMessages(messagesHistory);
  const messages = [...reducedHistoryMessages, relatedUserMessage].map(
    (chatMessage) => {
      let messageRoleUpperCase;
      switch (chatMessage.role) {
        case ChatMessageRole.User:
          messageRoleUpperCase = ChatMessageRole.USER;
          break;
        case ChatMessageRole.Assistant:
          messageRoleUpperCase = ChatMessageRole.ASSISTANT;
          break;
        case ChatMessageRole.System:
          messageRoleUpperCase = ChatMessageRole.SYSTEM;
          break;
      }
      return {
        content: chatMessage.content,
        role: messageRoleUpperCase || chatMessage.role,
      };
    },
  );

  const relatedPromptBody: RequestBodyObjectPromptHubNew = {
    appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
    promptIdToFetch: promptId,
    messagesHistory: messages,
    params: {},
  };

  if (isLocalizationSpecOpen) {
    relatedPromptBody.params.LOCALIZATION_VAR =
      languageCodeToLanguageName[languageCode] || DEFAULT_LANGUAGE_CODE;
  }

  return relatedPromptBody;
};

const doubleToSingleBrackets = (text: string) => {
  if (text.startsWith('{{') && text.endsWith('}}')) {
    return text.substring(1, text.length - 1);
  }
  return text;
};

const isParsableJson = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const replaceQuotesExceptFirstLast = (inputString: string): string => {
  if (!inputString) {
    return '';
  }
  const firstQuoteIndex = inputString.indexOf('"');
  const lastQuoteIndex = inputString.lastIndexOf('"');
  if (
    firstQuoteIndex === -1 ||
    lastQuoteIndex === -1 ||
    firstQuoteIndex === lastQuoteIndex
  ) {
    return inputString;
  }
  if (
    inputString[firstQuoteIndex - 1] === '[' &&
    inputString[lastQuoteIndex + 1] === ']' &&
    isParsableJson(inputString)
  ) {
    return inputString;
  }
  const firstPart = inputString.substring(0, firstQuoteIndex + 1);
  const middlePart = inputString
    .substring(firstQuoteIndex + 1, lastQuoteIndex)
    .replaceAll('"', '\\"');
  const lastPart = inputString.substring(lastQuoteIndex);
  return firstPart + middlePart + lastPart;
};

const loopOverJsonValues = (acc: string[], str: string): string[] => {
  const [jsonKey, ...restJsonValue] = str.split(':');
  const escapedValue = replaceQuotesExceptFirstLast(restJsonValue.join(':'));
  const separator = escapedValue ? ':' : '';
  const escapedKeyValue = `${jsonKey}${separator}${escapedValue}`;
  acc.push(escapedKeyValue);
  return acc;
};

export const normalizeStringMessage = (text: string) => {
  const t = doubleToSingleBrackets(text);
  if (isParsableJson(t)) {
    return t;
  }
  return t.split('\n').reduce(loopOverJsonValues, []).join('');
};

export const getRelatedPromptAnswerPromptHubHidden = async (
  chatTopic: ChatTopic,
  relatedUserMessage: ChatMessage,
  messagesHistory: ChatMessage[],
  client: HttpClient,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  isLocalizationSpecOpen: boolean,
  languageCode: string,
) => {
  const promptId = getPromptIdByPromptName(
    chatTopic === ChatTopic.Theme
      ? PromptNames.THEME_RELATED
      : PromptNames.LAYOUT_RELATED,
    isLocalizationSpecOpen,
  );
  const body = getRelatedActionRequestBodyPromptHubHidden(
    relatedUserMessage,
    messagesHistory,
    promptId,
    isLocalizationSpecOpen,
    languageCode,
  );
  const data = await getResponsePromptHub(
    body,
    chatTopic,
    client,
    PromptHubAcceptedUrls.AI_GATEWAY_OBJECT_URL_NEW,
    promptId,
    sendSiteCommonBi,
    relatedUserMessage.content,
  );
  if (!data) {
    throw new Error('getRelatedPromptAnswerPromptHub - no data');
  }
  const rawTextResponse = getRawTextResponsePromptHub(data);

  return normalizeStringMessage(rawTextResponse);
};

export const getRelatedPromptNameByChatTopic = (
  chatTopic: ChatTopic,
): PromptNames => {
  switch (chatTopic) {
    case ChatTopic.Theme:
      return PromptNames.THEME_RELATED;
    case ChatTopic.Layout:
      return PromptNames.LAYOUT_RELATED;
    default:
      throw new Error(`no related for chosen chatTopic ${chatTopic}`);
  }
};

export const getRelatedActionRequestBodyPromptHub = (
  relatedUserMessage: ChatMessage,
  messagesHistory: ChatMessage[],
  systemMessage: OpenAiChatCompletionRequest,
  isLocalizationSpecOpen: boolean,
  languageCode: string,
): RequestBodyObjectPromptHub => {
  const reducedHistoryMessages = sliceMessages(messagesHistory);
  const messages = [
    systemMessage.messages[0],
    ...reducedHistoryMessages,
    relatedUserMessage,
  ].map((chatMessage) => {
    let messageRoleUpperCase;
    switch (chatMessage.role) {
      case ChatMessageRole.User:
        messageRoleUpperCase = ChatMessageRole.USER;
        break;
      case ChatMessageRole.Assistant:
        messageRoleUpperCase = ChatMessageRole.ASSISTANT;
        break;
      case ChatMessageRole.System:
        messageRoleUpperCase = ChatMessageRole.SYSTEM;
        break;
    }
    return {
      content: chatMessage.content,
      role: messageRoleUpperCase || chatMessage.role,
    };
  });

  const relatedPromptBody: RequestBodyObjectPromptHub = {
    appDefId: AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS,
    params: {},
    prompt: {
      templatedParameterNames: [],
      openAiChatCompletionRequest: {
        ...systemMessage,
        messages,
      },
    },
  };

  if (isLocalizationSpecOpen) {
    relatedPromptBody.params = {
      LOCALIZATION_VAR:
        languageCodeToLanguageName[languageCode] || DEFAULT_LANGUAGE_CODE,
    };
  }

  return relatedPromptBody;
};

export const getRelatedPromptAnswerPromptHub = async (
  chatTopic: ChatTopic,
  relatedUserMessage: ChatMessage,
  messagesHistory: ChatMessage[],
  client: HttpClient,
  systemMessage: OpenAiChatCompletionRequest,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
  isLocalizationSpecOpen: boolean,
  languageCode: string,
): Promise<string> => {
  const body = getRelatedActionRequestBodyPromptHub(
    relatedUserMessage,
    messagesHistory,
    systemMessage,
    isLocalizationSpecOpen,
    languageCode,
  );
  const data = await getResponsePromptHub(
    body,
    chatTopic,
    client,
    PromptHubAcceptedUrls.AI_GATEWAY_OBJECT_URL,
    getPromptIdByPromptName(
      chatTopic === ChatTopic.Theme
        ? PromptNames.THEME_RELATED
        : PromptNames.LAYOUT_RELATED,
      isLocalizationSpecOpen,
    ),
    sendSiteCommonBi,
    relatedUserMessage.content,
  );
  if (!data) {
    throw new Error('getRelatedPromptAnswerPromptHub - no data');
  }
  const rawTextResponse = getRawTextResponsePromptHub(data);

  return normalizeStringMessage(rawTextResponse);
};

export const getParsedResponseForMatchingImagesPromptHub = (
  rawResponse: RawResponsePromptHub,
  reportDebug: ReportError,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
): string | null => {
  let rawTextResponse;

  try {
    rawTextResponse = getRawTextResponsePromptHub(rawResponse);
    const parsedResponse = JSON.parse(rawTextResponse);
    return parsedResponse[MatchImagesPromptResponseProperties.IMAGE_SET];
  } catch (e) {
    reportDebug('getParsedResponsePromptHub Error - JSON.parse threw', {
      rawResponse: rawResponse.response.generatedTexts,
      rawTextResponse,
    });
    reportInvalidJson(
      'getParsedResponsePromptHub',
      rawTextResponse,
      ChatTopic.MatchImages,
      sendSiteCommonBi,
    );
    ErrorReporter.captureException(e, {
      tags: {
        siteGenerationWizard: true,
        siteGenerationAiErrorResponse: true,
      },
      extra: {
        textBeforeNormalization: rawResponse.response.generatedTexts,
        textAfterNormalization: rawTextResponse,
      },
    });
    return null;
  }
};

export const getMatchingProfileIdPromptHub = async (
  businessType: string,
  description: string,
  imageLibrary: Library,
  reportDebug: ReportError,
  client: HttpClient,
  isHiddenPromptSpecOpen: boolean,
  sendSiteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void,
): Promise<string | null> => {
  const initialRequestBody = getInitialRequestBodyPromptHub(
    ChatTopic.MatchImages,
    emptyInitialUserMessage,
    businessType,
    imageLibrary,
    '',
    description,
  );
  const data = await getResponsePromptHub(
    initialRequestBody,
    ChatTopic.MatchImages,
    client,
    isHiddenPromptSpecOpen
      ? PromptHubAcceptedUrls.AI_GATEWAY_URL_NEW
      : PromptHubAcceptedUrls.AI_GATEWAY_URL,
    initialRequestBody.promptId,
    sendSiteCommonBi,
  );
  if (!data) {
    return null;
  }
  return getParsedResponseForMatchingImagesPromptHub(
    data,
    reportDebug,
    sendSiteCommonBi,
  );
};
