import _ from 'lodash';
import {
  getInstagramMobilePreviewStructureReplacer,
  getInstagramDesktopPreviewStructureReplacer,
} from './instagramPreviewStructureReplacer';
import { INSTAGRAM_FEED } from '@wix/app-definition-ids';
import { PresetContentCategory } from '@wix/editor-content-provider';
import {
  DEFAULT_INSTAGRAM_IMAGES,
  IMG_NUMBER_PLACEHOLDER,
  msidToImagePatternMap,
} from './consts';

import type { SectionPresetStructure } from '../../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

const getInstagramImagesByMetaSiteId = (
  msid: string,
  count: number,
): string[] => {
  const imagesPatternMap = msidToImagePatternMap[msid];
  if (!imagesPatternMap) {
    return DEFAULT_INSTAGRAM_IMAGES;
  }
  return _.times(count).map((i) =>
    imagesPatternMap.replace(IMG_NUMBER_PLACEHOLDER, `0${i + 1}`),
  );
};

const encodeImage = (imageUrl: string): string => {
  const origin = 'https://static.parastorage.com/';
  const path = imageUrl.replace(origin, '');
  return origin + encodeURIComponent(path);
};

const getInstagramDesktopWidgetReplacer = (
  msid: string,
  y: number,
): SerializedCompStructure => {
  const instagramPresetStructureReplacer =
    getInstagramDesktopPreviewStructureReplacer(y);
  const columns = instagramPresetStructureReplacer.components!;
  const imageUris = getInstagramImagesByMetaSiteId(msid, columns.length).map(
    encodeImage,
  );
  columns.forEach(({ design }, i) => {
    const mediaRef = design?.background?.mediaRef;
    if (!mediaRef) return;
    mediaRef.uri = imageUris[i];
  });
  return instagramPresetStructureReplacer;
};

const getInstagramMobileWidgetReplacer = (
  msid: string,
  y: number,
): SerializedCompStructure => {
  const instagramMobilePresetStructureReplacer =
    getInstagramMobilePreviewStructureReplacer(y);
  const images =
    instagramMobilePresetStructureReplacer.components![0].components!;
  const imageUris = getInstagramImagesByMetaSiteId(msid, images.length).map(
    encodeImage,
  );
  images.forEach(({ data }, i) => {
    if (!data?.uri) return;
    data.uri = imageUris[i];
  });
  return instagramMobilePresetStructureReplacer;
};

const replaceInstagramPreviewWidgetRecursive = (
  parentStructure: SerializedCompStructure,
  msid: string,
  isMobile: boolean,
): void => {
  const { components } = parentStructure;
  if (!components) return;
  const instagramWidgetChildIndex = components.findIndex(
    ({ data }) => data?.appDefinitionId === INSTAGRAM_FEED,
  );
  if (instagramWidgetChildIndex >= 0) {
    const instagramWidget = components[instagramWidgetChildIndex];
    const instagramHeight = instagramWidget.layout?.height;
    const instagramY = instagramWidget.layout?.y || 0;
    const instagramWidgetPlaceholder = isMobile
      ? getInstagramMobileWidgetReplacer(msid, instagramY)
      : getInstagramDesktopWidgetReplacer(msid, instagramY);
    const placeHolderLayout = instagramWidgetPlaceholder.layout;
    if (placeHolderLayout && instagramHeight) {
      const heightDiff = instagramHeight - placeHolderLayout.height;
      components.forEach(({ layout }) => {
        if (!layout || layout.y <= placeHolderLayout.y) {
          return;
        }
        layout.y = layout.y - heightDiff;
      });
      if (parentStructure.layout) {
        parentStructure.layout.height =
          parentStructure.layout.height - heightDiff;
      }
    }
    components[instagramWidgetChildIndex] = instagramWidgetPlaceholder;
    return;
  }
  components?.forEach((childComponent) => {
    replaceInstagramPreviewWidgetRecursive(childComponent, msid, isMobile);
  });
};

const replaceInstagramPreviewWidget = (
  rootStructure: SerializedCompStructure,
  msid: string,
  isMobile: boolean,
): SerializedCompStructure => {
  const clonedStructure = _.cloneDeep(rootStructure);
  replaceInstagramPreviewWidgetRecursive(clonedStructure, msid, isMobile);
  return clonedStructure;
};

/**
 * We have presets that doesn't render well in previewer.
 * This specific fix is replacing instagram widget with a strip, and setting the instagram images as columns background images.
 * @param sections - all section presets to render in previewer
 */
export const replaceInstagramPreview = (
  sections: SectionPresetStructure[],
): SectionPresetStructure[] => {
  return sections.map((section) => {
    if (section.contentCategory === PresetContentCategory.Instagram) {
      return {
        ...section,
        structure: replaceInstagramPreviewWidget(
          section.structure,
          section.sourceTemplateId,
          false,
        ),
        mobileStructure: replaceInstagramPreviewWidget(
          section.mobileStructure,
          section.sourceTemplateId,
          true,
        ),
      };
    }
    return section;
  });
};
