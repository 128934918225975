export enum ChatEvent {
  SGPropertiesChanged = 'SG_PROPERTIES_CHANGED',
  SitePagesChanged = 'SITE_PAGES_CHANGED',
  UserDataChanged = 'USER_DATA_CHANGED',
  PreviewStageStarted = 'PREVIEW_STAGE_STARTED',
  SiteGenerated = 'SITE_GENERATED',
  FirstSiteGenerated = 'FIRST_SITE_GENERATED',
  FirstSiteLoaded = 'FIRST_SITE_LOADED',
  SiteLoaded = 'SITE_LOADED',
  RevealPhaseChanged = 'REVEAL_PHASE_CHANGED',
  SiteHistoryRecalled = 'SITE_HISTORY_RECALLED',
}

export enum REVEAL_PHASES {
  INITIAL,
  SHOW_IMAGE_BOXES,
  SHOW_IMAGES,
  SHOW_TEXTS,
  HIDE_SITE,
  FULL_SITE,
}

export enum ChatTopic {
  Theme = 'theme',
  Coloration = 'coloration',
  ThemeRecommendation = 'theme_recommendation',
  Layout = 'layout',
  ImageCollection = 'imageCollection',
  SiteProfile = 'siteProfile',
  Structure = 'structure',
  Media = 'media',
  Business = 'business',
  ColorPalette = 'colorPalette',
  Description = 'description',
  MatchImages = 'matchImages',
}

export enum ChatMessageRole {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  ASSISTANT = 'ASSISTANT',
}

export enum ChatControllerBiEventNumbers {
  SG_CHAT_MESSAGE = 1520,
  SG_ERROR = 1523,
  AI_TEXT_GENERATOR = 1544,
}

export enum languageCodeOptions {}

export const languageCodeToLanguageName: { [languageCode: string]: string } = {
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  pl: 'Polish',
  ru: 'Russian',
  nl: 'Dutch',
  tr: 'Turkish',
  sv: 'Swedish',
  pt: 'Portuguese',
  no: 'Norwegian',
  da: 'Danish',
  hi: 'Hindi',
  zh: 'Chinese',
  cs: 'Czech',
  th: 'Thai',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
  id: 'Indonesian',
};

export enum Volume {
  BuiltIn = 'built-in',
  Recommended = 'recommended',
  Optional = 'optional',
}
