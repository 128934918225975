import { replaceStructureColoration } from '../utils';
import { KitColorationPreset, KitColorationType } from '../consts';

import type { KitSiteStructure, KitDefinition } from '../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

const replaceMixSingleColorationPairs = (
  compStructure: SerializedCompStructure,
  sectionIndex: number,
  kitDefinition: KitDefinition,
): SerializedCompStructure => {
  switch (sectionIndex % 3) {
    case 1:
      return replaceStructureColoration(
        compStructure,
        kitDefinition.coloration[KitColorationType.Secondary],
        kitDefinition,
      );
    case 2:
      return replaceStructureColoration(
        compStructure,
        kitDefinition.coloration[KitColorationType.Extra],
        kitDefinition,
      );
    default:
      return compStructure;
  }
};

const replaceMixDoubleColorationPairs = (
  compStructure: SerializedCompStructure,
  sectionIndex: number,
  kitDefinition: KitDefinition,
): SerializedCompStructure => {
  switch (sectionIndex % 6) {
    case 0:
    case 1:
      return replaceStructureColoration(
        compStructure,
        kitDefinition.coloration[KitColorationType.Extra],
        kitDefinition,
      );
    case 2:
    case 3:
      return replaceStructureColoration(
        compStructure,
        kitDefinition.coloration[KitColorationType.Secondary],
        kitDefinition,
      );
    default:
      return compStructure;
  }
};

export const applyColorationPreset = (
  siteStructure: KitSiteStructure,
  kitDefinition: KitDefinition,
  colorationPreset?: KitColorationPreset,
): KitSiteStructure => {
  if (!colorationPreset) return siteStructure;
  switch (colorationPreset) {
    case KitColorationPreset.Plain:
      return siteStructure;
    case KitColorationPreset.MixSingle:
      let mixSingleIndex = 0;
      return {
        ...siteStructure,
        sections: siteStructure.sections.map(
          ({ structure, mobileStructure, ...sectionData }) => {
            const coloredSection = {
              ...sectionData,
              structure: replaceMixSingleColorationPairs(
                structure,
                mixSingleIndex,
                kitDefinition,
              ),
              mobileStructure: replaceMixSingleColorationPairs(
                mobileStructure,
                mixSingleIndex,
                kitDefinition,
              ),
            };
            mixSingleIndex++;
            return coloredSection;
          },
        ),
        footer: siteStructure.footer && {
          ...siteStructure.footer,
          structure: replaceMixSingleColorationPairs(
            siteStructure.footer.structure,
            mixSingleIndex,
            kitDefinition,
          ),
          mobileStructure: replaceMixSingleColorationPairs(
            siteStructure.footer.mobileStructure,
            mixSingleIndex,
            kitDefinition,
          ),
        },
      };
    case KitColorationPreset.MixDouble:
      let mixDoubleIndex = 0;
      return {
        ...siteStructure,
        header: siteStructure.header && {
          ...siteStructure.header,
          structure: replaceStructureColoration(
            siteStructure.header.structure,
            kitDefinition.coloration[KitColorationType.Extra],
            kitDefinition,
          ),
          mobileStructure:
            siteStructure.header.mobileStructure &&
            replaceStructureColoration(
              siteStructure.header.mobileStructure,
              kitDefinition.coloration[KitColorationType.Extra],
              kitDefinition,
            ),
        },
        sections: siteStructure.sections.map(
          ({ structure, mobileStructure, ...sectionData }) => {
            const coloredSection = {
              ...sectionData,
              structure: replaceMixDoubleColorationPairs(
                structure,
                mixDoubleIndex,
                kitDefinition,
              ),
              mobileStructure: replaceMixDoubleColorationPairs(
                mobileStructure,
                mixDoubleIndex,
                kitDefinition,
              ),
            };
            mixDoubleIndex++;
            return coloredSection;
          },
        ),
        footer: siteStructure.footer && {
          ...siteStructure.footer,
          structure: replaceMixDoubleColorationPairs(
            siteStructure.footer.structure,
            mixDoubleIndex,
            kitDefinition,
          ),
          mobileStructure: replaceMixDoubleColorationPairs(
            siteStructure.footer.mobileStructure,
            mixDoubleIndex,
            kitDefinition,
          ),
        },
      };
    default:
      throw new Error(`Unknown coloration preset ${colorationPreset}`);
  }
};
