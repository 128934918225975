import { ColorName } from './types';

export const MEDIA_COLOR_EXTRACTOR_URL =
  '/_api/media-manager-color-extractor-service/';

export const MEDIA_COLOR_EXTRACTOR_ALGORITHM = 'HISTOGRAM';

const serverlessName = 'gpt-3';
export const gpt3Url = `https://editor.wix.com/_serverless/${serverlessName}/completion`;

// For colors from logo
export const GPT_PROMPT_SYSTEM_ID = 'system_message';
export const GPT_PROMPT_USER_ID = 'user_message';
export const GPT_PROMPT_VERSION_STRICT = 'paletteFromLogo_1.1';
export const GPT_PROMPT_VERSION_CREATIVE = 'paletteFromLogo_1.2';
export const GPT_PROMPT_VERSION_LATEST = 'paletteFromLogo_1.5';
// For colors from prompt/text
export const GPT_PARAMS_VERSION = 'paletteFromLogo_1.0';
export const GPT_PALETTE_FROM_PROMPT_VERSION = 'paletteFromPrompt_1.7';
export const GPT_PARAMS_PALETTE_FROM_PROMPT_VERSION = 'paletteFromLogo_1.0';

export const PROMPT_PARAM_ID = 'COLORS-229a3a06-0abd-11ee';
export const PROMPT_PARAM_ID_FROM_PROMPT = '';
export const MAX_COLORS_TO_EXTRACT = 10;
export const MIN_COLOR_PERCENT = 3;
export const MAX_COLOR_PERCENT = 90;
export const MAX_WHITE_COLOR_PERCENT = 50;
export const AI_GATEWAY_FROM_TEXT_PROMPT_ID =
  '610a2953-85c9-4424-ae9b-e9aa5b521432';

export const AI_GATEWAY_FROM_LOGO_PROMPT_ID =
  'b8b0cb42-70f7-4d42-8de3-2a81708f9dba';

export const AI_GATEWAY_COLORS_FROM_IMAGE_AND_PALETTE_PROMPT_ID =
  'dbabb09d-24b2-4545-b003-2050a0ded012';

export const AI_GATEWAY_APP_DEF_ID = 'a446f5e6-13e6-475b-a8f6-a5abfa8b946a';
export const AI_GATEWAY_URL =
  'https://editor.wix.com/_serverless/ai-gateway/prompt';

export const AI_GATEWAY_URL_HIDDEN =
  'https://editor.wix.com/_serverless/ai-gateway/ai-prompt';

export const gpt3UrlGetPrompts = `https://editor.wix.com/_serverless/${serverlessName}/get-prompts`;

export const MAIN_COLORS_NAMES: ColorName[] = [
  'color_36',
  'color_37',
  'color_41',
  'color_42',
  'color_43',
  'color_44',
];

export const MEDIA_FILES_PATH = 'https://static.wixstatic.com/';

export const EMPTY_COLOR_NAME_VALUE = '__empty__';
