import type { DocumentServicesObject } from '@wix/document-services-types';
import { type SiteGenerator } from '../siteGenerator';
import type {
  KitDefinition,
  KitExtendedDescription,
  KitExtendedDescriptionKey,
  KitInjectionOptions,
} from '@wix/editor-kits';
import {
  colorationLibrary,
  KitColorationPreset,
  KitGradientPreset,
} from '@wix/editor-kits';
import {
  ChatControllerBiEventNumbers,
  ChatEvent,
  ChatMessageRole,
  ChatTopic,
  REVEAL_PHASES,
} from './constants';
import SgThemeFilteringService from './sgThemeFilteringService/sgThemeFilteringService';
import { SgChat } from './sgChat/sgChat';
import {
  ChatControllerData,
  ChatControllerOptions,
  ChatEvents,
  ChatHistory,
  ChatMessage,
  CustomChatInitialMessages,
  DataForUpdateTheme,
  FedopsInteraction,
  GeneratedSiteHistoryRecord,
  Libraries,
  SiteGeneratedData,
  SitePage,
  SiteSection,
  TopicValue,
} from './types';
import { PubSub, SubTypeFn, UnsubTypeFn } from './pubsub';
import type {
  GeneratedHomepage,
  LayoutFamilyDefinition,
  SiteTheme,
} from '../types';
import type {
  IndustryProfileData,
  PageSectionContent,
  UserData,
} from '@wix/editor-content-provider';
import _ from 'lodash';
import {
  constructLayoutLibraryFromLayoutFamilies,
  constructMediaLibraryFromIndustryProfiles,
  constructThemeLibraryFromKitDefinitions,
  getRelatedUserMessage,
} from './sgChat/utils';
import { type Message, TextMessage } from './message/message';
import {
  createKitInjectionOptions,
  getGeneratedHomepage,
  getInitialUserMessageFormat,
  getPageSectionsContentFromSiteSection,
  getRegenerateKits,
  injectTextBoldIndications,
} from './utils';
import { reportChatHistoryBI, reportInvalidSuggestion } from './biUtilts';
import { ParsedRelatedActionResponse } from './sgChat/types';
import { InitialPromptActions, UnrelatedAction } from './sgChat/constants';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { getPresetIdsFromGeneratedHomepage } from '../utils/debugUtils';

export class ChatController {
  private readonly languageCode: string = 'en';
  private readonly siteCommonBi: (
    evid: ChatControllerBiEventNumbers,
    fields: NonNullable<unknown>,
  ) => void;
  private readonly interactionStarted: FedopsInteraction;
  private readonly interactionEnded: FedopsInteraction;
  private ds: DocumentServicesObject;
  private SgChat: SgChat;
  private siteGenerator: SiteGenerator;
  private readonly customChatInitialMessages: CustomChatInitialMessages;
  private previousKit: KitDefinition | undefined;
  private currentKit: KitDefinition;
  private usedKits: KitDefinition[] = [];
  private currentKitInjectionOptions: KitInjectionOptions;
  private previousKitInjectionOptions: KitInjectionOptions;
  private kitDefinitions: KitDefinition[];
  private kitExtendedDescriptions: {
    [key in KitExtendedDescriptionKey]: KitExtendedDescription;
  };

  private currentLayoutFamily: LayoutFamilyDefinition;
  private previousLayoutFamily: LayoutFamilyDefinition | undefined;
  private layoutFamilies: LayoutFamilyDefinition[];
  private industryProfile: IndustryProfileData | undefined;
  private industryProfiles: IndustryProfileData[];
  private libraries: Libraries;
  private chatHistory: ChatHistory = {
    [ChatTopic.Theme]: [],
    [ChatTopic.Layout]: [],
  };

  // eslint-disable-next-line new-cap
  private pubSub = PubSub<ChatEvents>();

  private generatedHomepage: GeneratedHomepage;
  private themedGeneratedHomepage: GeneratedHomepage;
  private customSiteTheme: SiteTheme;
  private revealPhase: REVEAL_PHASES;
  private firstSiteLoaded: boolean = false;

  chatTopic: ChatTopic;
  sitePages: SitePage[] = [];
  siteSections: SiteSection[];

  private siteHistory: GeneratedSiteHistoryRecord[] = [];
  private siteHistoryIndex: number = 0;

  //Experiments
  private readonly isExperimentColorsFromLogoOpen;
  private readonly isExperimentLocalizationOpen;
  private readonly isExperimentHiddenPromptOpen;
  private readonly isExperimentSiteHistoryOpen;

  constructor({
    siteGenerator,
    httpClient,
    reportDebug,
    isExperimentColorsFromLogoOpen,
    isExperimentLocalizationOpen,
    isExperimentHiddenPromptOpen,
    isExperimentSiteHistoryOpen,
    interactionStarted = _.noop,
    interactionEnded = _.noop,
    siteCommonBi,
    languageCode,
    customChatInitialMessages,
  }: ChatControllerOptions) {
    this.customChatInitialMessages = customChatInitialMessages;
    this.languageCode = languageCode || this.languageCode;
    this.siteCommonBi = siteCommonBi;
    this.interactionStarted = interactionStarted;
    this.interactionEnded = interactionEnded;
    const sgThemeFiltering = new SgThemeFilteringService({
      interactionStarted,
      interactionEnded,
    });
    this.SgChat = new SgChat(
      httpClient,
      reportDebug,
      sgThemeFiltering,
      isExperimentHiddenPromptOpen,
      isExperimentLocalizationOpen,
    );
    this.libraries = {
      [ChatTopic.Coloration]: colorationLibrary,
    };
    this.siteGenerator = siteGenerator;
    this.isExperimentColorsFromLogoOpen = isExperimentColorsFromLogoOpen;
    this.isExperimentLocalizationOpen = isExperimentLocalizationOpen;
    this.isExperimentHiddenPromptOpen = isExperimentHiddenPromptOpen;
    this.isExperimentSiteHistoryOpen = isExperimentSiteHistoryOpen;
  }

  readonly subscribe: SubTypeFn<ChatEvents> = this.pubSub.subscribe;
  readonly unsubscribe: UnsubTypeFn<ChatEvents> = this.pubSub.unsubscribe;

  setDs(ds: DocumentServicesObject) {
    this.ds = ds;
  }

  getSiteHistory() {
    return this.siteHistory.map((_, index) => index);
  }

  private recordSiteHistory() {
    const controllerState: GeneratedSiteHistoryRecord = _.cloneDeep({
      previousKit: this.previousKit,
      currentKit: this.currentKit,
      previousKitInjectionOptions: this.previousKitInjectionOptions,
      currentKitInjectionOptions: this.currentKitInjectionOptions,
      previousLayoutFamily: this.previousLayoutFamily,
      currentLayoutFamily: this.currentLayoutFamily,
      industryProfile: this.industryProfile,
      sitePages: this.sitePages,
      siteSections: this.siteSections,
      customSiteTheme: this.customSiteTheme,
      generatedHomepage: this.generatedHomepage,
      themedGeneratedHomepage: this.themedGeneratedHomepage,
      userData: this.getUserData(),
      chosenApps: this.getChosenApps(),
    });
    this.siteHistory.unshift(controllerState);
  }

  recallSiteHistory(index: number) {
    if (index === this.siteHistoryIndex) {
      return;
    }
    this.siteHistoryIndex = index;

    const {
      previousKit,
      currentKit,
      previousKitInjectionOptions,
      currentKitInjectionOptions,
      previousLayoutFamily,
      currentLayoutFamily,
      industryProfile,
      sitePages,
      siteSections,
      customSiteTheme,
      generatedHomepage,
      themedGeneratedHomepage,
      userData,
      chosenApps,
    } = _.cloneDeep(this.siteHistory[index]);

    this.previousKit = previousKit;
    this.currentKit = currentKit;
    this.previousKitInjectionOptions = previousKitInjectionOptions;
    this.currentKitInjectionOptions = currentKitInjectionOptions;
    this.previousLayoutFamily = previousLayoutFamily;
    this.currentLayoutFamily = currentLayoutFamily;
    this.industryProfile = industryProfile;
    this.sitePages = sitePages;
    this.siteSections = siteSections;
    this.customSiteTheme = customSiteTheme;
    this.generatedHomepage = generatedHomepage;
    this.themedGeneratedHomepage = themedGeneratedHomepage;
    this.siteGenerator.setChosenApps(chosenApps);
    this.setUserData(userData);

    this.pubSub.publish(
      ChatEvent.SiteHistoryRecalled,
      this.getSiteGeneratedData(),
    );
  }

  private setRevealPhase = (revealPhase: REVEAL_PHASES) => {
    this.revealPhase = revealPhase;
    this.pubSub.publish(ChatEvent.RevealPhaseChanged, this.revealPhase);
  };

  async startSiteReveal() {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1500);
    this.setRevealPhase(REVEAL_PHASES.INITIAL);
    await delay(1500);
    this.setRevealPhase(REVEAL_PHASES.SHOW_IMAGE_BOXES);
    await delay(1500);
    this.setRevealPhase(REVEAL_PHASES.SHOW_TEXTS);
    await delay(3000);
    this.setRevealPhase(REVEAL_PHASES.SHOW_IMAGES);
    await delay(2000);
    this.setRevealPhase(REVEAL_PHASES.FULL_SITE);
  }
  getSiteGeneratedData(): SiteGeneratedData {
    return {
      kitDefinition: this.currentKit,
      layoutFamily: this.currentLayoutFamily,
      industryProfile: this.industryProfile,
      generatedHomepage: this.generatedHomepage,
      themedGeneratedHomepage: this.themedGeneratedHomepage,
      customSiteTheme: this.customSiteTheme,
      currentKitInjectionOptions: this.currentKitInjectionOptions,
      isColorFromLogoNeeded: this.getSiteGenerationIsColorFromLogoIsNeeded(),
    };
  }

  getChosenApps() {
    return this.siteGenerator.getChosenApps();
  }

  setChosenApps(appDefIdsToInstall: string[]) {
    this.siteGenerator.setChosenApps(appDefIdsToInstall);
  }

  getUserData() {
    return this.siteGenerator.getUserData();
  }

  setUserData(updatedUserData: UserData) {
    const originalUserData = this.getUserData();
    const businessTypeChanged =
      originalUserData.industryId !== updatedUserData.industryId ||
      originalUserData.structureId !== updatedUserData.structureId;

    if (businessTypeChanged) {
      this.updateIndustryProfiles(
        updatedUserData.industryId,
        updatedUserData.structureId,
        updatedUserData.businessTypeName,
      );
    }

    const descriptionChanged =
      updatedUserData.description &&
      originalUserData.description != updatedUserData.description;

    if (businessTypeChanged || descriptionChanged) {
      this.matchIndustryProfile(
        updatedUserData.description || '',
        updatedUserData.businessTypeName,
      );
    }

    this.siteGenerator.setUserData(updatedUserData);
    this.pubSub.publish(
      ChatEvent.UserDataChanged,
      this.siteGenerator.getUserData(),
    );
  }

  updateSiteGenerationIsColorFromLogoIsNeeded(isColorFromLogoNeeded: boolean) {
    if (this.isExperimentColorsFromLogoOpen) {
      this.siteGenerator.setIsColorFromLogoNeeded(isColorFromLogoNeeded);
    } else {
      this.siteGenerator.setIsColorFromLogoNeeded(false);
    }
  }

  getSiteGenerationIsColorFromLogoIsNeeded() {
    return this.siteGenerator.getIsColorFromLogoNeeded();
  }

  async updateIndustryProfiles(
    industryId: string,
    structureId: string,
    businessTypeName: string,
  ) {
    await this.siteGenerator.initContent(
      industryId,
      structureId,
      businessTypeName,
    );
    const newAdditionalPages =
      await this.siteGenerator.getAdditionalPagesData();
    this.updateControllerData({
      sitePages: newAdditionalPages.map((pageData) => ({
        ...pageData,
        isSelected: false,
      })),
    });
    const industryProfiles = await this.siteGenerator.getIndustryProfiles();
    this.setIndustryProfiles(industryProfiles);
  }

  setChatTopic(chatTopic: ChatTopic) {
    this.chatTopic = chatTopic;
  }
  updateControllerData({
    currentKit,
    sitePages,
    siteSections,
    kitDefinitions,
    layoutFamily,
    layoutFamilies,
    industryProfile,
    industryProfiles,
    customSiteTheme,
    generatedHomepage,
    themedGeneratedHomepage,
    currentKitInjectionOptions,
  }: Partial<ChatControllerData>) {
    if (currentKit) {
      this.previousKit = this.currentKit;
      this.usedKits.push(currentKit);
      this.usedKits = _.uniqBy(this.usedKits, '_id');
      this.currentKit = currentKit;
    }
    if (currentKitInjectionOptions) {
      this.previousKitInjectionOptions = this.currentKitInjectionOptions;
      this.currentKitInjectionOptions = currentKitInjectionOptions;
    }
    if (kitDefinitions) {
      this.kitDefinitions = kitDefinitions;
      this.libraries = {
        ...this.libraries,
        [ChatTopic.Theme]:
          constructThemeLibraryFromKitDefinitions(kitDefinitions),
      };
      this.kitExtendedDescriptions = (kitDefinitions as KitDefinition[]).reduce(
        (acc, { kitExtendedDescription }) => ({
          ...acc,
          ...kitExtendedDescription,
        }),
        {} as { [key in KitExtendedDescriptionKey]: KitExtendedDescription },
      );
    }
    if (layoutFamily) {
      this.previousLayoutFamily = this.currentLayoutFamily;
      this.currentLayoutFamily = layoutFamily;
    }
    if (layoutFamilies) {
      this.layoutFamilies = layoutFamilies;
      this.libraries = {
        ...this.libraries,
        [ChatTopic.Layout]:
          constructLayoutLibraryFromLayoutFamilies(layoutFamilies),
      };
    }
    if (industryProfile) {
      this.industryProfile = industryProfile;
    }
    if (industryProfiles) {
      this.setIndustryProfiles(industryProfiles);
    }
    if (sitePages) {
      this.sitePages = sitePages;
      this.pubSub.publish(ChatEvent.SitePagesChanged, sitePages);
    }
    if (siteSections) {
      this.siteSections = siteSections;
    }
    if (currentKit || layoutFamily || industryProfile) {
      this.pubSub.publish(
        ChatEvent.SGPropertiesChanged,
        this.getSiteGeneratedData(),
      );
    }
    if (customSiteTheme) {
      this.customSiteTheme = customSiteTheme;
    }
    if (generatedHomepage) {
      this.generatedHomepage = generatedHomepage;
    }
    if (themedGeneratedHomepage) {
      this.themedGeneratedHomepage = themedGeneratedHomepage;
    }
    if (generatedHomepage || themedGeneratedHomepage) {
      this.pubSub.publish(ChatEvent.SiteGenerated, this.getSiteGeneratedData());
      if (this.isExperimentSiteHistoryOpen) {
        this.recordSiteHistory();
        this.siteHistoryIndex = 0;
      }
    }
  }

  firstSiteGenerated(generatedHomepage: SiteGeneratedData) {
    this.updateControllerData({
      currentKit: generatedHomepage.kitDefinition,
      layoutFamily: generatedHomepage.layoutFamily,
      industryProfile: generatedHomepage.industryProfile,
      generatedHomepage: generatedHomepage.generatedHomepage,
      themedGeneratedHomepage: generatedHomepage.themedGeneratedHomepage,
      customSiteTheme: generatedHomepage.customSiteTheme,
      currentKitInjectionOptions: generatedHomepage.currentKitInjectionOptions,
    });
    this.pubSub.publish(ChatEvent.FirstSiteGenerated, generatedHomepage);
  }

  onPreviewStageStarted() {
    this.pubSub.publish(ChatEvent.PreviewStageStarted, true);
  }

  onRegenerate() {
    this.setRevealPhase(REVEAL_PHASES.HIDE_SITE);
  }

  onSiteLoaded() {
    if (this.firstSiteLoaded) {
      this.setRevealPhase(REVEAL_PHASES.FULL_SITE);
      this.pubSub.publish(ChatEvent.SiteLoaded, true);
    } else {
      this.firstSiteLoaded = true;
      this.startSiteReveal();
      this.pubSub.publish(ChatEvent.FirstSiteLoaded, true);
    }
  }

  setIndustryProfiles = (industryProfiles: IndustryProfileData[]) => {
    this.industryProfiles = industryProfiles;
    this.libraries = {
      ...this.libraries,
      [ChatTopic.Media]: industryProfiles?.length
        ? constructMediaLibraryFromIndustryProfiles(industryProfiles)
        : null,
    };
  };

  private getCurrentAndPreviousFullKitName = () => {
    const previousKitName = this.previousKit?.title;
    const previousKitPreset =
      this.previousKitInjectionOptions?.colorationPreset ||
      this.previousKitInjectionOptions?.gradientPreset;
    const fullPreviousKitName =
      previousKitName && previousKitPreset
        ? `${previousKitName}_${previousKitPreset}`
        : '';

    const currentKitName = this.currentKit?.title;
    const currentKitPreset =
      this.currentKitInjectionOptions?.colorationPreset ||
      this.currentKitInjectionOptions?.gradientPreset;
    const fullCurrentKitName =
      currentKitName && currentKitPreset
        ? `${currentKitName}_${currentKitPreset}`
        : '';

    return [fullPreviousKitName, fullCurrentKitName];
  };

  private getTopicValue(
    chatTopic: ChatTopic.Layout | ChatTopic.Theme,
  ): TopicValue {
    switch (chatTopic) {
      case ChatTopic.Theme:
        const [fullPreviousKitName, fullCurrentKitName] =
          this.getCurrentAndPreviousFullKitName();
        return {
          previous: fullPreviousKitName,
          current: fullCurrentKitName,
        };
      case ChatTopic.Layout:
        return {
          previous: this.previousLayoutFamily?.title || '',
          current: this.currentLayoutFamily?.title || '',
        };
    }
  }

  private handleThemeColorsFromLogo = (translateText: string): Message => {
    const relatedUserMessage = {
      role: ChatMessageRole.User,
      content: getRelatedUserMessage(
        ChatTopic.Theme,
        'Describe',
        [UnrelatedAction.DESCRIBE],
        translateText,
      ),
    };
    this.setChatHistory(ChatTopic.Theme, relatedUserMessage);
    const newResponseMessageRecord = {
      role: ChatMessageRole.Assistant,
      content: translateText,
    };
    this.setChatHistory(ChatTopic.Theme, newResponseMessageRecord);
    return new TextMessage(injectTextBoldIndications(translateText));
  };

  async startChat(): Promise<Message> {
    const library = this.libraries?.[this.chatTopic];
    if (
      (this.chatTopic !== ChatTopic.Theme &&
        this.chatTopic !== ChatTopic.Layout) ||
      !library
    ) {
      throw new Error(
        'No message returned from AI after startChat, wrong chat Topic or no library not defined',
      );
    }
    const userInput = 'Describe';
    const currentSiteValues = this.getTopicValue(this.chatTopic);
    const initialUserMessage = {
      role: ChatMessageRole.User,
      content: getInitialUserMessageFormat(
        this.chatTopic,
        userInput,
        currentSiteValues,
      ),
    };
    const { businessTypeName } = this.getUserData();

    if (
      this.chatTopic === ChatTopic.Theme &&
      this.siteGenerator.getIsColorFromLogoNeeded()
    ) {
      return this.handleThemeColorsFromLogo(
        this.customChatInitialMessages.themeColorsFromLogoInitialText,
      );
    }

    const startMs = Date.now();
    this.interactionStarted('sg_send_message_ai_communication', {
      customParams: { chatTopic: this.chatTopic },
    });
    const response = await this.SgChat.sendMessage(
      userInput,
      initialUserMessage,
      this.chatTopic,
      this.getChatHistory(this.chatTopic),
      library,
      this.kitExtendedDescriptions,
      businessTypeName,
      currentSiteValues,
      (chatTopic: ChatTopic, message: ChatMessage) =>
        this.setChatHistory(chatTopic, message),
      {
        currentKit: this.currentKit,
        previousKit: this.previousKit,
        currentLayoutFamily: this.currentLayoutFamily,
        currentKitInjectionOption: this.currentKitInjectionOptions,
        isColorFromLogoNeeded: this.getSiteGenerationIsColorFromLogoIsNeeded(),
        previousKitInjectionOption: this.previousKitInjectionOptions,
      },
      this.languageCode,
      this.siteCommonBi,
      this.isExperimentHiddenPromptOpen,
      this.isExperimentLocalizationOpen,
    );
    const endMs = Date.now();
    this.interactionEnded('sg_send_message_ai_communication', {
      customParams: { chatTopic: this.chatTopic, duration: endMs - startMs },
    });
    console.log(
      `SgChat.sendMessage ${this.chatTopic} took ${endMs - startMs}ms`,
    );

    return new TextMessage(injectTextBoldIndications(response.content));
  }

  private handleNonTextualResponseForTKit = (
    response: ParsedRelatedActionResponse,
  ) => {
    if (response.action === InitialPromptActions.APPLY_THEME_VARIATION) {
      return this.handleNonTextualResponseForColoration(response);
    }
    if (response.action === InitialPromptActions.SHUFFLE_COLORS) {
      return this.handleNonTextualResponseForShuffleColors(response);
    }
    return this.handleNonTextualResponseForTheme(response);
  };

  private handleNonTextualResponseForShuffleColors = (
    response: ParsedRelatedActionResponse,
  ) => {
    const newExtendedKit = response.newExtendedKit;
    if (!newExtendedKit) {
      throw new Error('no newExtendedKit suggestion');
    }
    const kitInjectionOptions: KitInjectionOptions = {};
    if (this.currentKit.isGradientKit) {
      kitInjectionOptions.gradientPreset =
        newExtendedKit.preset as KitGradientPreset;
    } else {
      kitInjectionOptions.colorationPreset =
        newExtendedKit.preset as KitColorationPreset;
    }
    this.updateSiteTheme({
      kitDefinition: this.currentKit,
      kitInjectionOptions,
      isColorFromLogoNeeded: this.getSiteGenerationIsColorFromLogoIsNeeded(),
    });

    return new TextMessage(response.content);
  };

  private handleNonTextualResponseForTheme = (
    response: ParsedRelatedActionResponse,
  ) => {
    const selectedStyle = response.suggestion; //TODO: change the options to single option
    if (!selectedStyle) {
      throw new Error('no selectedStyle suggestion');
    }
    const { kit, kitInjectionOptions } = this.findSelectedKit(selectedStyle);
    if (kit) {
      this.updateSiteTheme({ kitDefinition: kit, kitInjectionOptions });
    }
    return new TextMessage(response.content);
  };

  private findSelectedKit(selectedStyle: string) {
    const selectedKitExtendedDescription: KitExtendedDescription =
      this.kitExtendedDescriptions[selectedStyle as KitExtendedDescriptionKey];
    if (!selectedKitExtendedDescription) {
      throw new Error(`selectedStyle: ${selectedStyle} not found in library`);
    }
    const kit = this.kitDefinitions.find(
      (kit) => kit.title === selectedKitExtendedDescription.kitName,
    );
    if (!kit) {
      throw new Error(
        `kit: ${selectedKitExtendedDescription.kitName} not found in kitDefinitions`,
      );
    }
    const kitInjectionOptions: KitInjectionOptions = {};
    if (kit.isGradientKit) {
      kitInjectionOptions.gradientPreset =
        selectedKitExtendedDescription.preset as KitGradientPreset;
    } else {
      kitInjectionOptions.colorationPreset =
        selectedKitExtendedDescription.preset as KitColorationPreset;
    }
    return { kit, kitInjectionOptions };
  }

  private handleNonTextualResponseForColoration = (
    response: ParsedRelatedActionResponse,
  ) => {
    this.changeColoration();
    return new TextMessage(response.content);
  };

  private handleNonTextualResponseForLayout = (
    response: ParsedRelatedActionResponse,
  ) => {
    if (response.action === InitialPromptActions.APPLY_LAYOUT_VARIATION) {
      return this.handleNonTextualResponseForLayoutVariation(response);
    }
    return this.handleNonTextualResponseForLayoutChange(response);
  };

  private handleNonTextualResponseForLayoutChange = (
    response: ParsedRelatedActionResponse,
  ) => {
    const selectedLayout = response.suggestion;
    const responseMessage = new TextMessage(response.content);

    if (!selectedLayout || selectedLayout?.length === 0) {
      this.changeLayoutVariation();
      reportInvalidSuggestion(
        'suggested layout is null or empty',
        'handleNonTextualResponseForLayoutChange',
        ChatTopic.Layout,
        response,
        this.siteCommonBi,
      );
      return responseMessage;
    }

    const layout = this.layoutFamilies.find(
      (layout) => layout.title === selectedLayout,
    );

    if (!layout) {
      this.changeLayoutVariation();
      reportInvalidSuggestion(
        'suggested layout doesnt exists in libaray',
        'handleNonTextualResponseForLayoutChange',
        ChatTopic.Layout,
        response,
        this.siteCommonBi,
      );
      return responseMessage;
    }
    this.updateLayoutFamily(layout);

    return responseMessage;
  };

  private handleNonTextualResponseForLayoutVariation = (
    response: ParsedRelatedActionResponse,
  ) => {
    this.changeLayoutVariation();
    return new TextMessage(response.content);
  };

  async sendMessageToAi(input: string): Promise<Message> {
    // TODO fix this implementation
    const handleNonTextualResponse = {
      [ChatTopic.Theme]: this.handleNonTextualResponseForTKit,
      [ChatTopic.Layout]: this.handleNonTextualResponseForLayout,
    } as Record<
      ChatTopic,
      (response: ParsedRelatedActionResponse) => TextMessage
    >;
    return this.sendMessageToAiByTopic(
      input,
      this.chatTopic,
      handleNonTextualResponse[this.chatTopic],
    );
  }

  async sendMessageToAiByTopic(
    userInput: string,
    chatTopic: ChatTopic,
    handleNonTextualResponse: (
      response: ParsedRelatedActionResponse,
    ) => Message,
  ): Promise<Message> {
    const library = this.libraries?.[this.chatTopic];
    if (
      (chatTopic !== ChatTopic.Theme && chatTopic !== ChatTopic.Layout) ||
      !library
    ) {
      throw new Error(
        'No message returned from AI after sendMessageToAiByTopic, wrong chat Topic or no library not defined',
      );
    }
    const topicValue = this.getTopicValue(chatTopic);
    const initialUserContent = getInitialUserMessageFormat(
      chatTopic,
      userInput,
      topicValue,
    );
    const initialUserMessage = {
      role: ChatMessageRole.User,
      content: initialUserContent,
    };
    const { businessTypeName } = this.getUserData();

    const startMs = Date.now();
    this.interactionStarted(`sg_send_message_ai_communication`, {
      customParams: {
        chatTopic: this.chatTopic,
      },
    });
    const response = await this.SgChat.sendMessage(
      userInput,
      initialUserMessage,
      chatTopic,
      this.getChatHistory(chatTopic),
      library,
      this.kitExtendedDescriptions,
      businessTypeName,
      topicValue,
      (chatTopic: ChatTopic, message: ChatMessage) =>
        this.setChatHistory(chatTopic, message),
      {
        currentKit: this.currentKit,
        previousKit: this.previousKit,
        currentLayoutFamily: this.currentLayoutFamily,
        currentKitInjectionOption: this.currentKitInjectionOptions,
        previousKitInjectionOption: this.previousKitInjectionOptions,
        isColorFromLogoNeeded: this.getSiteGenerationIsColorFromLogoIsNeeded(),
      },
      this.languageCode,
      this.siteCommonBi,
      this.isExperimentHiddenPromptOpen,
      this.isExperimentLocalizationOpen,
    );
    const endMs = Date.now();
    this.interactionEnded(`sg_send_message_ai_communication`, {
      customParams: {
        chatTopic: this.chatTopic,
        duration: endMs - startMs,
      },
    });
    console.log(`SgChat.sendMessage ${chatTopic} took ${endMs - startMs}ms`);

    if (
      response.action === InitialPromptActions.DONE ||
      response.action === InitialPromptActions.UNRELATED
    ) {
      return new TextMessage(injectTextBoldIndications(response.content));
    }
    return handleNonTextualResponse(response);
  }

  async matchIndustryProfile(description: string, businessTypeName: string) {
    if (
      !this.libraries[ChatTopic.Media] ||
      this.industryProfiles?.length === 0
    ) {
      this.industryProfile = undefined;
      return;
    }
    const startMs = Date.now();
    this.interactionStarted('sg_send_message_ai_communication', {
      customParams: {
        chatTopic: this.chatTopic,
      },
    });
    let profileId = await this.SgChat.getMatchingProfileId(
      businessTypeName,
      description,
      this.libraries[ChatTopic.Media],
      this.isExperimentHiddenPromptOpen,
      this.siteCommonBi,
    );
    const endMs = Date.now();
    this.interactionEnded('sg_send_message_ai_communication', {
      customParams: {
        chatTopic: this.chatTopic,
        duration: endMs - startMs,
      },
    });
    console.log(
      `SgChat.getMatchingProfileId ${this.chatTopic} took ${endMs - startMs}ms`,
    );

    if (!profileId) {
      const sampleProfile = _.sample(this.industryProfiles)?.id;
      profileId = sampleProfile || null;
    }
    const industry = this.industryProfiles.find(
      (profile) => profile.id === profileId,
    );

    if (industry) {
      this.industryProfile = industry;
      this.siteGenerator.setIndustryProfile(industry?.id);
    }
  }

  //TODO:REMOVE WHEN DELETE OLD UI
  getUserMessage(input: string) {
    throw new Error('Method not implemented.');
  }

  setChatHistory(chatTopic: ChatTopic, message: ChatMessage) {
    if (!message.content) {
      return;
    }
    reportChatHistoryBI(message, chatTopic, this.siteCommonBi);

    if (chatTopic !== ChatTopic.Theme && chatTopic !== ChatTopic.Layout) {
      return;
    }
    this.chatHistory[chatTopic].push(message);
  }

  getChatHistory(chatTopic: ChatTopic): ChatMessage[] {
    if (chatTopic !== ChatTopic.Theme && chatTopic !== ChatTopic.Layout) {
      return [];
    }
    return [...this.chatHistory[chatTopic]];
  }

  clearTopicChatHistory(chatTopic: ChatTopic) {
    if (chatTopic !== ChatTopic.Theme && chatTopic !== ChatTopic.Layout) {
      return;
    }
    this.chatHistory[chatTopic] = [];
  }

  async buildFirstSite() {
    const sgEngineSections = getPageSectionsContentFromSiteSection(
      this.siteSections,
    );
    ErrorReporter.breadcrumb(
      `buildFirstSite, sgEngineSections: ${sgEngineSections.map(
        ({ contentCategory }) => contentCategory,
      )}`,
    );
    const generatedHomepage: GeneratedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      this.currentLayoutFamily,
      sgEngineSections,
      undefined,
      this.currentKit,
    );
    const themedGeneratedHomepage: GeneratedHomepage =
      await this.siteGenerator.injectKit(
        generatedHomepage,
        this.currentLayoutFamily,
        this.currentKit,
        this.currentKitInjectionOptions,
      );
    const customSiteTheme = this.siteGenerator.getSiteTheme(
      this.ds,
      this.currentKit,
      this.currentLayoutFamily,
    );
    return {
      generatedHomepage,
      themedGeneratedHomepage,
      customSiteTheme,
    };
  }

  async updateSiteTheme({
    kitDefinition,
    kitInjectionOptions,
    isColorFromLogoNeeded = false,
  }: DataForUpdateTheme) {
    ErrorReporter.breadcrumb(
      `updateSiteTheme, generatedHomepage.sections length: ${this.generatedHomepage?.sections?.length}`,
    );
    this.updateSiteGenerationIsColorFromLogoIsNeeded(isColorFromLogoNeeded);
    this.onRegenerate();
    let newKitInjectionOptions = kitInjectionOptions;
    if (Object.keys(kitInjectionOptions).length === 0) {
      newKitInjectionOptions = createKitInjectionOptions(
        kitDefinition,
        kitInjectionOptions,
      );
    }
    const [customSiteTheme, themedGeneratedHomepage] = await Promise.all([
      this.siteGenerator.getSiteTheme(
        this.ds,
        kitDefinition,
        this.currentLayoutFamily,
      ),
      this.siteGenerator.injectKit(
        this.generatedHomepage,
        this.currentLayoutFamily,
        kitDefinition,
        newKitInjectionOptions,
      ),
    ]);
    this.updateControllerData({
      currentKit: kitDefinition,
      currentKitInjectionOptions: newKitInjectionOptions,
      customSiteTheme,
      themedGeneratedHomepage,
    });
  }

  async updateLayoutFamily(layoutFamily: LayoutFamilyDefinition) {
    this.onRegenerate();
    ErrorReporter.breadcrumb(`updateLayoutFamily: ${layoutFamily}`);
    const generatedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      layoutFamily,
      undefined,
      undefined,
      this.currentKit,
    );
    const themedGeneratedHomepage = await this.siteGenerator.injectKit(
      generatedHomepage,
      layoutFamily,
      this.currentKit,
      this.currentKitInjectionOptions,
    );
    this.updateControllerData({
      layoutFamily,
      generatedHomepage,
      themedGeneratedHomepage,
    });
  }

  async changeLayoutVariation() {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `changeLayoutVariation: ${this.currentLayoutFamily}`,
    );
    const generatedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      this.currentLayoutFamily,
      undefined,
      undefined,
      this.currentKit,
    );
    const themedGeneratedHomepage = await this.siteGenerator.injectKit(
      generatedHomepage,
      this.currentLayoutFamily,
      this.currentKit,
      this.currentKitInjectionOptions,
    );
    this.updateControllerData({
      generatedHomepage,
      themedGeneratedHomepage,
    });
  }

  async changeColoration() {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `changeColoration, sections length: ${this.generatedHomepage?.sections?.length}`,
    );
    const newKitInjectionOptions = createKitInjectionOptions(
      this.currentKit,
      this.currentKitInjectionOptions,
    );
    const themedGeneratedHomepage = await this.siteGenerator.injectKit(
      this.generatedHomepage,
      this.currentLayoutFamily,
      this.currentKit,
      newKitInjectionOptions,
    );
    this.updateControllerData({
      themedGeneratedHomepage,
      currentKitInjectionOptions: newKitInjectionOptions,
    });
  }

  private async buildHomepageWithRandomColoration(
    kitDefinition: KitDefinition,
    kitInjectionOptions: KitInjectionOptions,
    layoutFamily: LayoutFamilyDefinition,
    sgEngineSections?: PageSectionContent[],
  ) {
    ErrorReporter.breadcrumb(
      `buildHomepageWithRandomColoration, sgEngineSections.length: ${sgEngineSections?.length}`,
    );
    const generatedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      layoutFamily,
      sgEngineSections,
      undefined,
      kitDefinition,
    );
    const themedGeneratedHomepage = await this.siteGenerator.injectKit(
      generatedHomepage,
      layoutFamily,
      kitDefinition,
      kitInjectionOptions,
    );
    const customSiteTheme = this.siteGenerator.getSiteTheme(
      this.ds,
      kitDefinition,
      layoutFamily,
    );
    this.updateControllerData({
      layoutFamily,
      currentKit: kitDefinition,
      currentKitInjectionOptions: kitInjectionOptions,
      generatedHomepage,
      themedGeneratedHomepage,
      customSiteTheme,
    });
  }

  async doMinorChanges() {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `doMinorChanges, sections length: ${this.generatedHomepage?.sections?.length}`,
    );
    const newKitInjectionOptions = createKitInjectionOptions(
      this.currentKit,
      this.currentKitInjectionOptions,
    );
    this.buildHomepageWithRandomColoration(
      this.currentKit,
      newKitInjectionOptions,
      this.currentLayoutFamily,
    );
  }

  async regenerateSite() {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `regenerateSite, sections length: ${this.generatedHomepage?.sections?.length}`,
    );
    const layoutOptionsWithoutCurrent = this.layoutFamilies.filter(
      (layout) => layout.title !== this.currentLayoutFamily.title,
    );
    const randomLayoutFamily = _.sample(layoutOptionsWithoutCurrent);
    const randomKitDefinition = _.sample(
      getRegenerateKits(this.kitDefinitions, this.currentKit, this.usedKits),
    );
    if (!randomKitDefinition || !randomLayoutFamily) {
      throw new Error('randomKitDefinition or randomLayoutFamily is undefined');
    }
    const sgEngineSections = getPageSectionsContentFromSiteSection(
      this.siteSections,
    );
    const kitInjectionOptions = createKitInjectionOptions(
      randomKitDefinition,
      this.currentKitInjectionOptions,
    );
    this.updateControllerData({
      currentKit: randomKitDefinition,
      layoutFamily: randomLayoutFamily,
    });
    await this.buildHomepageWithRandomColoration(
      randomKitDefinition,
      kitInjectionOptions,
      randomLayoutFamily,
      sgEngineSections,
    );
  }

  async updateSiteHomepage(updatedSiteSections: SiteSection[]) {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `updateSiteHomepage, updatedSiteSections.length: ${updatedSiteSections.length}`,
    );
    const sgEngineSections =
      getPageSectionsContentFromSiteSection(updatedSiteSections);
    const presetIds = getPresetIdsFromGeneratedHomepage(
      this.themedGeneratedHomepage,
    );

    const generatedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      this.currentLayoutFamily,
      sgEngineSections,
      presetIds,
      this.currentKit,
    );
    const themedGeneratedHomepage = await this.siteGenerator.injectKit(
      generatedHomepage,
      this.currentLayoutFamily,
      this.currentKit,
      this.currentKitInjectionOptions,
    );
    this.updateControllerData({
      siteSections: updatedSiteSections,
      generatedHomepage,
      themedGeneratedHomepage,
    });
  }

  async applyTextChanges(lockHeaderFooter: boolean) {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `ApplyTextChanges lockheaderFooter ${lockHeaderFooter}`,
    );
    const lockedPresetsIds = getPresetIdsFromGeneratedHomepage(
      this.themedGeneratedHomepage,
    );
    if (!lockHeaderFooter) {
      delete lockedPresetsIds.header;
      delete lockedPresetsIds.footer;
    }
    const generatedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      this.currentLayoutFamily,
      getPageSectionsContentFromSiteSection(this.siteSections),
      lockedPresetsIds,
      this.currentKit,
    );
    const themedGeneratedHomepage: GeneratedHomepage =
      await this.siteGenerator.injectKit(
        generatedHomepage,
        this.currentLayoutFamily,
        this.currentKit,
        this.currentKitInjectionOptions,
      );
    this.updateControllerData({
      generatedHomepage,
      themedGeneratedHomepage,
    });
  }

  async regenerateOnDescriptionChange() {
    this.onRegenerate();
    ErrorReporter.breadcrumb(
      `regenerateOnDescriptionChange, sections length: ${this.siteSections.length}`,
    );
    const presetIds = getPresetIdsFromGeneratedHomepage(
      this.themedGeneratedHomepage,
    );
    const generatedHomepage = await getGeneratedHomepage(
      this.ds,
      this.siteGenerator,
      this.currentLayoutFamily,
      this.siteSections,
      presetIds,
      this.currentKit,
    );
    const themedGeneratedHomepage = await this.siteGenerator.injectKit(
      generatedHomepage,
      this.currentLayoutFamily,
      this.currentKit,
      this.currentKitInjectionOptions,
    );
    const kits = await this.siteGenerator.getKits();
    this.updateControllerData({
      generatedHomepage,
      themedGeneratedHomepage,
      customSiteTheme: this.siteGenerator.getSiteTheme(
        this.ds,
        this.currentKit,
        this.currentLayoutFamily,
      ),
      kitDefinitions: kits,
    });
  }
}
