import _ from 'lodash';
import { setFormsStyles } from './plugins/formsKit';
import { setSectionIcons } from './plugins/kitIcons';
import { setSectionsStickers } from './plugins/kitStickers';
import { applyColorationPreset } from './plugins/coloration';
import { applyGradientPreset } from './plugins/gradients';
import { applyShapeDividers } from './plugins/shapeDividers';
import { setTransparentVideos } from './plugins/transparentVideoKit';
import { adjustColorsForImageBG } from './plugins/textColorOnImageBG';

import type {
  KitDefinition,
  KitSiteStructure,
  KitPresetStructures,
  KitInjectionOptions,
} from './types';
import { applyCapsFonts } from './plugins/capsFonts';
import { setSocialLinksColorsForSection } from './plugins/socialLinks';

const setPresetKit = (
  presetStructure: KitPresetStructures,
  kitDefinition: KitDefinition,
): void => {
  setFormsStyles(presetStructure, kitDefinition.forms);
  setSectionIcons(presetStructure, kitDefinition.icons);
  setTransparentVideos(presetStructure, kitDefinition.transparentVideo);
};

const getColoredSiteStructure = (
  siteStructure: KitSiteStructure,
  kitDefinition: KitDefinition,
  kitInjectionOptions: KitInjectionOptions,
): KitSiteStructure => {
  if (kitDefinition.isGradientKit && !kitInjectionOptions.disableGradient) {
    return applyGradientPreset(
      siteStructure,
      kitDefinition,
      kitInjectionOptions.gradientPreset,
    );
  }
  const coloredSiteStructure = applyColorationPreset(
    siteStructure,
    kitDefinition,
    kitInjectionOptions.colorationPreset,
  );
  if (!kitInjectionOptions.disableShapeDividers) {
    applyShapeDividers(coloredSiteStructure, kitDefinition.shapeDividers);
  }
  applyCapsFonts(coloredSiteStructure, kitDefinition.capsFonts);
  return coloredSiteStructure;
};

const setSocialLinksColors = (
  coloredSiteStructure: KitSiteStructure,
  kitDefinition: KitDefinition,
): KitSiteStructure => {
  [
    coloredSiteStructure.header,
    ...coloredSiteStructure.sections,
    coloredSiteStructure.footer,
  ].forEach((section) => {
    if (section) {
      setSocialLinksColorsForSection(section.structure, kitDefinition);
      setSocialLinksColorsForSection(section.mobileStructure, kitDefinition);
    }
  });
  return coloredSiteStructure;
};

export const injectKitIntoStructure = async (
  kitDefinition: KitDefinition,
  siteStructure: KitSiteStructure,
  staticMediaUrl: string,
  kitInjectionOptions: KitInjectionOptions,
): Promise<KitSiteStructure> => {
  const clonedSiteStructure = _.cloneDeep(siteStructure);

  [
    clonedSiteStructure.header,
    ...clonedSiteStructure.sections,
    clonedSiteStructure.footer,
  ]
    .filter((x) => x?.structure) // TODO: fix this filter (sometimes don't have header & footer structures)
    .forEach((presetStructure) => {
      setPresetKit(presetStructure, kitDefinition);
    });
  setSectionsStickers(clonedSiteStructure.sections, kitDefinition.stickers);

  const coloredSiteStructure = getColoredSiteStructure(
    clonedSiteStructure,
    kitDefinition,
    kitInjectionOptions,
  );

  const structureWithSocialLinks = setSocialLinksColors(
    coloredSiteStructure,
    kitDefinition,
  );

  const contrastAdjustedSiteStructure = await adjustColorsForImageBG(
    structureWithSocialLinks,
    kitDefinition,
    staticMediaUrl,
  );

  return contrastAdjustedSiteStructure;
};
