import { DocumentServicesObject } from '@wix/document-services-types';
import { ChatTopic } from './constants';
import { Volume } from '@wix/adi-content-api';
import { SiteSection, type TopicValue } from './types';
import {
  getDefaultKitInjectionOptions,
  KitColorationPreset,
  KitDefinition,
  KitGradientPreset,
  KitInjectionOptions,
} from '@wix/editor-kits';
import _ from 'lodash';
import { type SiteGenerator } from '../siteGenerator';
import {
  GeneratedHomepage,
  LayoutFamilyDefinition,
  LockedPresetIds,
} from '../types';
import {
  PageSectionContent,
  type PageData,
} from '@wix/editor-content-provider';

export const getInitialUserMessageFormat = (
  chatTopic: ChatTopic.Layout | ChatTopic.Theme,
  userInput: string,
  topicValues: TopicValue,
): string => {
  switch (chatTopic) {
    case ChatTopic.Theme:
      return `{{
    "Previous Theme": "${topicValues.previous}",
    "Current Theme": "${topicValues.current}",
    "User Request": "${userInput}"
  }}`;
    case ChatTopic.Layout:
      return `{{
    "Previous Layout Style": "${topicValues.previous}",
    "Current Layout Style": "${topicValues.current}",
    "User Request": "${userInput}"
  }}`;
  }
};

export const injectTextBoldIndications = (text: string): string => {
  const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [];
  const lastQuestionSentence = sentences
    .reverse()
    .find((sentence) => sentence.trim().endsWith('?'));

  if (lastQuestionSentence) {
    const trimmedSentence = lastQuestionSentence.trim();
    const sentenceStartIndex = text.lastIndexOf(trimmedSentence);
    const sentenceBegining = text.substring(0, sentenceStartIndex);
    const restOfSentence = text.substring(
      sentenceStartIndex + trimmedSentence.length,
    );
    return `${sentenceBegining}<0>${trimmedSentence}</0>${restOfSentence}`;
  }

  return text;
};

export const removeBoldIndications = (text: string): string => {
  return text.replaceAll('<0>', '').replaceAll('</0>', '');
};

export function createKitInjectionOptions(
  kitDefinition: KitDefinition,
  currentKitInjectionOptions?: KitInjectionOptions,
): KitInjectionOptions {
  const kitInjectionOptions = getDefaultKitInjectionOptions(kitDefinition);

  if (kitDefinition.isGradientKit) {
    if (currentKitInjectionOptions?.gradientPreset) {
      const nonUsedGradientPresets = Object.values(KitGradientPreset).filter(
        (option) => option != currentKitInjectionOptions.gradientPreset,
      );
      kitInjectionOptions.gradientPreset = _.sample(nonUsedGradientPresets);
    }
  } else {
    const colorationOptions = Object.values(KitColorationPreset).filter(
      (option) => option != currentKitInjectionOptions?.colorationPreset,
    );
    kitInjectionOptions.colorationPreset = _.sample(colorationOptions);
  }

  return kitInjectionOptions;
}

export const getGeneratedHomepage = async (
  ds: DocumentServicesObject,
  siteGenerator: SiteGenerator,
  layoutFamily: LayoutFamilyDefinition,
  updatedHomepageSections?: PageSectionContent[],
  lockedPresetsIds?: LockedPresetIds,
  kitDefinition?: KitDefinition,
): Promise<GeneratedHomepage> => {
  const { name, sections } = await siteGenerator.getHomepageData(ds);
  const homepageData: PageData = {
    name,
    sections: updatedHomepageSections?.length
      ? updatedHomepageSections
      : sections.filter(
          (section: PageSectionContent) => section.volume !== Volume.Optional,
        ),
  };

  return siteGenerator.buildFullHomepageStructure(
    ds,
    layoutFamily.title,
    homepageData,
    { injectCaasText: true, injectAiText: true },
    lockedPresetsIds,
    kitDefinition,
  );
};

export const getPageSectionsContentFromSiteSection = (
  sections: SiteSection[],
): PageSectionContent[] => {
  return (sections || [])
    .filter((section: SiteSection) => section.isSelected)
    .map(
      (section: SiteSection): PageSectionContent => ({
        name: section.name,
        volume: section.volume,
        ceType: section.ceType,
        contentCategory: section.contentCategory,
        sourceTemplateId: section.sourceTemplateId,
      }),
    );
};

export const getPreferredPresets = <
  T extends KitDefinition | LayoutFamilyDefinition,
>(
  presets: T[],
): T[] => {
  const builtInPresets = presets.filter(
    ({ volume }) => volume === Volume.BuiltIn,
  );

  if (builtInPresets.length > 0) {
    return builtInPresets;
  }

  const recommendedPresets = presets.filter(
    ({ volume }) => volume === Volume.Recommended,
  );

  if (recommendedPresets.length > 0) {
    return recommendedPresets;
  }

  return presets;
};

export function getRegenerateKits(
  allKits: KitDefinition[],
  currentKit: KitDefinition,
  usedKits: KitDefinition[],
) {
  const usedKitTitles = (usedKits ?? []).map((kit) => kit.title);

  const optionalKitsWithoutUsed = allKits.filter(
    (kit) => !usedKitTitles.includes(kit.title),
  );

  if (optionalKitsWithoutUsed.length > 0) {
    return getPreferredPresets(optionalKitsWithoutUsed);
  }

  const kitsWithoutCurrent = allKits.filter(
    (kit) => kit.title != currentKit.title,
  );

  return getPreferredPresets(kitsWithoutCurrent);
}
