import { ErrorReporter } from '@wix/editor-error-reporter';
import type {
  CompRef,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { type AnimationsKit } from '@wix/animations-kit';
import {
  PreviewerSupportedTriggers,
  type PreviewerEffectsMapping,
  type PreviewerViewportAnimationData,
  type ViewerDoneParams,
} from './types';

export const getPreviewerEffectsMapping = (
  miniDs: DocumentServicesObject,
  rootPointer: CompRef,
): PreviewerEffectsMapping => {
  const children = miniDs.components.getChildren(rootPointer, true);
  return children.reduce((acc, childRef) => {
    const effectRefs = miniDs.components.effects.list(childRef);
    if (effectRefs.length === 0) return acc;
    return {
      ...acc,
      [childRef.id]: effectRefs
        .map((effectRef) => ({
          effectId: effectRef.id,
          animationOptions: miniDs.components.effects.get(childRef, effectRef)
            ?.value,
        }))
        .filter((effectData) => effectData.animationOptions?.namedEffect),
    };
  }, {});
};

export const extractSelfViewportAnimationComponents = (
  triggersAndReactionsConfig: ViewerDoneParams['options']['triggersAndReactions'],
  effectsMapping: PreviewerEffectsMapping,
): { [compId: string]: PreviewerViewportAnimationData } => {
  const compIdToReactionData = Object.entries(
    triggersAndReactionsConfig.compsToTriggers,
  ).reduce(
    (acc, [triggerId, triggerTypeMap]) => {
      const effectId =
        triggerTypeMap[PreviewerSupportedTriggers.ViewportEnter]?.[
          triggerId
        ]?.[0]?.reactions?.[0]?.reactionData?.effect;
      if (effectId) {
        acc[triggerId] = effectId;
      }
      return acc;
    },
    {} as Record<string, string>,
  );
  return Object.entries(compIdToReactionData).reduce(
    (acc, [compId, effectId]) => {
      if (!effectId || !effectsMapping[compId]) return acc;
      const effect = effectsMapping[compId].find(
        (effectData) => effectData.effectId === effectId,
      );
      if (!effect?.animationOptions?.namedEffect) return acc;
      const duration =
        typeof effect.animationOptions.duration === 'number'
          ? effect.animationOptions.duration
          : effect.animationOptions.duration.value;
      return {
        ...acc,
        [compId]: {
          allowReplay: effect.animationOptions.allowReplay || '',
          duration: (duration || 0) / 1000,
          delay: (effect.animationOptions.delay || 0) / 1000,
          name: effect.animationOptions.namedEffect.type,
          effectId,
        },
      };
    },
    {},
  );
};

export const runPreviewAnimation = (
  animationsKit: AnimationsKit,
  shadowRoot: ShadowRoot,
  triggersAndReactionsConfig: ViewerDoneParams['options']['triggersAndReactions'],
  effectsMapping: PreviewerEffectsMapping,
) => {
  const selfViewportAnimationComponents =
    extractSelfViewportAnimationComponents(
      triggersAndReactionsConfig,
      effectsMapping,
    );

  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          const element = entry.target as HTMLElement;
          const { delay, duration, name, allowReplay } =
            selfViewportAnimationComponents[element.id];
          try {
            animationsKit.animate(name, element, duration, delay, {
              allowReplay,
            });
          } catch (e) {
            console.error(e);
            ErrorReporter.captureException(e, {
              tags: { previewerRunAnimationKit: true },
              extra: {
                name,
                delay,
                duration,
                allowReplay,
              },
            });
            element.style.opacity = '1';
          }
          observer.unobserve(element);
        }
      });
    },
    { rootMargin: '50% 0px' },
  );
  Object.keys(selfViewportAnimationComponents).forEach((compId) => {
    const el = shadowRoot.getElementById(compId);
    if (el) {
      el.style.opacity = '0';
      observer.observe(el);
    }
  });
};

export function getDefaultBaseWidth(): number {
  return Math.max(
    window.document.body.getBoundingClientRect().width - 100,
    980,
  );
}
