import type { LayoutFamilyDefinition } from '../types';
import type { KitInjectionOptions } from '@wix/editor-kits';

export const getFullKitInjectionOptions = (
  layoutFamily: LayoutFamilyDefinition,
  kitInjectionOptions: KitInjectionOptions,
): KitInjectionOptions => {
  const { disableShapeDividers, disableGradient } = layoutFamily;
  return {
    ...kitInjectionOptions,
    disableShapeDividers,
    disableGradient,
  };
};
