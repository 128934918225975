import _ from 'lodash';
import { WIX_PRO_GALLERY } from '@wix/app-definition-ids';
import type { SerializedCompStructure } from '@wix/document-services-types';
import type { SectionPresetStructure } from '../types';
import { replaceInstagramPreview } from './instagram';
import { removeParallaxBehaviors } from './behaviors';
import { replaceProGalleryPreviewData } from './proGallery';

export const fixSectionPreviewStructures = (
  sections: SectionPresetStructure[],
): SectionPresetStructure[] => {
  return _(sections)
    .thru(replaceInstagramPreview)
    .thru(removeParallaxBehaviors)
    .thru(replaceProGalleryPreviewData)
    .value();
};

const APP_DEF_IDS_WIDGETS_TO_PRESAVE_IDS = [WIX_PRO_GALLERY];
const collectOldToNewMap = (
  { data, originCompId, components }: SerializedCompStructure,
  map: Record<string, string>,
): void => {
  if (
    originCompId &&
    APP_DEF_IDS_WIDGETS_TO_PRESAVE_IDS.includes(data?.appDefinitionId)
  ) {
    map[originCompId] = originCompId;
  }
  components?.forEach((childStructure) => {
    collectOldToNewMap(childStructure, map);
  });
};

export const getPreviewOldToNewIdsMap = (
  compStructure: SerializedCompStructure,
): Record<string, string> => {
  const map: Record<string, string> = {};
  collectOldToNewMap(compStructure, map);
  return map;
};
