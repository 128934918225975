import { CeType } from '@wix/adi-core-types';

export const ADD_PRESET_SERVERLESS_BASE_URL =
  'https://editor.wix.com/_serverless/editor-presets-bundle/';

export enum PresetStyleType {
  Style1 = 'style_1',
  Style2 = 'style_2',
  Style3 = 'style_3',
  Style4 = 'style_4',
  Style5 = 'style_5',
  Style6 = 'style_6',
  Style7 = 'style_7',
  Style8 = 'style_8',
  Style9 = 'style_9',
  Style10 = 'style_10',
  Style11 = 'style_11',
  Style12 = 'style_12',
  Style13 = 'style_13',
  Style14 = 'style_14',
  Style15 = 'style_15',
}

export enum PresetContentVolume {
  HEAVY = 'heavy',
  Light = 'light',
}

//TODO: get from compTypes package when available
export const COMP_TYPES = {
  DEAD_COMP: 'to.be.removed.by.MobileMerge',
  TEXT: 'wysiwyg.viewer.components.WRichText',
  APP_WIDGET: 'platform.components.AppWidget',
  SOCIAL_LINK_BAR: 'wysiwyg.viewer.components.LinkBar',
  SECTION: 'wysiwyg.viewer.components.ClassicSection',
  CONTAINER: 'mobile.core.components.Container',
  TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
  FORM_CONTAINER: 'wysiwyg.viewer.components.FormContainer',
  STRIP_COLUMNS_CONTAINER: 'wysiwyg.viewer.components.StripColumnsContainer',
  REF_COMPONENT: 'wysiwyg.viewer.components.RefComponent',
  HEADER: 'wysiwyg.viewer.components.HeaderContainer',
  FOOTER: 'wysiwyg.viewer.components.FooterContainer',
};

export const HEADER_WIDGETS_ALIGNMENT_GAP = 15;
export const SECTION_MIN_HEIGHT = 40;

export const RESTRICTED_SECTION_CE_TYPES_TO_ADD = new Set<CeType>([
  CeType.Portfolio_Widget,
]);

export const PROPS_TO_DELETE_FROM_STRUCTURE = [
  'mobileStructure',
  'mobileHints',
];

export enum logoAlignmentOptions {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
