import type { JSXElementConstructor } from 'react';
import type { BaseAnimationOptions } from '@wix/document-services-types';

export interface TargetCompIdReactions {
  [targetCompId: string]: Array<{
    triggerBpRange: {
      min?: number;
      max?: number;
    };
    reactions: Array<{ reactionData: { effect: string } }>;
  }>;
}

// On each time we support a trigger, we should take values from here
// https://github.com/wix-private/thunderbolt/blob/5d594a6561cd600be6cfc0940360dcc9affbfc4c/packages/feature-triggers-and-reactions/src/types.ts#L4
export enum PreviewerSupportedTriggers {
  ViewportEnter = 'viewport-enter',
}

export interface ViewerDoneParams {
  shadowRoot: ShadowRoot;
  options: {
    triggersAndReactions: {
      compsToTriggers: {
        [triggerCompId: string]: Record<
          PreviewerSupportedTriggers,
          TargetCompIdReactions
        >;
      };
    };
  };
}

export interface PreviewerViewportAnimationData {
  allowReplay: string;
  compId: string;
  duration: number;
  delay: number;
  name: string;
  effectId: string;
}

export interface DisplayConfig {
  displayWidth?: number;
  baseWidth?: number;
  displayMaxHeight?: number;
  targetDefaultHeight?: number;
  shouldOverlay?: boolean;
  isInteractive?: boolean;
  isScrollable?: boolean;
  isClickable?: boolean;
  onReady?: () => void;
}

export interface PreviewerEffectsMapping {
  [compId: string]: Array<{
    effectId: string;
    animationOptions?: BaseAnimationOptions;
  }>;
}

export interface PreviewerComponentProps extends DisplayConfig {
  effectsMapping: PreviewerEffectsMapping;
  enablePreviewAnimationsPromise?: Promise<void>;
  ThumbnailComponent: JSXElementConstructor<{ onReady: Function }>;
}
