import { CeType } from '@wix/adi-core-types';
import { replaceStructureColoration } from '../utils';
import { GradientType, KitColorationType, KitGradientPreset } from '../consts';

import type { KitDefinition, KitSiteStructure } from '../types';
import type {
  SerializedCompStructure,
  ContainerContentRole,
} from '@wix/document-services-types';

const applyGradientPresetOnStructure = (
  compStructure: SerializedCompStructure,
  kitDefinition: KitDefinition,
  gradientType: GradientType,
): SerializedCompStructure => {
  if (!compStructure.design?.background) {
    return compStructure;
  }
  // TODO: handle header & footer when we have product decision how to apply gradients
  const coloredCompStructure = replaceStructureColoration(
    compStructure,
    kitDefinition.coloration[KitColorationType.Secondary],
    kitDefinition,
  );
  coloredCompStructure.design.background.colorLayers =
    kitDefinition.gradients![gradientType];
  return coloredCompStructure;
};

export const applyGradientPreset = (
  siteStructure: KitSiteStructure,
  kitDefinition: KitDefinition,
  gradientPreset?: KitGradientPreset,
): KitSiteStructure => {
  if (!kitDefinition.gradients) return siteStructure;
  switch (gradientPreset) {
    case KitGradientPreset.Side:
      return {
        header: siteStructure.header && {
          // TODO: handle header & footer when we have product decision how to apply gradients
          ...siteStructure.header,
        },
        sections: siteStructure.sections.map((section) => ({
          ...section,
          structure: applyGradientPresetOnStructure(
            section.structure,
            kitDefinition,
            GradientType.Side,
          ),
          mobileStructure: applyGradientPresetOnStructure(
            section.mobileStructure,
            kitDefinition,
            GradientType.Side,
          ),
        })),
        footer: siteStructure.footer && {
          // TODO: handle header & footer when we have product decision how to apply gradients
          ...siteStructure.footer,
        },
      };

    case KitGradientPreset.MixSingle:
      return {
        ...siteStructure,
        sections: siteStructure.sections.map((section, index) => ({
          ...section,
          structure:
            index % 2 === 0
              ? applyGradientPresetOnStructure(
                  section.structure,
                  kitDefinition,
                  GradientType.Freestyle,
                )
              : section.structure,
          mobileStructure:
            index % 2 === 0
              ? applyGradientPresetOnStructure(
                  section.mobileStructure,
                  kitDefinition,
                  GradientType.Freestyle,
                )
              : section.mobileStructure,
        })),
      };

    case KitGradientPreset.MixHarmony:
      // TODO: missing footer handling for next in line (depends on sections.length even or odd)
      return {
        ...siteStructure,
        sections: siteStructure.sections.map((section, index) => ({
          ...section,
          structure: applyGradientPresetOnStructure(
            section.structure,
            kitDefinition,
            index % 2 === 0 ? GradientType.Bottom : GradientType.Top,
          ),
          mobileStructure: applyGradientPresetOnStructure(
            section.mobileStructure,
            kitDefinition,
            index % 2 === 0 ? GradientType.Bottom : GradientType.Top,
          ),
        })),
      };

    case KitGradientPreset.Welcome:
      return {
        ...siteStructure,
        sections: siteStructure.sections.map((section) => {
          if (
            (section.structure.contentRole as ContainerContentRole)
              ?.contentRole === CeType.Welcome
          ) {
            return {
              ...section,
              structure: applyGradientPresetOnStructure(
                section.structure,
                kitDefinition,
                GradientType.Freestyle,
              ),
              mobileStructure: applyGradientPresetOnStructure(
                section.mobileStructure,
                kitDefinition,
                GradientType.Freestyle,
              ),
            };
          }
          return section;
        }),
      };
    default:
      return siteStructure;
  }
};
