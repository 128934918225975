import { mapStructureByCompType } from '@wix/editor-kits';
import type { SectionPresetStructure } from '../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

export const a11yUpgradeTitlesInPage = (sections: SectionPresetStructure[]) => {
  if (!sections.length) return;
  const [firstSection] = sections;
  upgradeTitlesInSection(firstSection);
};

const upgradeTitlesInSection = (section: SectionPresetStructure) => {
  const sectionHeadingTexts = mapStructureByCompType(section.structure)[
    // TODO: use compTypes package when ready
    'wysiwyg.viewer.components.WRichText'
  ];
  sectionHeadingTexts?.forEach((textStructure) =>
    upgradeHeadingTags(textStructure),
  );
};

const upgradeHeadingTags = (textStructure: SerializedCompStructure) => {
  const { data } = textStructure;
  if (data?.text) {
    data.text = replaceHeadingTag(textStructure.data.text);
  } else if (data.original.text) {
    upgradeHeadingTagsInRepeaterTexts(textStructure);
  }
};

export const upgradeHeadingTagsInRepeaterTexts = ({
  data,
}: SerializedCompStructure) => {
  data.original.text = replaceHeadingTag(data.original.text);
  Object.keys(data.overrides).forEach((item) => {
    data.overrides[item].text = replaceHeadingTag(data.overrides[item].text);
  });
};

const replaceHeadingTag = (text: string) =>
  text.replace(
    /<h([2-6])([^>]+)>(.*?)<\/h([2-6])>/gi,
    (match, openTagLevel, attributes, content) => {
      const newTagLevel = parseInt(openTagLevel) - 1;
      if (newTagLevel >= 1) {
        return `<h${newTagLevel}${attributes}>${content}</h${newTagLevel}>`;
      } else {
        return match;
      }
    },
  );
