import type { SerializedCompStructure } from '@wix/document-services-types';
import { KitDefinition, KitPresetStructures, KitSiteStructure } from '../types';
import {
  getFontNameFromText,
  mapStructureByCompType,
  mapStructureById,
} from '../utils';

export const applyCapsFonts = (
  siteStructure: KitSiteStructure,
  capsFonts: KitDefinition['capsFonts'],
) => {
  if (!capsFonts.length) return;
  siteStructure.sections.forEach((section) => {
    applyCapsFontsToSection(section, capsFonts);
  });

  if (siteStructure.header) {
    applyCapsFontsToSection(siteStructure.header, capsFonts);
  }
  if (siteStructure.footer) {
    applyCapsFontsToSection(siteStructure.footer, capsFonts);
  }
};

const applyCapsFontsToSection = (
  { structure, mobileStructure }: KitPresetStructures,
  capsFonts: KitDefinition['capsFonts'],
) => {
  const mobileElementsById = mapStructureById(mobileStructure);
  const structureTexts = mapStructureByCompType(structure)[
    // TODO: use compTypes package when ready
    'wysiwyg.viewer.components.WRichText'
  ]?.filter((textStructure) => {
    if (!textStructure.data) return false;
    return isTextFontInCapsFonts(
      textStructure.data.text || textStructure.data.original.text,
      capsFonts,
    );
  });

  structureTexts?.forEach((structure) => {
    injectAllCapsInText(structure);
    const mobileTextStructure = mobileElementsById[structure.id!];
    if (mobileTextStructure) {
      injectAllCapsInText(mobileTextStructure);
    }
  });
};

const isTextFontInCapsFonts = (
  text: string,
  capsFonts: KitDefinition['capsFonts'],
) => {
  const fontName = getFontNameFromText(text);
  return (capsFonts as string[]).includes(fontName);
};

export const injectAllCapsInText = (textStructure: SerializedCompStructure) => {
  const { data } = textStructure;
  if (data.text) {
    data.text = replaceTextToCaps(data.text);
  } else if (data.original.text) {
    injectAllCapsInRepeaterTexts(textStructure);
  }
};

const injectAllCapsInRepeaterTexts = ({ data }: SerializedCompStructure) => {
  data.original.text = replaceTextToCaps(data.original.text);
  Object.keys(data.overrides).forEach((item) => {
    data.overrides[item].text = replaceTextToCaps(data.overrides[item].text);
  });
};

const replaceTextToCaps = (text: string) =>
  text.replace(/>(.*?)</g, (match) => match.toUpperCase());
