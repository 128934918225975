import _ from 'lodash';
import { WIX_PRO_GALLERY } from '@wix/app-definition-ids';
import { msidToProGalleryIdMap } from './consts';
import { PresetContentCategory } from '@wix/editor-content-provider';

import type { SectionPresetStructure } from '../../types';
import type { SerializedCompStructure } from '@wix/document-services-types';

const collectGalleries = (
  structure: SerializedCompStructure,
  galleyStructures: SerializedCompStructure[],
): void => {
  if (structure.data?.appDefinitionId === WIX_PRO_GALLERY) {
    galleyStructures.push(structure);
  }
  structure.components?.forEach((childStructure) => {
    collectGalleries(childStructure, galleyStructures);
  });
};

const replaceGalleriesCompIds = (
  rootStructure: SerializedCompStructure,
  msid: string,
): void => {
  const galleryStructures: SerializedCompStructure[] = [];
  collectGalleries(rootStructure, galleryStructures);
  const compId = msidToProGalleryIdMap[msid];
  if (!compId) {
    return;
  }
  galleryStructures.forEach((galleyStructure) => {
    galleyStructure.id = compId;
    galleyStructure.originCompId = compId;
  });
};

const applyProGalleryAppDataByMsid = (
  structure: SerializedCompStructure,
  msid: string,
): SerializedCompStructure => {
  const clonedSectionStructure = _.cloneDeep(structure);
  replaceGalleriesCompIds(clonedSectionStructure, msid);
  return clonedSectionStructure;
};

export const replaceProGalleryPreviewData = (
  sections: SectionPresetStructure[],
): SectionPresetStructure[] => {
  return sections.map((section) => {
    if (section.contentCategory === PresetContentCategory.ProGallery) {
      return {
        ...section,
        structure: applyProGalleryAppDataByMsid(
          section.structure,
          section.sourceTemplateId,
        ),
        mobileStructure: applyProGalleryAppDataByMsid(
          section.mobileStructure,
          section.sourceTemplateId,
        ),
      };
    }
    return section;
  });
};
