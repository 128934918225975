import _ from 'lodash';
import { hex } from 'wcag-contrast';
import { RGBColorObject, HSBColorObject } from './types';
import {
  ALL_ACCENTS_COLORS,
  COLOR_ROLE_TO_COLOR_NAME_MAP,
  COLOR_ROLES,
  RGB_COLORS,
  SHADES_COLORS,
  SHADES_RATIOS,
} from './consts';
import { ColorPalette as DMColorPalette } from '@wix/document-services-types';
import { ColorName } from '../palettesFromComponentApi/types';
type PartialColorPalette = Partial<DMColorPalette>;

const getContrastLevel = (color1: string, color2: string) => {
  try {
    return hex(color1, color2);
  } catch {
    // if `hex` function can fail if one of the arguments is not hex color
    // 21 is the contrast between black and white
    // in case something fails - a11y errors won't be shown
    return 21;
  }
};

const isAccessible = (
  color1: string | null | undefined,
  color2: string | string[] | null | undefined,
  optimalLevel = 4.5,
) => {
  if (!color1 || !color2) return true;

  return Array.isArray(color2)
    ? color2.every((color) => getContrastLevel(color1, color) >= optimalLevel)
    : getContrastLevel(color1, color2) >= optimalLevel;
};

function realMod(value, mod) {
  const result = value > 0 ? value % mod : (mod + value) % mod;
  return result;
}

function rgbToHsbExact(rgb: RGBColorObject) {
  let hue;
  const { red } = rgb;
  const { green } = rgb;
  const { blue } = rgb;
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;
  const cMax = Math.max(r, g, b);
  const cMin = Math.min(r, g, b);
  const delta = cMax - cMin;
  if (delta === 0) {
    hue = 0;
  } else {
    switch (cMax) {
      case r:
        hue = 60 * realMod((g - b) / delta, 6);
        break;
      case g:
        hue = 60 * ((b - r) / delta + 2);
        break;
      case b:
        hue = 60 * ((r - g) / delta + 4);
        break;
    }
  }

  const saturation = cMax === 0 ? 0 : delta / cMax;
  const brightness = cMax;

  return {
    hue,
    saturation: 100 * saturation,
    brightness: 100 * brightness,
  };
}

function rgbToHsb(rgb: RGBColorObject): HSBColorObject {
  const hsbExact = rgbToHsbExact(rgb);
  return {
    hue: Math.round(hsbExact.hue),
    saturation: Math.round(hsbExact.saturation),
    brightness: Math.round(hsbExact.brightness),
  };
}

const fixValue = (value: number) => {
  if (value > 255) return 255;
  if (value < 0) return 0;
  return Math.round(value);
};

const getShadeRgb = (
  darkRgb: RGBColorObject,
  lightRgb: RGBColorObject,
  darkRatio: number,
) => {
  const rgbColorObject = { red: 0, blue: 0, green: 0 };
  RGB_COLORS.forEach((color) => {
    rgbColorObject[color] = fixValue(
      darkRatio * darkRgb[color] + (1 - darkRatio) * lightRgb[color],
    );
  });
  return rgbColorObject;
};

const getColorNameByRole = (role: COLOR_ROLES): ColorName | null => {
  return COLOR_ROLE_TO_COLOR_NAME_MAP[role]
    ? COLOR_ROLE_TO_COLOR_NAME_MAP[role]
    : null;
};

const getColorValueByRole = (
  palette: PartialColorPalette,
  role: COLOR_ROLES,
): string | null => {
  const colorName = getColorNameByRole(role);
  if (!colorName) return null;
  return palette[colorName] ?? null;
};

const isAccent1AccessibleToBase1 = (palette: PartialColorPalette) =>
  isAccessible(
    getColorValueByRole(palette, COLOR_ROLES.SECONDARY_1),
    getColorValueByRole(palette, COLOR_ROLES.MAIN_1),
  );

const getA11yButtonColor = (palette: PartialColorPalette) => {
  return isAccent1AccessibleToBase1(palette)
    ? getColorNameByRole(COLOR_ROLES.MAIN_1)
    : getColorNameByRole(COLOR_ROLES.MAIN_2);
};

const getAdvancedColorsMapping = (): PartialColorPalette => {
  const advancedColorsMapping: PartialColorPalette = {};

  const background = getColorNameByRole(COLOR_ROLES.BACKGROUND);
  if (background) advancedColorsMapping[background] = 'color_36';
  const secondaryBackground = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BACKGROUND,
  );
  if (secondaryBackground)
    advancedColorsMapping[secondaryBackground] = 'color_38';
  const secondaryText = getColorNameByRole(COLOR_ROLES.SECONDARY_TEXT);
  if (secondaryText) advancedColorsMapping[secondaryText] = 'color_40';
  const primaryText = getColorNameByRole(COLOR_ROLES.PRIMARY_TEXT);
  if (primaryText) advancedColorsMapping[primaryText] = 'color_37';
  const link = getColorNameByRole(COLOR_ROLES.LINK);
  if (link) advancedColorsMapping[link] = 'color_41';
  const title = getColorNameByRole(COLOR_ROLES.TITLE);
  if (title) advancedColorsMapping[title] = 'color_37';
  const subtitle = getColorNameByRole(COLOR_ROLES.SUBTITLE);
  if (subtitle) advancedColorsMapping[subtitle] = 'color_37';
  const line = getColorNameByRole(COLOR_ROLES.LINE);
  if (line) advancedColorsMapping[line] = 'color_40';
  const primaryButtonFill = getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_FILL);
  if (primaryButtonFill) advancedColorsMapping[primaryButtonFill] = 'color_41';
  const primaryButtonBorder = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_BORDER,
  );
  if (primaryButtonBorder)
    advancedColorsMapping[primaryButtonBorder] = 'color_41';
  const primaryButtonText = getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_TEXT);
  if (primaryButtonText) advancedColorsMapping[primaryButtonText] = 'color_36';
  const primaryButtonFillHover = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_FILL_HOVER,
  );
  if (primaryButtonFillHover)
    advancedColorsMapping[primaryButtonFillHover] = 'color_36';
  const primaryButtonBorderHover = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_BORDER_HOVER,
  );
  if (primaryButtonBorderHover)
    advancedColorsMapping[primaryButtonBorderHover] = 'color_41';
  const primaryButtonTextHover = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_TEXT_HOVER,
  );
  if (primaryButtonTextHover)
    advancedColorsMapping[primaryButtonTextHover] = 'color_41';
  const primaryButtonFillDisabled = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_FILL_DISABLED,
  );
  if (primaryButtonFillDisabled)
    advancedColorsMapping[primaryButtonFillDisabled] = 'color_39';
  const primaryButtonBorderDisabled = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_BORDER_DISABLED,
  );
  if (primaryButtonBorderDisabled)
    advancedColorsMapping[primaryButtonBorderDisabled] = 'color_39';
  const primaryButtonTextDisabled = getColorNameByRole(
    COLOR_ROLES.PRIMARY_BUTTON_TEXT_DISABLED,
  );
  if (primaryButtonTextDisabled)
    advancedColorsMapping[primaryButtonTextDisabled] = 'color_36';
  const secondaryButtonFill = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_FILL,
  );
  if (secondaryButtonFill)
    advancedColorsMapping[secondaryButtonFill] = 'color_36';
  const secondaryButtonBorder = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_BORDER,
  );
  if (secondaryButtonBorder)
    advancedColorsMapping[secondaryButtonBorder] = 'color_41';
  const secondaryButtonText = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_TEXT,
  );
  if (secondaryButtonText)
    advancedColorsMapping[secondaryButtonText] = 'color_41';
  const secondaryButtonFillHover = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_FILL_HOVER,
  );
  if (secondaryButtonFillHover)
    advancedColorsMapping[secondaryButtonFillHover] = 'color_41';
  const secondaryButtonBorderHover = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_BORDER_HOVER,
  );
  if (secondaryButtonBorderHover)
    advancedColorsMapping[secondaryButtonBorderHover] = 'color_41';
  const secondaryButtonTextHover = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_TEXT_HOVER,
  );
  if (secondaryButtonTextHover)
    advancedColorsMapping[secondaryButtonTextHover] = 'color_36';
  const secondaryButtonFillDisabled = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_FILL_DISABLED,
  );
  if (secondaryButtonFillDisabled)
    advancedColorsMapping[secondaryButtonFillDisabled] = 'color_36';
  const secondaryButtonBorderDisabled = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_BORDER_DISABLED,
  );
  if (secondaryButtonBorderDisabled)
    advancedColorsMapping[secondaryButtonBorderDisabled] = 'color_39';
  const secondaryButtonTextDisabled = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BUTTON_TEXT_DISABLED,
  );
  if (secondaryButtonTextDisabled)
    advancedColorsMapping[secondaryButtonTextDisabled] = 'color_39';

  return advancedColorsMapping;
};

const getA11yColors = (
  colorNames: ColorName[],
  palette: PartialColorPalette,
  colorToCompare: ColorName,
) => {
  return colorNames.filter((colorName) => {
    return isAccessible(palette[colorName], palette[colorToCompare]);
  });
};

const getMostContrastColor = (
  palette: PartialColorPalette,
  colors: ColorName[],
  colorToCompare: string,
): string | null => {
  let mostContrastColor: { value: number; colorName: string } = {
    value: 0,
    colorName: '',
  };

  let isPaletteContainNull = false;
  colors.forEach((colorName) => {
    const colorValue = palette[colorName];
    if (!colorValue) {
      isPaletteContainNull = true;
      return;
    }
    const contrastValue = getContrastLevel(colorValue, colorToCompare);
    if (contrastValue > mostContrastColor.value) {
      mostContrastColor = {
        value: contrastValue,
        colorName,
      };
    }
  });
  if (isPaletteContainNull) {
    return null;
  }

  return mostContrastColor.colorName;
};

const getLinksAndActionsColor = (
  palette: PartialColorPalette,
): ColorName | null => {
  if (isAccent1AccessibleToBase1(palette)) {
    return getColorNameByRole(COLOR_ROLES.SECONDARY_1);
  }

  const main1 = getColorNameByRole(COLOR_ROLES.MAIN_1);
  if (!main1) return null;
  const a11yAccentColors = getA11yColors(ALL_ACCENTS_COLORS, palette, main1);
  const a11yShadowColors = getA11yColors(SHADES_COLORS, palette, main1);

  // some palettes have no accessible colors from accent and shadow colors,
  // in such cases we need to find the most contrast color to Base1 from accents and shadows
  const main1Value = getColorValueByRole(palette, COLOR_ROLES.MAIN_1);
  if (!main1Value) return null;
  return (
    a11yAccentColors[0] ||
    a11yShadowColors[0] ||
    getMostContrastColor(
      palette,
      [...ALL_ACCENTS_COLORS, ...SHADES_COLORS],
      main1Value,
    )
  );
};

function hexToRgb(hex): RGBColorObject | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
}

const getSecondaryBgColor = (
  palette: PartialColorPalette,
): ColorName | null => {
  let maxBrightnessValue = 0;
  const main2 = getColorNameByRole(COLOR_ROLES.MAIN_2);
  if (!main2) {
    return null;
  }

  const a11yAccentColors = getA11yColors(ALL_ACCENTS_COLORS, palette, main2);
  const a11yShadowColors = getA11yColors(SHADES_COLORS, palette, main2);

  if (a11yAccentColors.length === 1) {
    return a11yAccentColors[0];
  }

  const colorsToCheck =
    a11yAccentColors.length === 0 ? a11yShadowColors : a11yAccentColors;

  if (a11yShadowColors.length === 0) {
    const main1ColorName = getColorNameByRole(COLOR_ROLES.MAIN_1);
    if (!main1ColorName) return null;
    return main1ColorName;
  }

  const colorsHsbValues = colorsToCheck.map((colorName) => {
    const colorRgb = hexToRgb(palette[colorName as ColorName]);
    if (!colorRgb) return null;
    const colorHsb = rgbToHsb(colorRgb);

    if (colorHsb.brightness > maxBrightnessValue) {
      maxBrightnessValue = colorHsb.brightness;
    }

    return { colorName, colorHsb };
  });

  if (colorsHsbValues.some((color) => !color)) {
    return null;
  }

  const brightestColors = colorsHsbValues.filter(
    (color) => color?.colorHsb.brightness === maxBrightnessValue,
  ) as { colorName: ColorName; colorHsb: HSBColorObject }[];

  const sortedByMinSaturation = brightestColors.sort(
    (color1, color2) => color1.colorHsb.saturation - color2.colorHsb.saturation,
  );

  const leastSaturatedColor = sortedByMinSaturation[0].colorName;

  return leastSaturatedColor;
};

function colorComponentToHex(c: string | number = '') {
  const hex = c.toString(16);
  const result = hex.length === 1 ? `0${hex}` : hex;
  return result.toUpperCase();
}

function rgbToHex(rgb: RGBColorObject) {
  return `#${colorComponentToHex(rgb.red)}${colorComponentToHex(
    rgb.green,
  )}${colorComponentToHex(rgb.blue)}`;
}

const generateShadeColors = (hexLight: string, hexDark: string) => {
  const lightRgb = hexToRgb(hexLight);
  const darkRgb = hexToRgb(hexDark);
  if (!lightRgb || !darkRgb) return null;

  return _.mapValues(SHADES_RATIOS, (ratio) =>
    rgbToHex(getShadeRgb(darkRgb, lightRgb, ratio)),
  );
};

const isColorDark = (color: string | undefined) => {
  if (!color) return null;
  const rgb = hexToRgb(color);
  if (!rgb) return null;
  const { red, green, blue } = rgb;
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
  return brightness < 128;
};

const getGptStringByAccesibility = (
  colorToCheck: string | undefined,
  colorToCheckIndex: number,
  main1ColorName: string | undefined,
  main2ColorName: string | undefined,
) => {
  if (!colorToCheck || !main1ColorName || !main2ColorName) return null;
  const isAccentColor1AccessibleToMainColor1 = isAccessible(
    colorToCheck,
    main1ColorName,
    3,
  );
  const isAccentColor1AccessibleToMainColor2 = isAccessible(
    colorToCheck,
    main2ColorName,
    3,
  );
  switch (true) {
    case isAccentColor1AccessibleToMainColor1 &&
      isAccentColor1AccessibleToMainColor2:
      return `Accent Color ${colorToCheckIndex}: ${colorToCheck} (contrasts with both base colors)`;
    case isAccentColor1AccessibleToMainColor1 &&
      !isAccentColor1AccessibleToMainColor2:
      return `Accent Color ${colorToCheckIndex}: ${colorToCheck} (contrasts with Base Color 1)`;
    case !isAccentColor1AccessibleToMainColor1 &&
      isAccentColor1AccessibleToMainColor2:
      return `Accent Color ${colorToCheckIndex}: ${colorToCheck} (contrasts with Base Color 2)`;
    case !isAccentColor1AccessibleToMainColor1 &&
      !isAccentColor1AccessibleToMainColor2:
      return `Accent Color ${colorToCheckIndex}: ${colorToCheck} (contrasts with neither base color)`;
  }
};

const getColorsStringsToGpt = (
  main1Color: string | undefined,
  main2Color: string | undefined,
  secondary1Color: string | undefined,
  secondary2Color: string | undefined,
  secondary3Color: string | undefined,
  secondary4Color: string | undefined,
) => {
  const isBaseColor1Dark = isColorDark(main1Color);
  const isBaseColor2Dark = isColorDark(main2Color);
  if (isBaseColor1Dark === null || isBaseColor2Dark === null) return null;
  const baseColor1 = `Base Color 1: ${main1Color} (${
    isBaseColor1Dark ? `dark` : `bright`
  })`;
  const baseColor2 = `Base Color 2: ${main2Color} (${
    isBaseColor2Dark ? `dark` : `bright`
  })`;

  const accentColor1 = getGptStringByAccesibility(
    secondary1Color,
    1,
    main1Color,
    main2Color,
  );
  const accentColor2 = getGptStringByAccesibility(
    secondary2Color,
    2,
    main1Color,
    main2Color,
  );
  const accentColor3 = getGptStringByAccesibility(
    secondary3Color,
    3,
    main1Color,
    main2Color,
  );
  const accentColor4 = getGptStringByAccesibility(
    secondary4Color,
    4,
    main1Color,
    main2Color,
  );
  if (!accentColor1 || !accentColor2 || !accentColor3 || !accentColor4)
    return null;

  return {
    baseColor1,
    baseColor2,
    accentColor1,
    accentColor2,
    accentColor3,
    accentColor4,
  };
};

const convertColorPaletteToGPTParam = (
  palette: PartialColorPalette,
  isAdditionalColorsNeeded = true,
): string | null => {
  const main1ColorName = getColorNameByRole(COLOR_ROLES.MAIN_1);
  const main2ColorName = getColorNameByRole(COLOR_ROLES.MAIN_2);
  const secondary1ColorName = getColorNameByRole(COLOR_ROLES.SECONDARY_1);
  const secondary2ColorName = getColorNameByRole(COLOR_ROLES.SECONDARY_2);
  const secondary3ColorName = getColorNameByRole(COLOR_ROLES.SECONDARY_3);
  const secondary4ColorName = getColorNameByRole(COLOR_ROLES.SECONDARY_4);
  if (
    !main1ColorName ||
    !main2ColorName ||
    !secondary1ColorName ||
    !secondary2ColorName ||
    !secondary3ColorName ||
    !secondary4ColorName
  )
    return null;

  const colorsToGptValues = getColorsStringsToGpt(
    palette[main1ColorName],
    palette[main2ColorName],
    palette[secondary1ColorName],
    palette[secondary2ColorName],
    palette[secondary3ColorName],
    palette[secondary4ColorName],
  );
  if (!colorsToGptValues) return null;

  // Optional additional colors, if they exist
  const additionalColors: string[] = [];
  if (isAdditionalColorsNeeded) {
    for (let i = 38; i <= 40; i++) {
      const colorKey = `color_${i}` as ColorName;
      if (palette[colorKey]) {
        additionalColors.push(
          `Accent Color ${i - 37} (additional): ${palette[colorKey]}`,
        );
      }
    }
  }

  const allColors = [...Object.values(colorsToGptValues), ...additionalColors];

  return `[${allColors.join(', ')}]`;
};

export {
  getA11yButtonColor,
  getColorNameByRole,
  getAdvancedColorsMapping,
  getLinksAndActionsColor,
  getSecondaryBgColor,
  generateShadeColors,
  convertColorPaletteToGPTParam,
};
