import type { SerializedCompStructure } from '@wix/document-services-types';

export const getInstagramDesktopPreviewStructureReplacer = (
  y: number,
): SerializedCompStructure => {
  return {
    type: 'Container',
    components: [
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a2',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfo7wm',
        },
        style: 'mc1',
      },
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 250,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a3',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfoe4p',
        },
        style: 'mc1',
      },
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 500,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a4',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfol2p',
        },
        style: 'mc1',
      },
      {
        type: 'Container',
        components: [],
        layout: {
          width: 230,
          height: 264,
          x: 750,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.Column',
        parent: 'comp-lovfl52w',
        id: 'comp-irvfl0a5',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            type: 'BackgroundMedia',
            mediaRef: {
              type: 'Image',
              title: 'Portrait (1).jpg',
              uri: 'a3c153_df0f2c1592834d5288499f33b98b32c1~mv2.jpg',
              description: 'private',
              width: 1500,
              height: 2000,
              alt: 'Portrait (1).jpg',
              artist: {
                id: '',
                name: '',
              },
              hasAnimation: false,
            },
            color: '#FAFAFA',
            colorOpacity: 1,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '{color_11}',
                },
              },
            ],
          },
          charas: 'design-lovfor5n',
        },
        style: 'mc1',
      },
    ],
    layout: {
      width: 980,
      height: 264,
      x: 0,
      y,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
    parent: 'comp-lovfl0a1',
    id: 'comp-irvfl0a1',
    props: {
      type: 'StripColumnsContainerProperties',
      fullWidth: true,
      columnsMargin: 20,
      frameMargin: 0,
      rowMargin: 0,
      siteMargin: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      background: {
        type: 'BackgroundMedia',
        color: '#FFFFFF',
        colorOpacity: 0,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 1,
      },
    },
    style: 'strc1',
  };
};

export const getInstagramMobilePreviewStructureReplacer = (
  y: number,
): SerializedCompStructure => {
  return {
    componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
    layout: {
      x: 0,
      y,
      fixedPosition: false,
      width: 320,
      height: 315,
      scale: 1,
      rotationInDegrees: 0,
    },
    type: 'Container',
    components: [
      {
        componentType: 'wysiwyg.viewer.components.Column',
        layout: {
          x: 0,
          y: 0,
          fixedPosition: false,
          width: 320,
          height: 315,
          scale: 1,
          rotationInDegrees: 0,
        },
        type: 'Container',
        components: [
          {
            layout: {
              x: 168.5,
              y: 164,
              fixedPosition: false,
              width: 139,
              height: 135,
              scale: 1,
              rotationInDegrees: 0,
            },
            componentType: 'wysiwyg.viewer.components.WPhoto',
            type: 'Component',
            parent: 'comp-ls7itmbr',
            data: {
              type: 'Image',
              title: 'Freckles',
              uri: '08f04ba501c5443dadc671324eda69b9.jpg',
              height: 1717,
              width: 2500,
              alt: 'Marble Surface',
              hasAnimation: false,
              name: 'Marble Surface',
            },
            props: {
              type: 'WPhotoProperties',
              displayMode: 'fill',
              autoFill: true,
              onClickBehavior: 'goToLink',
              effectName: 'none',
            },
            style: {
              type: 'ComponentStyle',
              style: {
                properties: {},
                propertiesSource: {},
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.WPhoto',
              skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
              styleType: 'custom',
            },
          },
          {
            layout: {
              x: 14.5,
              y: 164,
              fixedPosition: false,
              width: 139,
              height: 135,
              scale: 1,
              rotationInDegrees: 0,
            },
            componentType: 'wysiwyg.viewer.components.WPhoto',
            type: 'Component',
            parent: 'comp-ls7itmbr',
            data: {
              type: 'Image',
              title: 'Freckles',
              uri: '08f04ba501c5443dadc671324eda69b9.jpg',
              height: 1717,
              width: 2500,
              alt: 'Marble Surface',
              hasAnimation: false,
              name: 'Marble Surface',
            },
            props: {
              type: 'WPhotoProperties',
              displayMode: 'fill',
              autoFill: true,
              onClickBehavior: 'goToLink',
              effectName: 'none',
            },
            style: {
              type: 'ComponentStyle',
              style: {
                properties: {},
                propertiesSource: {},
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.WPhoto',
              skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
              styleType: 'custom',
            },
          },
          {
            layout: {
              x: 14.5,
              y: 16,
              fixedPosition: false,
              width: 139,
              height: 135,
              scale: 1,
              rotationInDegrees: 0,
            },
            componentType: 'wysiwyg.viewer.components.WPhoto',
            type: 'Component',
            parent: 'comp-ls7itmbr',
            data: {
              type: 'Image',
              title: 'Freckles',
              uri: '08f04ba501c5443dadc671324eda69b9.jpg',
              height: 1717,
              width: 2500,
              alt: 'Marble Surface',
              hasAnimation: false,
              name: 'Marble Surface',
            },
            props: {
              type: 'WPhotoProperties',
              displayMode: 'fill',
              autoFill: true,
              onClickBehavior: 'goToLink',
              effectName: 'none',
            },
            style: {
              type: 'ComponentStyle',
              style: {
                properties: {},
                propertiesSource: {},
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.WPhoto',
              skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
              styleType: 'custom',
            },
          },
          {
            layout: {
              x: 168.5,
              y: 16,
              fixedPosition: false,
              width: 139,
              height: 135,
              scale: 1,
              rotationInDegrees: 0,
            },
            componentType: 'wysiwyg.viewer.components.WPhoto',
            type: 'Component',
            parent: 'comp-ls7itmbr',
            data: {
              type: 'Image',
              title: 'Freckles',
              uri: '08f04ba501c5443dadc671324eda69b9.jpg',
              height: 1717,
              width: 2500,
              alt: 'Marble Surface',
              hasAnimation: false,
              name: 'Marble Surface',
            },
            props: {
              type: 'WPhotoProperties',
              displayMode: 'fill',
              autoFill: true,
              onClickBehavior: 'goToLink',
              effectName: 'none',
            },
            style: {
              type: 'ComponentStyle',
              style: {
                properties: {},
                propertiesSource: {},
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.WPhoto',
              skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
              styleType: 'custom',
            },
          },
        ],
        parent: 'comp-ls7itmbb',
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        design: {
          type: 'MediaContainerDesignData',
          background: {
            fittingType: 'fill',
            alignType: 'center',
            colorOverlay: '',
            colorOverlayOpacity: 0,
            color: '#FAFAFA',
            colorOpacity: 1,
            type: 'BackgroundMedia',
            scrollType: 'none',
            showOverlayForMediaType: 'WixVideo',
            colorLayers: [
              {
                type: 'SolidColorLayer',
                opacity: 0,
                fill: {
                  type: 'SolidColor',
                  color: '#FAFAFA',
                },
              },
            ],
          },
          charas: 'design-ls7itwdz',
        },
        style: 'mc1',
      },
    ],
    parent: 'comp-ls7gncms',
    props: {
      frameMargin: 0,
      fullWidth: true,
      columnsMargin: 0,
      siteMargin: 0,
      rowMargin: 0,
      type: 'StripColumnsContainerProperties',
    },
    design: {
      type: 'MediaContainerDesignData',
      background: {
        fittingType: 'fill',
        alignType: 'center',
        colorOverlay: '',
        colorOverlayOpacity: 1,
        color: '#FFFFFF',
        colorOpacity: 0,
        type: 'BackgroundMedia',
        imageOverlay: '',
        scrollType: 'none',
      },
    },
    style: 'strc1',
  };
};
