// TODO we still not working with menus?
export const COMP_IDS_NOT_TO_COLLECT = new Set([
  'MENU_AS_CONTAINER',
  'MENU_AS_CONTAINER_EXPANDABLE_MENU',
]);

export const HEADER = 'header';
export const FOOTER = 'footer';
export const SECTIONS = 'sections';

export const PROPS_KEYS_TO_UPDATE = [
  'minWidth',
  'minHeight',
  'overrideAlignment',
  'overrideColor',
  'verticalText',
];

export const ALLOWED_COMPS_TO_UPDATE_PROPS = [
  'wysiwyg.viewer.components.WRichText',
];

export const LAYOUT_PROPS_TO_OMIT = ['bounding'];

export const LAYOUT_PROPS_WITH_NUMERIC_VALUES = ['x', 'y', 'width', 'height'];
