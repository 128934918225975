import _ from 'lodash';

export const arraysHaveIntersection = <T>(arr1: T[], arr2: T[]): boolean => {
  return !!_.intersection(arr1, arr2).length;
};

export const getIndexOfLongestArray = <T>(listOfArrays: T[][]): number => {
  let longestArrayIndex = -1;
  let longestArrayLength = 0;
  listOfArrays.forEach((array, index) => {
    if (array.length > longestArrayLength) {
      longestArrayLength = array.length;
      longestArrayIndex = index;
    }
  });
  return longestArrayIndex;
};

export const findAllIndicesByCondition = <T>(
  array: T[],
  condition: (item: T) => boolean,
) => {
  const indices: number[] = [];
  array.forEach((item, index) => {
    if (condition(item)) {
      indices.push(index);
    }
  });
  return indices;
};
