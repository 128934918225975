export enum KitShapeDividerPosition {
  Top = 'Top',
  Bottom = 'Bottom',
}
export enum KitColorationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Extra = 'Extra',
}
export enum GradientType {
  Top = 'top',
  Bottom = 'bottom',
  Side = 'side',
  Freestyle = 'freestyle',
}

// TODO: use compTypes package when ready
export const FORMS_TYPES_TO_APPLY_KIT = [
  'wysiwyg.viewer.components.FormContainer',
  'wysiwyg.viewer.components.StateBoxFormState',
];

export const ADD_PRESET_SERVERLESS_BASE_URL =
  'https://editor.wix.com/_serverless/editor-presets-bundle/';

export enum KitColorationPreset {
  Plain = 'Plain',
  MixSingle = 'MixSingle',
  MixDouble = 'MixDouble',
}

export enum KitGradientPreset {
  Side = 'Side',
  MixHarmony = 'MixHarmony',
  MixSingle = 'MixSingle',
  Welcome = 'Welcome',
}

export const EMPTY_COLOR_NAME_VALUE = '__empty__';

export const SECTION_HEIGHT_DELTA = 5;

export const colorationLibrary: Record<
  KitColorationPreset,
  { description: string }
> = {
  [KitColorationPreset.Plain]: {
    description: 'The most basic and conservative version of the theme.',
  },
  [KitColorationPreset.MixSingle]: {
    description:
      'The most colorful version of the theme, alternating usage of the theme colors accross the site. Sections will have different backgrounds.',
  },
  [KitColorationPreset.MixDouble]: {
    description: 'MixDouble.',
  },
};

export const MAIN_BACKGROUND_COLOR = 'color_11';

export const COLOR_ROLES = ['Primary', 'Secondary', 'Additional'];
