import {
  WIX_CHAT,
  WIX_BLOG,
  WIX_FORUM,
  WIX_GROUPS,
  WIX_BOOKINGS,
  PRICING_PLANS,
  WIX_PORTFOLIO,
  WIX_FILE_SHARE,
  WIX_NEW_STORES,
  WIX_CHALLENGES,
  WIX_PHOTO_ALBUMS,
  WIX_RESTAURANTS_MENUS,
  WIX_RESTAURANTS_MENUS_NEW,
  WIX_RESTAURANTS_ORDERS,
  WIX_RESTAURANTS_ORDERS_NEW,
  WIX_RESTAURANTS_TABLE_RESERVATIONS,
} from '@wix/app-definition-ids';
import {
  BANDSINTOWN,
  INSTAGRAM_FEED,
  WIX_EVENTS,
  WIX_FORMS,
  WIX_MUSIC,
  WIX_PRO_GALLERY,
  WIX_RESERVATIONS,
  WIX_VIDEO,
} from '@wix/app-definition-ids';
import { CeType } from '@wix/adi-core-types';

export const ALLOWED_HEADLESS_INSTALL_APP_DEF_IDS = new Set([
  WIX_FORMS,
  WIX_VIDEO,
  WIX_MUSIC,
  WIX_EVENTS,
  BANDSINTOWN,
  INSTAGRAM_FEED,
  WIX_PRO_GALLERY,
  WIX_RESERVATIONS,
]);

export const DEFAULT_LANGUAGE_CODE = 'en';

export const STAGING_SUFFIX = '_staging';

export const LOGGER_PROPS_TO_REPORT = [
  'userData',
  'industryId',
  'structureId',
  'useStagingMode',
];

export const LOGGER_ARGS_TO_REPORT = [
  'appDefIds',
  'layoutFamilyName',
  'layoutFamilyDefinition',
  'ceType',
  'layoutFamily',
];

export const STORES_CE_TYPES = [
  CeType.Stores,
  CeType.StoresGridGalleryWidget,
  CeType.StoresSliderGalleryWidget,
  CeType.StoresProductSpecificWidget,
];

export const SILENT_INSTALLATION_PAGES_APPS = new Set([
  WIX_BLOG,
  WIX_FORUM,
  WIX_GROUPS,
  WIX_BOOKINGS,
  PRICING_PLANS,
  WIX_PORTFOLIO,
  WIX_FILE_SHARE,
  WIX_NEW_STORES,
  WIX_CHALLENGES,
  WIX_PHOTO_ALBUMS,
  WIX_RESTAURANTS_MENUS,
  WIX_RESTAURANTS_MENUS_NEW,
  WIX_RESTAURANTS_ORDERS,
  WIX_RESTAURANTS_ORDERS_NEW,
  WIX_RESTAURANTS_TABLE_RESERVATIONS,
]);

export const ALLOWED_SILENT_INSTALLATION_APPS = new Set([
  WIX_CHAT,
  ...SILENT_INSTALLATION_PAGES_APPS,
]);

export const AI_GATEWAY_APP_DEF_FOR_GET_PROMPTS = `e36c4531-99b6-4a9a-b523-451cca8bf286`;

export const SG_GET_COLORS_FROM_LOGO_SPEC_PROMPT_ID =
  'd8301706-8cc1-4aa4-89fd-2e5fae117b5e';
