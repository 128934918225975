/* ------------------------------------------
https://gist.github.com/sidola/3b267f21c872e449ef4bbdae9e2baeab
------------------------------------------ */

/**
 * Defines the function type of the publish function.
 *
 * Extracts the keys from `E` as valid event types, and the matching
 * property as the payload.
 */
export type PubTypeFn<E> = <Key extends string & keyof E>(
  event: Key,
  message: E[Key],
) => void;

/**
 * Defines the function type for the subscribe function.
 *
 * Extracts the keys from `E` as valid event types, and the matching
 * property as the payload to the callback function.
 *
 * Returns the given callback.
 */
export type SubTypeFn<E> = <Key extends string & keyof E>(
  event: Key,
  fn: (message: E[Key]) => void,
) => (message: E[Key]) => void;

/**
 * Defines the function type for the unsubscribe function.
 *
 * Extracts the keys from `E` as valid event types, and the matching
 * property as the payload to the callback function.
 */
export type UnsubTypeFn<E> = <Key extends string & keyof E>(
  event: Key,
  fn: (message: E[Key]) => void,
) => void;

/**
 * Tie everything together.
 */
export interface PubSubType<E> {
  publish: PubTypeFn<E>;
  subscribe: SubTypeFn<E>;
  unsubscribe: UnsubTypeFn<E>;
}

/**
 * Creates a new PubSub instance, the `E` type parameter should be a
 * type enumerating all the available events and their payloads.
 *
 * @example
 * type Events = {
 *  warn: { message: string },
 *  error: { message: string }
 * }
 *
 * const pubSub = PubSub<Events>()
 * const subHandle = pubSub.subscribe('warn', (message) => {
 *     console.warn(message)
 * })
 *
 * pubSub.publish('warn', { message: "Something bad happened!" })
 * pubSub.unsubscribe('warn', subHandle)
 */
export function PubSub<E>(): PubSubType<E> {
  // This any[] is our list of handlers functions. We don't have the
  // necessary type information in here which is why it's any-typed.
  const handlers: { [key: string]: any[] } = {};

  return {
    publish: (event, msg) => {
      handlers[event]?.forEach((h) => h(msg));
    },

    subscribe: (event, callback) => {
      const list = handlers[event] ?? [];
      list.push(callback);
      handlers[event] = list;

      return callback;
    },

    unsubscribe: (event, callback) => {
      let list = handlers[event] ?? [];
      list = list.filter((h) => h !== callback);
      handlers[event] = list;
    },
  };
}
