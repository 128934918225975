import { SerializedCompStructure } from '@wix/document-services-types';
import { KitDefinition } from '../types';
import { computeLuminosity, hexToRgb } from './textColorOnImageBG/luminosity';
import { LUMINOSITY_THRESHOLD } from './textColorOnImageBG/consts';
import { platformDataMap } from '@wix/editor-content-injector';

const getBackgroundColor = (
  sectionStructure: SerializedCompStructure,
  kitDefinition: KitDefinition,
): { color?: string; opacity?: number } => {
  if (sectionStructure.design) {
    const background = sectionStructure.design.background;
    const colorLayers = background?.colorLayers;
    if (colorLayers) {
      const solidColorLayer = colorLayers.find(
        (layer) => layer.type === 'SolidColorLayer',
      );
      if (solidColorLayer?.fill) {
        return {
          color:
            solidColorLayer.fill.color.match(/color_\d+/g, '')?.[0] ||
            solidColorLayer.fill.color,
          opacity: solidColorLayer.opacity,
        };
      }
    }
    return {
      color:
        (background?.color || '').match(/color_\d+/g, '')?.[0] ||
        background?.color,
      opacity: background?.colorOpacity,
    };
  }
  if (typeof sectionStructure.style === 'string') {
    return {
      color:
        kitDefinition.themeStyles[sectionStructure.style]?.style?.properties
          ?.bg,
      opacity:
        kitDefinition.themeStyles[sectionStructure.style]?.style?.properties?.[
          'alpha-bg'
        ],
    };
  } else {
    return {
      //@ts-expect-error
      color: sectionStructure.style?.style?.properties?.bg,
      //@ts-expect-error
      opacity: sectionStructure.style?.style?.properties?.['alpha-bg'],
    };
  }
};

const setLinkBarColors = (
  structure: SerializedCompStructure,
  background: string,
) => {
  const lightBackground =
    computeLuminosity(hexToRgb(background)) > LUMINOSITY_THRESHOLD;
  const linkData = structure.data;
  linkData?.items.forEach((item) => {
    const platformUriByTitle = getSocialLinkUriByTitle(
      item.title,
      lightBackground,
    );
    if (platformUriByTitle) {
      item.uri = platformUriByTitle;
    }
  });
};

const setSocialLinksColors_recursive = (
  structure: SerializedCompStructure,
  kitDefinition: KitDefinition,
  background: string,
) => {
  // TODO: use compTypes when available
  if (structure.componentType === 'wysiwyg.viewer.components.LinkBar') {
    setLinkBarColors(structure, background);
    return;
  }
  const currentBackground = getBackgroundColor(structure, kitDefinition);
  structure.components?.forEach((component) => {
    setSocialLinksColors_recursive(
      component,
      kitDefinition,
      currentBackground.color && currentBackground.opacity
        ? getHexColorFromKit(currentBackground.color, kitDefinition)
        : background,
    );
  });
  return structure;
};

const getHexColorFromKit = (color: string, kitDefinition: KitDefinition) => {
  return kitDefinition.colors[color] || color;
};

export const setSocialLinksColorsForSection = (
  sectionStructure: SerializedCompStructure,
  kitDefinition: KitDefinition,
) => {
  return setSocialLinksColors_recursive(
    sectionStructure,
    kitDefinition,
    '#ffffff',
  );
};

const getSocialLinkUriByTitle = (
  title: string,
  lightBackground: boolean,
): string => {
  const linkData = Object.keys(platformDataMap).find(
    (item) => platformDataMap[item].Title === title,
  );
  if (!linkData) {
    return '';
  }
  const platformData = platformDataMap[linkData];
  if (!platformData) {
    return '';
  }
  return lightBackground
    ? platformData.relativeUriLight
    : platformData.relativeUriDark;
};
