import _ from 'lodash';
import { mapStructureByCompType, mapStructureById } from '../utils';
import { FORMS_TYPES_TO_APPLY_KIT } from '../consts';

import type { KitDefinition, KitPresetStructures } from '../types';
import type { StyleRef } from '@wix/document-services-types';

export const setFormsStyles = (
  { structure, mobileStructure }: KitPresetStructures,
  kitForms: KitDefinition['forms'],
): void => {
  const mobileStructureById = mapStructureById(mobileStructure);
  const structureByCompTypeEntry = mapStructureByCompType(structure);
  FORMS_TYPES_TO_APPLY_KIT.forEach((formCompType) => {
    structureByCompTypeEntry[formCompType]?.forEach((formStructure) => {
      const formStructureByCompTypeEntry =
        mapStructureByCompType(formStructure);
      Object.entries(kitForms).forEach(([formChildType, formChildStyle]) => {
        formStructureByCompTypeEntry[formChildType]?.forEach(
          (formChildStructure) => {
            const mobileFormChildStructure =
              mobileStructureById[formChildStructure.id || ''];
            if (typeof formChildStyle === 'string') {
              formChildStructure.style = formChildStyle;
              if (mobileFormChildStructure) {
                mobileFormChildStructure.style = formChildStyle;
              }
            } else {
              if (typeof formChildStructure.style === 'object') {
                formChildStructure.style = {
                  ...formChildStyle,
                  id: formChildStructure.style.id,
                } as StyleRef;
                if (
                  mobileFormChildStructure &&
                  typeof mobileFormChildStructure.style === 'object'
                ) {
                  mobileFormChildStructure.style = {
                    ...formChildStyle,
                    id: mobileFormChildStructure.style.id,
                  } as StyleRef;
                }
              } else {
                formChildStructure.style = _.omit(formChildStyle, 'id');
                if (mobileFormChildStructure) {
                  mobileFormChildStructure.style = _.omit(formChildStyle, 'id');
                }
              }
            }
          },
        );
      });
    });
  });
};
