import _ from 'lodash';
import {
  KitDefinition,
  KitPresetStructures,
  KitShapeDividers,
  KitSiteStructure,
} from '../types';
import { SECTION_HEIGHT_DELTA } from '../consts';
import type { SerializedCompStructure } from '@wix/document-services-types';

const SHAPE_DIVIDER_HEIGHT_MOBILE = 50;
const DESIGN_WITH_SHAPE_DIVIDERS_TYPE = 'MediaContainerWithDividers';

export const applyShapeDividers = (
  siteStructure: KitSiteStructure,
  kitShapeDividers: KitDefinition['shapeDividers'],
) => {
  if (kitShapeDividers?.Top) {
    applyTopDividerIfNeeded(
      kitShapeDividers.Top,
      siteStructure.sections[0],
      siteStructure.sections[1],
      siteStructure.sections[2],
    );
  }
  if (kitShapeDividers?.Bottom) {
    const numOfSections = siteStructure.sections.length;
    applyBottomDividerIfNeeded(
      kitShapeDividers.Bottom,
      siteStructure.footer,
      siteStructure.sections[numOfSections - 1],
      siteStructure.sections[numOfSections - 2],
    );
  }
};

const applyTopDividerIfNeeded = (
  topDivider: KitShapeDividers['Top'],
  section1: KitPresetStructures,
  section2: KitPresetStructures,
  section3: KitPresetStructures,
) => {
  if (!(section1 && section2)) {
    return;
  }
  const section1BG = getSectionBackground(section1.structure);
  const section2BG = getSectionBackground(section2.structure);

  if (
    shouldApplyShapeDivider(section1, section2, section1BG, section2BG, false)
  ) {
    setShapeDivider(topDivider, section1BG, section2, false);
    return;
  }

  if (!section3) {
    return;
  }
  const section3BG = getSectionBackground(section3.structure);
  if (
    shouldApplyShapeDivider(section2, section3, section2BG, section3BG, false)
  ) {
    setShapeDivider(topDivider, section2BG, section3, false);
  }
};

const applyBottomDividerIfNeeded = (
  bottomDivider: KitShapeDividers['Bottom'],
  footer: KitPresetStructures | null,
  lastSection: KitPresetStructures,
  beforeLastSection: KitPresetStructures,
) => {
  if (!(footer?.structure && lastSection?.structure)) {
    return;
  }
  const footerBG = getHeaderFooterBackground(footer.structure);
  const lastSectionBG = getSectionBackground(lastSection.structure);

  if (
    shouldApplyShapeDivider(footer, lastSection, footerBG, lastSectionBG, true)
  ) {
    setShapeDivider(bottomDivider, footerBG, lastSection, true);
    return;
  }

  if (!beforeLastSection) {
    return;
  }
  const beforeLastSectionBG = getSectionBackground(beforeLastSection.structure);
  if (
    shouldApplyShapeDivider(
      lastSection,
      beforeLastSection,
      lastSectionBG,
      beforeLastSectionBG,
      true,
    )
  ) {
    setShapeDivider(bottomDivider, lastSectionBG, beforeLastSection, true);
  }
};

export const shouldApplyShapeDivider = (
  section1: KitPresetStructures,
  section2: KitPresetStructures,
  section1BG: string,
  section2BG: string,
  isBottomDivider: boolean,
): boolean => {
  const shouldApply = isBottomDivider
    ? shouldApplyBottomDivider(section1, section2)
    : shouldApplyTopDivider(section1, section2);
  return section1BG !== section2BG && shouldApply;
};

const shouldApplyTopDivider = (
  section1: KitPresetStructures,
  section2: KitPresetStructures,
): boolean => {
  return (
    !hasBackgroundMediaOrStripWithBackground(section1, false) &&
    !hasBackgroundMediaOrStripWithBackground(section2, true)
  );
};

const shouldApplyBottomDivider = (
  section1: KitPresetStructures,
  section2: KitPresetStructures,
): boolean => {
  return (
    !hasBackgroundMediaOrStripWithBackground(section1, true) &&
    !hasBackgroundMediaOrStripWithBackground(section2, false) &&
    !hasTopShapeDivider(section1)
  );
};

const hasTopShapeDivider = ({ structure }: KitPresetStructures): boolean =>
  structure.design?.dividerTop;

const getHeaderFooterBackground = (
  structure: SerializedCompStructure,
): string => {
  return (structure?.style as any)?.style?.properties?.bg;
};

const getSectionBackground = (structure: SerializedCompStructure): string => {
  let color;
  const colorLayers = structure.design?.background?.colorLayers;
  if (!colorLayers) {
    color = structure.design?.background?.color || '';
  } else {
    const solidColorLayer = colorLayers.find(
      (layer) => layer.type === 'SolidColorLayer',
    );
    color = solidColorLayer?.fill?.color || '';
  }

  return color.match(/color_\d+/g, '')?.[0];
};

const hasBackgroundMediaOrStripWithBackground = (
  sectionStructure: KitPresetStructures,
  topOfSection: boolean,
) => {
  const sectionLayout = sectionStructure.structure.layout;
  if (!sectionLayout) {
    // TODO: implement for one doc
    console.error('could not apply shape divider, no layout in structure');
    return false;
  }
  const { height: sectionHeight } = sectionLayout;

  const hasBackgroundMedia =
    sectionStructure.structure.design?.background?.mediaRef;

  if (hasBackgroundMedia) {
    return true;
  }

  const hasStripWithBackgroundMedia =
    sectionStructure.structure.components?.some((compStructure) => {
      const compLayout = compStructure.layout;
      if (!compLayout) {
        // TODO: implement for one doc
        console.error('could not apply shape divider, no layout in structure');
        return false;
      }
      const isStripWithBackgroundMedia = isStripWithMedia(compStructure);
      if (topOfSection) {
        return (
          isStripWithBackgroundMedia && compLayout.y <= SECTION_HEIGHT_DELTA
        );
      } else {
        return (
          isStripWithBackgroundMedia &&
          Math.abs(compLayout.y + compLayout.height - sectionHeight) <=
            SECTION_HEIGHT_DELTA
        );
      }
    });
  return hasStripWithBackgroundMedia;
};

const isStripWithMedia = (compStructure: SerializedCompStructure) => {
  return (
    compStructure.componentType ===
      'wysiwyg.viewer.components.StripColumnsContainer' &&
    compStructure.components!.some(
      (compStructure) => compStructure.design?.background?.mediaRef,
    )
  );
};

const setShapeDivider = (
  shapeDivider: KitShapeDividers['Top'] | KitShapeDividers['Bottom'],
  color: string,
  sectionStructure: KitPresetStructures,
  isBottomDivider: boolean,
) => {
  const dividerData = getDividerData(shapeDivider, color, isBottomDivider);

  sectionStructure.structure.design = {
    ..._.pick(sectionStructure.structure.design, 'background'),
    ...dividerData.designData,
  };
  if (sectionStructure.mobileStructure) {
    sectionStructure.mobileStructure.design = {
      ..._.pick(sectionStructure.mobileStructure.design, 'background'),
      ...dividerData.designData,
    };
  }

  const dividerHeight = dividerData.height;
  extendSection(sectionStructure, dividerHeight);
  if (!isBottomDivider) {
    lowerSectionContent(sectionStructure, dividerHeight);
  }
};

const getDividerData = (
  shapeDividerData: any,
  color: string,
  isBottomDivider: boolean,
): { designData: any; height: number } => {
  const designData = {
    type: DESIGN_WITH_SHAPE_DIVIDERS_TYPE,
    [isBottomDivider ? 'dividerBottom' : 'dividerTop']: {
      ...shapeDividerData,
      color,
    },
  };
  return {
    designData,
    height: shapeDividerData.height.value,
  };
};

const lowerSectionContent = (
  sectionStructure: KitPresetStructures,
  height: number,
) => {
  sectionStructure.structure.components!.forEach(({ layout }) => {
    if (layout) {
      layout.y = layout.y + height;
    } else {
      // TODO: implement for one doc
      console.error(
        'could not lowerSectionContent after set divider, no layout in structure',
      );
    }
  });
  if (sectionStructure.mobileStructure) {
    sectionStructure.mobileStructure.components!.forEach(({ layout }) => {
      if (layout) {
        layout.y = layout.y + SHAPE_DIVIDER_HEIGHT_MOBILE;
      } else {
        // TODO: implement for one doc
        console.error(
          'could not lowerSectionContent after set divider, no layout in structure',
        );
      }
    });
  }
};

const extendSection = (
  sectionStructure: KitPresetStructures,
  height: number,
) => {
  const sectionLayout = sectionStructure.structure.layout;
  if (!sectionLayout) {
    // TODO: implement for one doc
    console.error(
      'could not extendSection after set divider, no layout in structure. componentType:',
      sectionStructure.structure.componentType,
    );
    return;
  }
  sectionLayout.height = sectionLayout.height + height;

  if (sectionStructure.mobileStructure) {
    const sectionMobileLayout = sectionStructure.mobileStructure.layout;
    if (!sectionMobileLayout) {
      // TODO: implement for one doc
      console.error(
        'could not extendSection mobile after set divider, no layout in structure. componentType:',
        sectionStructure.mobileStructure.componentType,
      );
      return;
    }
    sectionMobileLayout.height =
      sectionMobileLayout.height + SHAPE_DIVIDER_HEIGHT_MOBILE;
  }
};
