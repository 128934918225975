import type {
  SerializedCompStructure,
  SerializedPageStructure,
} from '@wix/document-services-types';
export const getBlankPageStructure = (
  pageUriSEO: string,
  components: SerializedCompStructure[],
  isMobile: boolean = false,
): SerializedPageStructure => ({
  type: 'Page',
  id: 'c1dmp',
  components,
  mobileComponents: isMobile ? components : {},
  skin: 'wysiwyg.viewer.skins.page.TransparentPageSkin',
  layout: {
    width: isMobile ? 324 : 1054,
    height: 500,
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Page',
  data: {
    type: 'Page',
    metaData: {
      isPreset: true,
      schemaVersion: '2.0',
      isHidden: false,
      sig: 'aig-116',
      pageId: 'masterPage',
    },
    title: 'HOME',
    hideTitle: true,
    icon: '',
    descriptionSEO: '',
    metaKeywordsSEO: '',
    pageTitleSEO: '',
    pageUriSEO,
    hidePage: false,
    isMobileLandingPage: false,
    underConstruction: false,
    tpaApplicationId: 0,
    pageSecurity: {
      requireLogin: false,
    },
    isPopup: false,
    indexable: true,
    isLandingPage: false,
    pageBackgrounds: {
      desktop: {
        custom: true,
        ref: {
          type: 'BackgroundMedia',
          metaData: {
            isPreset: true,
            schemaVersion: '2.0',
            isHidden: false,
            sig: '5p1-10',
            pageId: 'c1dmp',
          },
          color: '{color_11}',
          alignType: 'top',
          fittingType: 'fill',
          scrollType: 'fixed',
        },
        isPreset: true,
      },
      mobile: {
        custom: true,
        ref: {
          type: 'BackgroundMedia',
          metaData: {
            isPreset: true,
            schemaVersion: '2.0',
            isHidden: false,
            sig: '5p1-11',
            pageId: 'c1dmp',
          },
          color: '{color_11}',
          alignType: 'top',
          fittingType: 'fill',
          scrollType: 'fixed',
          colorOverlay: '',
          colorOverlayOpacity: 0,
        },
        isPreset: true,
        // @ts-expect-error
        mediaSizing: 'viewport',
      },
    },
    translationData: {
      uriSEOTranslated: false,
    },
    ignoreBottomBottomAnchors: true,
    ogImage: '',
    advancedSeoData: '{"tags":[]}',
  },
  props: {
    id: 'propItem-lji1s6ui',
    type: 'PageProperties',
    // @ts-expect-error
    desktop: {
      minHeight: 40,
    },
  },
  behaviors: {
    type: 'ObsoleteBehaviorsList',
    id: 'behavior-jg9pfpzt',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
    },
    items: '[]',
  },
  style: {
    type: 'ComponentStyle',
    id: 'style-krbyc0il',
    metaData: {
      isPreset: true,
      schemaVersion: '1.0',
      isHidden: false,
      sig: 'yx9-7',
      pageId: 'c1dmp',
    },
    style: {
      properties: {},
      propertiesSource: {},
      groups: {},
    },
    componentClassName: 'mobile.core.components.Page',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.page.TransparentPageSkin',
  },
  activeModes: {},
});
