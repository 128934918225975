import { ColorPalette as DMColorPalette } from '@wix/document-services-types';
type PartialColorPalette = Partial<DMColorPalette>;
import { COLOR_ROLES } from './consts';
import {
  getColorNameByRole,
  getA11yButtonColor,
  getAdvancedColorsMapping,
  getLinksAndActionsColor,
  getSecondaryBgColor,
  generateShadeColors,
} from './utils';

export const getAdvancedColors = (palette: PartialColorPalette) => {
  const advancedColorsPalette: PartialColorPalette = getAdvancedColorsMapping();

  const secondaryBackground = getColorNameByRole(
    COLOR_ROLES.SECONDARY_BACKGROUND,
  );
  const secondaryBackgroundColor = getSecondaryBgColor(palette);
  if (secondaryBackgroundColor && secondaryBackground) {
    advancedColorsPalette[secondaryBackground] = secondaryBackgroundColor;
  }

  const secondaryText = getColorNameByRole(COLOR_ROLES.SECONDARY_TEXT);
  const secondaryTextName = getColorNameByRole(COLOR_ROLES.MAIN_2);
  if (secondaryTextName && secondaryText) {
    advancedColorsPalette[secondaryText] = secondaryTextName;
  }

  const colorLink = getColorNameByRole(COLOR_ROLES.LINK);
  const linksAndActionsColor = getLinksAndActionsColor(palette);
  if (linksAndActionsColor && colorLink) {
    advancedColorsPalette[colorLink] = linksAndActionsColor;
  }

  const colorLine = getColorNameByRole(COLOR_ROLES.LINE);
  const colorLineName = getColorNameByRole(COLOR_ROLES.SECONDARY_1);
  if (colorLineName && colorLine) {
    advancedColorsPalette[colorLine] = colorLineName;
  }

  const a11yButtonColor = getA11yButtonColor(palette);
  const buttonNamesByRole = [
    getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_TEXT),
    getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_FILL_HOVER),
    getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_FILL),
    getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_TEXT_HOVER),
  ];
  buttonNamesByRole.forEach((buttonNameByRole) => {
    if (buttonNameByRole && a11yButtonColor) {
      advancedColorsPalette[buttonNameByRole] = a11yButtonColor;
    }
  });

  return advancedColorsPalette;
};

export const getShadesFromBaseColors = ({
  color_36,
  color_37,
}: PartialColorPalette): PartialColorPalette => {
  if (!color_36 || !color_37) {
    throw new Error('no color_36 or color_37 in palette');
  }

  const shadeFromBaseColorsPalette: PartialColorPalette = {};
  const shades = generateShadeColors(color_36, color_37);
  const shadesRoles = [
    getColorNameByRole(COLOR_ROLES.SHADE_1),
    getColorNameByRole(COLOR_ROLES.SHADE_2),
    getColorNameByRole(COLOR_ROLES.SHADE_3),
  ];
  Object.entries(shades).forEach(([key, value], index) => {
    const shadeNameByRole = shadesRoles[index];
    if (shades[key] && shadeNameByRole) {
      shadeFromBaseColorsPalette[shadeNameByRole] = shades[key];
    }
  });

  return shadeFromBaseColorsPalette;
};
