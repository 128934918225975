import {
  PredictorPrimeService,
  type InvokeModelResponse,
} from '@wix/ambassador-predictor-prime-service/http';
import type {
  KitExtendedDescription,
  KitExtendedDescriptionKey,
} from '@wix/editor-kits';
import { ChatControllerInteractionName, FedopsInteraction } from '../types';
import _ from 'lodash';

const appService = '/_api/predictor-service/';
// eslint-disable-next-line new-cap
const predictorPrimeService = PredictorPrimeService(appService).Predictor();

export interface SgThemeFilteringServiceOptions {
  interactionStarted: (interactionName: ChatControllerInteractionName) => void;
  interactionEnded: (interactionName: ChatControllerInteractionName) => void;
}

export default class SgThemeFilteringService {
  private readonly interactionStarted: FedopsInteraction;
  private readonly interactionEnded: FedopsInteraction;

  constructor({
    interactionStarted = _.noop,
    interactionEnded = _.noop,
  }: SgThemeFilteringServiceOptions) {
    this.interactionStarted = interactionStarted;
    this.interactionEnded = interactionEnded;
  }

  public async filterKitsByUserInput(
    userInput: string,
    kitExtendedDescriptions: {
      [key in KitExtendedDescriptionKey]: KitExtendedDescription;
    },
  ): Promise<InvokeModelResponse> {
    this.interactionStarted('site_generation_filter_kits_insight');
    const result = await predictorPrimeService().invokeByModel({
      modelId: 'sg-theme-matching',
      features: {
        values: {
          user_input: { strVal: userInput },
          library: {
            strVal: JSON.stringify(kitExtendedDescriptions),
          },
        },
      },
    });
    this.interactionEnded('site_generation_filter_kits_insight');
    return result;
  }
}
