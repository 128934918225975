import {
  mapStructureByCompType,
  mapStructureById,
  replaceVectorArt,
} from '../utils';
import {
  isFieldContentRole,
  PresetContentFieldRole,
} from '@wix/editor-content-injector';

import type { KitDefinition, KitPresetStructures } from '../types';
import type {
  FieldContentRole,
  SerializedCompStructure,
} from '@wix/document-services-types';

export const setSectionIcons = (
  { structure, mobileStructure }: KitPresetStructures,
  kitIcons: KitDefinition['icons'],
): void => {
  if (!kitIcons || kitIcons.length === 0) {
    return;
  }
  let iconIndex = 0;
  const mobileStructureById = mapStructureById(mobileStructure);
  const structureByCompTypeEntry = mapStructureByCompType(structure);
  structureByCompTypeEntry['wysiwyg.viewer.components.VectorImage']?.forEach(
    (vectorArtStructure: SerializedCompStructure) => {
      if (
        vectorArtStructure.id &&
        vectorArtStructure.contentRole &&
        isFieldContentRole(vectorArtStructure.contentRole) &&
        (vectorArtStructure.contentRole as FieldContentRole).fieldRole ===
          PresetContentFieldRole.Icon
      ) {
        const icon = kitIcons[iconIndex];
        replaceVectorArt(vectorArtStructure, icon);
        const mobileIconStructure = mobileStructureById[vectorArtStructure.id];
        if (mobileIconStructure) {
          replaceVectorArt(mobileIconStructure, icon);
        }
        iconIndex = (iconIndex + 1) % kitIcons.length;
      }
    },
  );
};
